import { handleDragItem } from "@redux/slice/live-slice";
import { Empty, Spin } from "antd";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";

const ListFile = ({ loading, dataFile }) => {
  const dispatch = useDispatch()
  const CameraSingle = ({item }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          url: item.id,
          node: item,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <>
        <div
          ref={drag}
          className="bg-[#2B2A3A] h-[120px] w-full mt-[8px] rounded-lg flex flex-col justify-center px-[12px]"
        >
          <div className="flex items-center justify-between w-full">
            <p className="text-white">
              {item.name} 
            </p>
          </div>
          <p className="text-white mt-[10px]">{item.date}</p>
        </div>
      </>
    );
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full h-full px-[8px] py-[12px] min-w-[300px]">
        <p className="text-white font-semibold">
          Tổng số:{" "}
          <span className="font-semibold text-[#5B5B9F]">
            {dataFile.length}
          </span>
        </p>
        <div className="h-[88vh] w-full overflow-auto scroll_default mt-[10px]">
          {loading ? (
            <div className="h-full w-full flex items-center justify-center">
              <Spin />
            </div>
          ) : (
            <>
              {dataFile.length === 0 ? (
                <div className="h-full w-full flex items-center justify-center">
                  <Empty description="Không có dữ liệu" />
                </div>
              ) : (
                <>
                  {dataFile.map((item, index) => {
                    return <CameraSingle key={index} item={item} />;
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </DndProvider>
  );
};
export default ListFile;

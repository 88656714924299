import colors from "@constants/colors";
import Search from "@common/search/custom-search";
import TableDevice from "@components/map/device-group/table-device";
import { Divider } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import CustomTreeLoad from "@common/tree/tree-loader";
import openIcon from "@assets/open.svg";
import closeIcon from "@assets/close.svg";
import PopupScan from "@components/map/device-group/popup-scan";
import {
  doIsTogle,
  handleLoadingTables,
  selectisShowScanTable,
  selectToggle,
} from "@redux/slice/action-slice";
import _, { set } from "lodash";
import { selectListProvince } from "@redux/slice/live-slice";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import { useSearchParams } from "react-router-dom";
import { urlKeyPoint } from "@utils/index";
import ScanDrawer from "@components/map/scan";
import { selecttotalElements } from "@redux/slice/map-slice";
const DeviceGroup = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [keyChange, setChangKey] = useState(0);
  let [searchParams] = useSearchParams();
  let provinceID = searchParams.get("provinceIds");
  let districtIds = searchParams.get("districtIds");
  let communeIds = searchParams.get("communeIds");
  const [keyChangeTree, setChangKeyTree] = useState(0);
  const isShowDrawer = useSelector(selectisShowScanTable);
  const isShowDraw = useSelector(selectToggle);
  const dataListTree = useSelector(selectListProvince);
  const [total, setTotal] = useState(0);
  const [showDistrict, setShowDisTrict] = useState(false);
  const itemAll = [
    {
      id: "all",
      name: "Tất cả",
    },
  ];
  const dataTreeAll = itemAll.concat(dataListTree);

  const [selectedKeys, setSelectedKeys] = useState();
  const [listWard, setListWard] = useState([]);
  const navigate = useNavigate();
  const filterLocationCameraRef = useRef({
    communeIds: null,
    districtIds: null,
    ids: null,
    ips: null,
    pageNumber: 0,
    pageSize: 10,
    provinceIds: null,
    status: null,
    name: null,
    cameraGroupIds: null,
    state: null,
    dateFrom: null,
    dateTo: null,
    detail: true,
  });
  const handleReload = () => {
    filterLocationCameraRef.current.cameraGroupIds = null;
    filterLocationCameraRef.current.communeIds = null;
    filterLocationCameraRef.current.districtIds = null;
    filterLocationCameraRef.current.name = null;
    filterLocationCameraRef.current.dateFrom = null;
    filterLocationCameraRef.current.dateTo = null;
    filterLocationCameraRef.current.pageNumber = 0;
    filterLocationCameraRef.current.pageSize = 10;
    filterLocationCameraRef.current.state = null;
    filterLocationCameraRef.current.status = null;
    loadDataCame();
  };
  const loadDataProvince = () => {
    const callback = (data) => {
      handleSetParams(data);
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: { body: filterLocationCameraRef.current, callback },
    });
  };
  useEffect(() => {
    loadDataProvince();
    dispatch(doIsTogle(0));
    getTotalCameraMap();
  }, []);
  const getTotalCameraMap = () => {
    const callback = (data) => {
      setTotal(data.total);
    };
    dispatch({
      type: TYPE_ACTION.MAP.GET_COUNT_CAMERA_MAP,
      payload: { callback },
    });
  };
  const handleSetParams = (dataProvince) => {
    if (provinceID || communeIds || districtIds) {
      setSelectedKeys(JSON.stringify(provinceID));
      setShowDisTrict(true);
      filterLocationCameraRef.current.provinceIds = provinceID;
      filterLocationCameraRef.current.communeIds = communeIds;
      filterLocationCameraRef.current.districtIds = districtIds;
      if (filterLocationCameraRef.current.provinceIds) {
        loadDataCame();
      }
      const callBack = (data) => {
        setListWard(
          data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      };
      dispatch({
        type: TYPE_ACTION.LIVE.GET_DISTRICT_LIVE,
        payload: {
          body: filterLocationCameraRef.current.provinceIds,
          callBack,
        },
      });
    } else {
      setShowDisTrict(false);
      filterLocationCameraRef.current.provinceIds = null;
      setSelectedKeys(JSON.stringify("all"));
      loadDataCame();
    }
  };
  const loadDataCame = async () => {
    dispatch(handleLoadingTables(true));
    const callBack = (data) => {
      dispatch(handleLoadingTables(false));
    };
    const url = urlKeyPoint(filterLocationCameraRef.current);
    const newUrl = `/${PATH.MAP}/${PATH.REGION}?${url}`;
    window.history.pushState(null, "", newUrl);
    // navigate(`/${PATH.MAP}/${PATH.REGION}?${url}`);
    await dispatch({
      type: TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
      payload: { body: filterLocationCameraRef.current, callBack: callBack },
    });
  };

  const debouncedLoadData = useCallback(
    _.debounce(() => {
      setChangKeyTree((pre) => pre + 1);
      loadDataProvince();
    }, 500), // 500ms là thời gian chờ để debounce
    []
  );
  const handleOnChange = (e) => {
    filterLocationCameraRef.current.name = e.target.value;
    debouncedLoadData();
  };
  useEffect(() => {
    setOpen(!isShowDrawer);
  }, [isShowDrawer]);
  return (
    <div className="p-2 w-full h-full space-x-5 flex">
      <div
        className="h-[92vh] space-y-[27px] p-[20px] overflow-hidden"
        style={{ background: "#181824", width: open ? "320px" : "60px" }}
      >
        <div
          className={`flex items-center ${
            open ? "justify-between" : "justify-start"
          }`}
        >
          {open && (
            <p
              className="font-semibold text-[18px]"
              style={{ color: colors.textBase }}
            >
              DANH SÁCH VÙNG
            </p>
          )}
          {open ? (
            <img
              alt="close"
              src={closeIcon}
              onClick={() => setOpen(false)}
              className="cursor-pointer min-w-[18px]"
            />
          ) : (
            <img
              alt="open"
              src={openIcon}
              onClick={() => setOpen(true)}
              className="cursor-pointer min-w-[24px]"
            />
          )}
        </div>
        {open && <Search onChange={(e) => handleOnChange(e)} />}
        <Divider
          style={{
            borderColor: "#2B2A3A",
          }}
        />
        {/* <CustomTreeLoad key={keyChangeTree} open={open} handleOnChangeKey={()=>setChangKey(pre=>pre+1)}/> */}
        {open && (
          <div className="h-[70vh]  scroll_default overflow-auto w-[300px]">
            {dataTreeAll.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between  border-[#2E2E2E] cursor-pointer border-b ${
                    selectedKeys === JSON.stringify(item.id)
                      ? "bg-[#323539]"
                      : "transparent"
                  }`}
                  style={{ width: "230px" }}
                  onClick={(e) => {
                    setSelectedKeys(JSON.stringify(item.id));
                    e.stopPropagation();
                    if (item.id === "all") {
                      filterLocationCameraRef.current.provinceIds = null;
                      setShowDisTrict(false);
                    } else {
                      filterLocationCameraRef.current.provinceIds = item.id;
                      setShowDisTrict(true);
                    }
                    setChangKey((pre) => pre + 1);
                    handleReload();
                    const callBack = (data) => {
                      setListWard(
                        data.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                      );
                    };
                    dispatch({
                      type: TYPE_ACTION.LIVE.GET_DISTRICT_LIVE,
                      payload: { body: item.id, callBack },
                    });
                    loadDataCame();
                  }}
                >
                  <div className="flex items-center w-full px-[6px] py-[8px]">
                    <span
                      className={`flex-1 text-white ${
                        selectedKeys === JSON.stringify(item.id)
                          ? "font-bold"
                          : "font-normal"
                      }`}
                    >
                      {item.name}{" "}
                      <>
                        {item.name === "Tất cả" ? (
                          <span className={`text-[#01D68F]`}>({total})</span>
                        ) : (
                          <span className={`text-[#01D68F]`}>
                            ({item.cameraCount > 0 ? item.cameraCount : 0})
                          </span>
                        )}
                      </>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        key={keyChange}
        className="h-[92vh] w-full overflow-hidden"
        style={{ background: colors.bg_input }}
      >
        <TableDevice
          filterLocationCameraRef={filterLocationCameraRef}
          listWard={listWard}
          showDistrict={showDistrict}
        />
      </div>
      <div>{isShowDraw === 1 && <ScanDrawer />}</div>
    </div>
  );
};
export default DeviceGroup;

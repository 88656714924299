import { ConfigProvider, Spin, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { useDrag } from "react-dnd";
import { handleDragItem, selectListProvince } from "@redux/slice/live-slice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Search from "@common/search/custom-search";
import { imagesGroup } from "@common/images/image";
const TreePlayBack = ({ width, callbackID }) => {
  const [treeData, setTreeData] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const formState = useRef({
    ids: null,
    name: null,
    detail: true,
  });
  useEffect(() => {
    loadDataGroup();
  }, []);
  const loadDataGroup = () => {
    setLoading(true)
    const callBack = (data) => {
      setTreeData(data);
      setLoading(false)
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_GROUP_LIVE,
      payload: { body: formState.current, callBack },
    });
  };

  const handleOnChange = (e) => {
    formState.current.name = e.target.value;
    loadDataGroup();
  };
  const [select, setSelect] = useState();

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className=""
        style={{
          width: width,
        }}
      >
        <div className="px-[20px] py-[12px]">
          <Search
            placeholder="Nhập từ khóa tìm kiếm"
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <div className="h-[61vh] overflow-x-hidden  scroll_default overflow-auto">
          {loading ? (
            <div className="h-full w-full flex items-center justify-center"><Spin /></div>
          ) : (
            <>
              {treeData.map((item, index) => {
                if (item.type === "NVR")
                  return (
                    <div
                      onClick={() => {
                        callbackID(item.id);
                        setSelect(item.id);
                      }}
                      key={index}
                      className="px-[20px] w-full h-[60px] border-b border-[#444459] flex items-center cursor-pointer"
                      style={{
                        background: select === item.id ? colors.redGlobal : "",
                      }}
                    >
                      <p className="text-white">{item.name}</p>
                    </div>
                  );
              })}
            </>
          )}
        </div>
      </div>
    </DndProvider>
  );
};
export default TreePlayBack;

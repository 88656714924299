import React, { useRef, useEffect, useState } from "react";
import mpegts from "mpegts.js";
import { Spin } from "antd";

const FLVPlayer = ({ flvUrl, controls = false, height = "auto" }) => {
  const videoElement = useRef(null);
  const [loading, setLoading] = useState(true);
  let player = useRef(null);

  const createPlayer = (url) => {
    if (mpegts.getFeatureList().mseLivePlayback) {
      player.current = mpegts.createPlayer({
        type: "flv",
        url: url,
        isLive: true,
      });

      player.current.attachMediaElement(videoElement.current);
      player.current.load();

      // Lắng nghe sự kiện lỗi từ player
      player.current.on(mpegts.Events.ERROR, (errorType, errorDetails) => {
        console.error("Player Error:", errorType, errorDetails);
        // setLoading(false);
      });

      // Khi video có thể phát
      videoElement.current.addEventListener("canplay", () => {
        player.current.play();
        setLoading(false);
      });

      // Lắng nghe lỗi trên video element
      videoElement.current.addEventListener("error", () => {
        console.error("Video Element Error");
        // setLoading(false);
      });

      // Tự động phát lại nếu video bị tạm dừng
      videoElement.current.addEventListener("pause", () => {
        videoElement.current.play();
      });
    }
  };

  useEffect(() => {
    createPlayer(flvUrl);

    return () => {
      if (player.current) {
        player.current.unload();
        player.current.detachMediaElement();
        player.current.destroy();
        player.current = null;
      }
    };
  }, [flvUrl]);

  return (
    <div className="flex items-center justify-center h-full relative">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spin />
        </div>
      )}
      <video
        style={{ width: "100%", height: height, aspectRatio: "16 / 9" }}
        ref={videoElement}
        autoPlay
        controls={controls}
      ></video>
    </div>
  );
};

export default FLVPlayer;

import colors from "@constants/colors";
import { AutoComplete, ConfigProvider, Input, Select } from "antd";
import CustomTable from "@common/table/custom-table";
import { convertISOToDateTime2 } from "@utils/format-date";
import { useEffect, useRef, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import search_icon from "@assets/Search.svg";
import edit_icon from "@assets/Edit.svg";
import delete_icon from "@assets/Delete.svg";
import _ from "lodash";
import refesh_icon from "@assets/Refesh.svg";
import human_icon from "@assets/Nguoi.svg";
import vehicle_icon from "@assets/Xe.svg";
import iconLive from "@assets/live.svg";
import CustomModal from "@common/modal/modal";
import { useNavigate } from "react-router";
import {
  handleGetDataGroup,
  handleListCameraGroup,
  selectDataGroup,
  selectListGroup,
} from "@redux/slice/edit-group-slice";
import PATH from "@constants/path";
import Search from "@common/search/custom-search";
import PopupTab from "@components/map/device-group/pop-tab";
import {
  handleLoadingTables,
  selectisLoadingTables,
} from "@redux/slice/action-slice";
const CameraGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listGroup = useSelector(selectListGroup);
  const dataGroup = useSelector(selectDataGroup);
  const [isSelectTable, setIsSelectTable] = useState(false);
  const isLoading = useSelector(selectisLoadingTables);
  const [openTab, setopenTab] = useState(false);
  const [idCameraTab, setidCameraTab] = useState();
  const [listCameraTab, setListCameraTab] = useState([]);
  const [listCameraDelete, setListCameraDelete] = useState([]);
  const data = [];
  const filterDataCameRef = useRef({
    active: null,
    name: null,
    pageNumber: 0,
    pageSize: 10,
    type: null,
    detail: true,
  });
  const loadDataGroup = () => {
    dispatch(handleLoadingTables(true));
    const callBack = () => {
      dispatch(handleLoadingTables(false));
    };
    dispatch({
      type: TYPE_ACTION.GROUP.GET_LIST_GROUP,
      payload: { body: filterDataCameRef.current, callBack: callBack },
    });
  };
  useEffect(() => {
    loadDataGroup();
  }, []);

  const handleLive = async () => {
    let listCamera = [];
    const promises = listCameraTab.map((item) => {
      return new Promise((resolve) => {
        const callBack = (data) => {
          data[0]?.cameraIds?.forEach((cameraId) => {
            listCamera.push(cameraId);
          });
          resolve();
        };

        dispatch({
          type: TYPE_ACTION.GROUP.GET_LIST_GROUP,
          payload: {
            body: { ids: item, detail: true, get: true },
            callBack: callBack,
          },
        });
      });
    });

    await Promise.all(promises);

    setidCameraTab(listCamera);

    setopenTab(true);
    loadDataGroup();
  };

  const handleDelete = (e, value) => {
    e.stopPropagation();
    try {
      const callBack = (data) => {
        loadDataGroup();
      };
      dispatch({
        type: TYPE_ACTION.GROUP.DELETE_CAMERA_GROUP,
        payload: { body: { ids: value.id }, callBack: callBack },
      });
    } catch (error) {}
  };
  const handleDeleteArray = (listDelete) => {
    try {
      listDelete.map((item) => {
        const callBack = (data) => {
          loadDataGroup();
        };
        dispatch({
          type: TYPE_ACTION.GROUP.DELETE_CAMERA_GROUP,
          payload: { body: { ids: item }, callBack: callBack },
        });
      });
    } catch (error) {}
  };
  const handleEdit = (e, value) => {
    e.stopPropagation();
    dispatch(handleGetDataGroup([value]));
    navigate(`/${PATH.MAP}/${PATH.CAMERA_GROUP}/edit/${value.id}`, {
      state: {
        groupID: value.id,
        lisAdd: dataGroup,
      },
    });
    dispatch(handleListCameraGroup([value]));
  };
  const handleAdd = () => {
    navigate("/" + PATH.MAP + "/" + PATH.ADD_GROUP);
  };

  const handleDataSearch = (e) => {
    filterDataCameRef.current.name = e.target.value;
    loadDataGroup();
  };

  const handleSelectCheckbox = (e) => {
    if (e.length > 0 || e === true) {
      if (Array.isArray(e)) {
        let listId = [];
        e.map((item) => {
          listId.push(item.id);
        });
        setListCameraDelete(listId);
        setListCameraTab(listId);
      }
      setIsSelectTable(true);
    } else {
      setIsSelectTable(false);
    }
  };
  const handleChange = (value) => {
    filterDataCameRef.current.active = JSON.stringify(value);
    loadDataGroup();
  };
  const handleChangeType = (value) => {
    filterDataCameRef.current.type = JSON.stringify(value);
    // loadDataGroup();
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 80,
      fixed: "left",
    },
    {
      title: "TÊN NHÓM",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "SỐ LƯỢNG",
      dataIndex: "quality",
      key: "quality",
      width: 120,
    },
    {
      title: "VỊ TRÍ",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "CẬP NHẬT LẦN CUỐI",
      dataIndex: "camera_created_at",
      key: "camera_created_at",
      width: 180,
    },
    {
      title: "BÀI TOÁN AI",
      dataIndex: "ai",
      key: "ai",
      width: 180,
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      align: "left",
      width: 160,
    },
    {
      title: "HÀNH ĐỘNG",
      dataIndex: "action",
      key: "action",
      width: 120,
    },
  ];

  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(listGroup) &&
      listGroup.map((dataIndex, index) => {
        data.push({
          key: index + 1,
          id: dataIndex.id,
          stt: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {index + 1}
            </div>
          ),
          name: dataIndex.name,
          company: dataIndex.type,
          quality: dataIndex.cameraIds.length,
          detail: <div className="truncate">{dataIndex.description}</div>,
          status: (
            <div className="flex justify-start">
              {dataIndex.activate ? (
                <CustomButtonCommon
                  height=""
                  text="Hoạt động"
                  className="cursor-default"
                  backgroundColor="#006400"
                  textColor={colors.textBase}
                  border="none"
                />
              ) : (
                <CustomButtonCommon
                  className="cursor-default"
                  height=""
                  text="Không hoạt động"
                  backgroundColor="#E21A3D"
                  textColor={colors.textBase}
                  border="none"
                />
              )}
            </div>
          ),
          action: (
            <div className="flex justify-center items-center gap-[20px]">
              <img
                className="cursor-pointer"
                src={edit_icon}
                onClick={(e) => handleEdit(e, dataIndex)}
                alt="edit"
              />
              <img
                className="cursor-pointer"
                src={delete_icon}
                onClick={(e) => handleDelete(e, dataIndex)}
                alt="delete"
              />
            </div>
          ),
          camera_created_at: convertISOToDateTime2(dataIndex.createdDate),
          ai: (
            <div className="flex justify-center items-center gap-[12px]">
              <img src={human_icon} alt="human" />
              <img src={vehicle_icon} alt="vehicle" />
            </div>
          ),
        });
      });
  }

  const options = [
    { label: "Hoạt động", value: true },
    { label: "Không hoạt động", value: false },
  ];
  const optionsType = [
    { label: "Tất cả", value: "null" },
    { label: "NVR/DVR", value: "NVR" },
    { label: "Nhóm thường", value: "none" },
  ];
  return (
    <div className="p-2 w-full h-full space-x-5 flex">
      <div
        className="h-full w-full space-y-[27px] p-[20px]"
        style={{ background: "#181824" }}
      >
        <div className="flex justify-between items-start">
          <ConfigProvider
            theme={{
              token: {
                borderRadius: 4,
                colorBgBase: "#181824",
                colorBorder: colors.borderColor,
                controlHeight: 40,
                controlOutlineWidth: 0,
                colorText: colors.textBase,
                controlItemBgActive: "#5B5B9F",
              },
            }}
          >
            <div className="grid grid-cols-3 gap-[12px]">
              <div className="w-[214px]">
                <Search onChange={handleDataSearch} />
              </div>
              <Select
                className="w-[214px]"
                placeholder="Trạng thái"
                onChange={handleChange}
                options={options}
              />
              <Select
                className="w-[214px]"
                placeholder="Phân loại nhóm"
                onChange={handleChangeType}
                options={optionsType}
              />
            </div>
          </ConfigProvider>
          <div className="flex justify-between items-center px-3 gap-4">
            <CustomButtonCommon
              text="Xem trực tiếp"
              icon={<img src={iconLive} alt="live" />}
              backgroundColor={colors.background_disable}
              className="flex justify-center items-center"
              onClick={handleLive}
              disabled={!isSelectTable}
            />
            <CustomButtonCommon
              text="Thêm"
              icon={<img src={search_icon} alt="search" />}
              backgroundColor={colors.redGlobal}
              onClick={() => {
                handleAdd();
              }}
              className="flex justify-center items-center"
            />
            <CustomButtonCommon
              text="Xóa"
              icon={<img src={delete_icon} alt="delete" />}
              disabled={!isSelectTable}
              onClick={() => handleDeleteArray(listCameraDelete)}
              className="flex justify-center items-center"
            />
            <CustomButtonCommon
              text="Làm mới"
              icon={<img src={refesh_icon} alt="reset" />}
              backgroundColor={"#444459"}
              className="flex justify-center items-center"
              onClick={() => {
                loadDataGroup();
              }}
              border={"none"}
            />
          </div>
        </div>
        <CustomTable
          key={listGroup.id}
          data={data}
          columns={columns}
          showTotal={true}
          pagination={true}
          hasSelectCheckbox={(e) => handleSelectCheckbox(e)}
          showCheckBox={true}
          loading={isLoading}
          bordered={false}
          handleRowClick={(record, event) => {
            navigate(`/${PATH.MAP}/${PATH.CAMERA_GROUP}/view/${record.id}`, {
              state: {
                groupID: record.id,
              },
            });
          }}
        />
      </div>
      <CustomModal
        centered={true}
        open={openTab}
        width={"584px"}
        handleCancel={() => setopenTab(false)}
        background="#444459"
        content={
          <PopupTab
            listCameraTab={idCameraTab}
            handleCancel={() => setopenTab(false)}
          />
        }
        renderHeader={
          <p className="text-[#E3E3E3] text-[16px] uppercase font-semibold">
            Chọn tab cần di chuyển
          </p>
        }
      />
    </div>
  );
};
export default CameraGroup;

import { SERVICE_PATHS } from "@constants/url";
import { get, remove, post, patch } from "../../api/api-base";
const GroupService = {
  getListGroup(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.GROUP.GET_LIST_GROUP);
    }

    return get(`${SERVICE_PATHS.GROUP.GET_LIST_GROUP}${filterString}`);
  },
  getAllCamera(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.GROUP.GET_ALL_CAMERA);
    }

    return get(`${SERVICE_PATHS.GROUP.GET_ALL_CAMERA}${filterString}`);
  },
  createCameraGroup: (body) => post(SERVICE_PATHS.GROUP.CREATE_GROUP, body),
  deleteCameraOnGroup: (body) =>
    post(SERVICE_PATHS.GROUP.DELETE_CAMERA_ON_GROUP, body),
  addCameraOnGroup: (body) =>
    post(SERVICE_PATHS.GROUP.ADD_CAMERA_ON_GROUP, body),
  updateCameraGroup: (body) => patch(SERVICE_PATHS.GROUP.UPDATE_GROUP, body),
  deleteCameraGroup: (body) =>
    remove(`${SERVICE_PATHS.GROUP.DELETE_GROUP}?ids=${body}`),
};

export default GroupService;

import { ConfigProvider, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { useDrag } from "react-dnd";
import { handleDragItem, selectListProvince } from "@redux/slice/live-slice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Search from "@common/search/custom-search";
import { imagesGroup } from "@common/images/image";
const CustomTreeLiveGroup = ({
  initTreeData,
  onSelect,
  data = [],
  titleRender,
  showIcon = true,
  onExpand,
  height = null,
  width,
  isGetDataTable,
  filterLocationCameraRef,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const dispatch = useDispatch();
  const treeWeb = [];
  const formState = useRef({
    ids: null,
    name: null,
    detail: true,
  });
  useEffect(() => {
    loadDataGroup()
  }, []);
  const loadDataGroup = () => {
    const callBack = (data) => {
      mapData(data);
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_GROUP_LIVE,
      payload: { body: formState.current, callBack },
    });
  };

  const mapData = (data) => {
    const arr = [];
    data?.map((item) => {
      const key = item.id;
      if (item.id === 1) {
        // console.log(item)
      }
      const treeNode = {
        title: item.name,
        key,
        id: item.id,
        isLeaf: false,
        children: item?.cameraDTOs.map((camera, i) => ({
          title: camera.name,
          key: `${key}-${i}`,
          camera: true,
          isLeaf: true,
          url: camera.urlMainstream,
          status: camera.status,
          id: camera.id,
          level: 2,
          name: camera.ipAddress,
        })),
        cameraCount: item?.cameraDTOs.length,
        level: 0,
      };
      arr.push(treeNode);
    });
    setTreeData(arr);
  };
  useEffect(() => {
    if (Array.isArray(treeWeb) && Array.isArray(initTreeData)) {
      const newArray = [...treeWeb, ...initTreeData];
      setTreeData(newArray);
    } else {
      console.error("treeWeb or initTreeData is not an array");
    }
  }, [initTreeData]);

  const handleExpandToggle = (key, nodeData) => {
    if (isGetDataTable) {
      filterLocationCameraRef.current.provinceIds = nodeData.id;
    }

    const listData = [
      nodeData.id,
      ...(nodeData?.children ? nodeData.children.map((item) => item.id) : []),
    ];

    setSelectedKeys(listData);
    setExpandedKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };
  const CameraSingle = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "single",
          url: node.id,
          node: node,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <div
        ref={drag}
        className="flex items-center justify-between  border-[#2E2E2E] pr-[20px]"
        style={{ paddingLeft: `${node.level * 20}px`, width: width }}
        onClick={(e) => {
          e.stopPropagation();
          handleExpandToggle(node.key, node);
        }}
      >
        <div className="flex items-center w-full p-2 space-x-2">
          <img alt="icon" src={imagesGroup.camera_connected} />

          <span className="flex-1 max-w-[200px] truncate">{node.title} </span>
        </div>
      </div>
    );
  };
  const CameraGroup = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "group_list",
          url: node.id,
          node: node,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <div
        ref={drag}
        className="flex items-center justify-between  border-[#2E2E2E] px-[24px]"
        style={{ paddingLeft: `${node.level * 20 + 10}px`, width: width }}
        onClick={(e) => {
          e.stopPropagation();
          handleExpandToggle(node.key, node);
        }}
      >
        <div className="flex items-center w-full p-2">
          <span className="max-w-[200px] truncate overflow-hidden">
            {" "}
            {node.title}{" "}
          </span>
          <span className="flex-1 w-full">
            {node.level < 3 && (
              <>
                <span className="text-[#01D68F]">
                  ({node.cameraCount > 0 ? node.cameraCount : 0})
                </span>
              </>
            )}
          </span>
        </div>
        <DownOutlined style={{ color: "#444459" }} />
      </div>
    );
  };
  const memoizedTitleRender = useMemo(() => {
    return (node) => {
      const hasChildren = node.children && node.children.length >= 0;
      if (hasChildren) {
        return <CameraGroup node={node} name={node.title} />;
      } else {
        return <CameraSingle node={node} name={node.title} />;
      }
    };
  }, []);

  const handleOnChange = (e) => {
    formState.current.name = e.target.value;
    loadDataGroup()
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="h-[900px]  scroll_default overflow-auto"
        style={{
          width: width,
        }}
      >
        <div className="px-[20px] py-[12px]">
          <Search
            placeholder="Nhập từ khóa tìm kiếm"
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "rgba(16, 16, 22, 0)",
              controlItemBgActive: colors.transparent,
              lineHeight: 2,
              colorText: colors.textBase,
              colorPrimary: colors.redGlobal,
              colorBorder: colors.borderColor,
              paddingXS: 5,
            },
          }}
        >
          <Tree
            checkable={false}
            treeData={treeData}
            showIcon={false}
            height={height}
            switcherIcon={null}
            showLine={false}
            onSelect={onSelect}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            titleRender={memoizedTitleRender}
          />
        </ConfigProvider>
      </div>
    </DndProvider>
  );
};
export default CustomTreeLiveGroup;

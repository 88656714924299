import { call, put, takeEvery } from "redux-saga/effects";
import DeviceService from "../services/device-service.js";
import TYPE_ACTION from "@constants/action.js";
import { handleListDevice } from "@redux/slice/device-slice.js";

function* doGetListDevice(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(DeviceService.getListDevice, body);
    const { data } = response;
    callBack(data);
    yield put(handleListDevice(data));
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}

function* doGetInfoCameraWithId(action) {
  const { ids, callBack } = action.payload;
  try {
    const response = yield call(DeviceService.getInfoCameraWithId, ids);
    callBack(response.data);
  } catch (error) {}
}

function* doUpdateAddressCamera(action) {
  try {
    const response = yield call(DeviceService.updateAddress, action.payload);
    return response;
  } catch (error) {}
}

function* doUpdateTab(action) {
  try {
    const response = yield call(DeviceService.updateCamera, action.payload);
    return response;
  } catch (error) {}
}

function* doDeleteCamera(action) {
  const {body,callback} = action.payload;
  try {
    const response = yield call(DeviceService.removeCamera, body);
    callback(response);
    return response;
  } catch (error) {}
}


// ----Watcher
function* watchGetListDevice() {
  yield takeEvery(TYPE_ACTION.CAMERA.GET_LIST_DEVICE, doGetListDevice);
}

function* watchGetCameraWithId() {
  yield takeEvery(TYPE_ACTION.CAMERA.GET_INFO_CAMERA_ID, doGetInfoCameraWithId);
}

function* watchUpdateCamera() {
  yield takeEvery(TYPE_ACTION.CAMERA.UPDATE_INFO_CAMERA_ID, doUpdateTab);
}

function* watchUpdateAddressCamera() {
  yield takeEvery(TYPE_ACTION.CAMERA.UPDATE_ADDRESS_CAMERA, doUpdateAddressCamera);
}
function* watchDeleteCamera() {
  yield takeEvery(TYPE_ACTION.CAMERA.DELETE_CAMERA_ID, doDeleteCamera);
}
export { watchGetListDevice, watchGetCameraWithId, watchUpdateCamera,watchUpdateAddressCamera,watchDeleteCamera};

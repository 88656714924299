import { selectisexcessWidth, selectistreeWidth } from "@redux/slice/action-slice";
import { ScreenHeight } from "@utils/index";
import { useSelector } from "react-redux";

const GridBackground = ({lengthLayout, row, column, scale, headerToolBox,widthTree}) => {
    const {screenHeight, screenWidth} = ScreenHeight()
    const excessWidth = useSelector(selectisexcessWidth)
    const treeWidth = useSelector(selectistreeWidth)
    return ( 
        <div
        className={
          "grid justify-center place-items-center overflow-hidden absolute bg-transparent scroll_default"
        }
        style={{
          width: screenWidth-excessWidth-treeWidth,
          height: screenHeight - 130,
          gridTemplateColumns: `repeat(${column},1fr)`,
          gridTemplateRows: `repeat(${row},1fr)`,
          transform: `scale(${scale})`,
          transformOrigin: "center center",
          transition: "transform 0.5s",
        }}
      >
        {lengthLayout.map((_, index) => {
          return (
            <div
              key={index}
              className="h-full w-full cursor-pointer"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 1)",
                borderBottom: `1px solid red`,
                borderRight: `1px solid red`,
              }}
            ></div>
          );
        })}
      </div>
     );
}
 
export default GridBackground;
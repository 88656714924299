import GridLayoutCustom from "@components/live/NX/grid-layout";
import colors from "@constants/colors";
import { useDispatch } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomTreeLive from "@common/tree/tree-live";
import HeaderTab from "./components/headerTab";
import { ConfigProvider, Tabs } from "antd";
import CustomTreeLiveGroup from "@common/tree/tree-group";
import { handleClearListCameraTab } from "@redux/slice/live-slice";
import openIcon from "@assets/open.svg";
import closeIcon from "@assets/close.svg";
import { handleChangeTreeWidth } from "@redux/slice/action-slice";
const NX = () => {
  const [isShowTree, setIsShowTree] = useState(true);
  const widthTree = isShowTree ? 28 : -224;
  const dispatch = useDispatch();
  const loadDataProvince = () => {
    const callback = () => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: { body: {}, callback },
    });
  };

  useEffect(() => {
    dispatch(handleClearListCameraTab());
    loadDataProvince();
  }, []);
  const onChange = (key) => {};
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [open, setOpen] = useState(true);
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };
  useEffect(() => {
    setScreenHeight(window.innerHeight);
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);
  const items = [
    {
      key: "1",
      label: <p className="font-semibold text-[16px]">DANH SÁCH VÙNG</p>,
      children: <CustomTreeLive width={400} height={screenHeight - 200} />,
    },
    {
      key: "2",
      label: <p className="font-semibold text-[16px]">DANH SÁCH NHÓM</p>,
      children: <CustomTreeLiveGroup width={400} />,
    },
  ];
  
  return (
    <div className="h-full flex w-full space-x-1 overflow-hidden">
      <div style={{ background: colors.bg_input,width:open?"400px":"40px" }} className="relative">
        {open ? (
          <img
            alt="close"
            src={closeIcon}
            onClick={() => {
              dispatch(handleChangeTreeWidth({
                open:false,
                width:40
              }))
              setOpen(false)
            }}
            className="cursor-pointer absolute right-[4px] top-[4px]"
          />
        ) : (
          <img
            alt="open"
            src={openIcon}
            onClick={() => {
              dispatch(handleChangeTreeWidth({
                open:true,
                width:400
              }))
              setOpen(true)
            }}
            className="cursor-pointer absolute left-[10px] top-[10px]"
          />
        )}
        {open && (
          <ConfigProvider
            theme={{
              token: {
                colorBgBase: "#181824",
                colorBgContainer: "#181824",
                colorPrimary: colors.redGlobal,
                colorText: "#706F80",
                colorTextBase: "#706F80",
              },
              components: {
                Tabs: {
                  itemSelectedColor: "#FFFFFF",
                },
              },
            }}
          >
            <Tabs
              defaultActiveKey="1"
              items={items}
              onChange={onChange}
              className="mt-[20px]"
              centered
            />
          </ConfigProvider>
        )}
      </div>
      <div className="w-full overflow-hidden">
        <HeaderTab />
        <DndProvider backend={HTML5Backend}>
          <GridLayoutCustom widthTree={widthTree} />
        </DndProvider>
      </div>
    </div>
  );
};
export default NX;

import { takeEvery, put, call } from "redux-saga/effects";
import TYPE_ACTION from "@constants/action";
import {
  getSizeGrid,
  getOpenList,
  getListCameraPreview,
  getListProvince,
  getCameraWithProvince,
  handleCheckGrid,
  handleDataSearch,
  handleGetGroup,
  handleGetCamera,
} from "@redux/slice/live-slice";
import LiveService from "@redux/services/live-services";
import { message } from "antd";
import { getDataLocationCam } from "@redux/slice/map-slice";

//---Worker

function* doGetListProvince(action) {
  const {body,callback} = action.payload;
  try {
    const response = yield call(
      LiveService.getDatalistProvince,
      body
    );
    const { data } = response;
    callback(data)
    yield put(getListProvince(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetCameraDataWithIDTree(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getPreviewCamWithIDTree, body);
    yield put(getDataLocationCam(response.data));
    callBack(response.data.content);
  } catch (error) {}
}

function* doCreateTab(action) {
  const { callBack,body } = action.payload;
  try {
    const response = yield call(LiveService.createTab,body);
    callBack(response.data);
    return response;
  } catch (error) {}
}

function* doGetTab(action) {
  const { ids,callBack } = action.payload;
  try {
    const response = yield call(LiveService.getTab,ids);
    callBack(response.data);
    return response;
  } catch (error) {}
}

function* doUpdateTab(action) {
  try {
    const response = yield call(LiveService.updateTabs, action.payload);
    return response;
  } catch (error) {}
}

function* deleteTab(action) {
  const { ids } = action.payload;
  try {
    const response = yield call(LiveService.deleteTabs, ids);
    return response;
  } catch (error) {}
}

function* addCameraInTab(action) {
  const {callback,body} = action.payload;
  try {
    const response = yield call(LiveService.addCameraTab, body);
    callback(response)
    return response;
  } catch (error) {}
}
function* removeCameraInTab(action) {
  try {
    const response = yield call(LiveService.removeCameraTab, action.payload);
    return response;
  } catch (error) {}
}


function* getListGroup(action) {
  const { body,callBack } = action.payload;
  try {
    const response = yield call(LiveService.getListGroup, body);
    callBack(response.data)
    return response;
  } catch (error) {}
}
////////

function* doSelectSizeGrid(action) {
  const body = action.payload;
  yield put(getSizeGrid(body));
}
function* doSelectOpenListCam(action) {
  const body = action.payload;
  yield put(getOpenList(body));
}

function* doGetListCameraPreview(action) {
  const body = action.payload;
  yield put(getListCameraPreview(body));
}
function* doGetCameraDataWithProvince(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.getCameraDataWithProvince, body);
    const { data } = response;
    callBack(data);
    yield put(getCameraWithProvince(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetCameraDataWithID(action) {
  const { url, callBack, errorBack } = action.payload;
  try {
    const response = yield call(LiveService.getPreviewCamWithID, url);
    callBack(response.data);
  } catch (error) {
    errorBack(error);
  }
}
function* doGetCameraRecord(action) {
  const { url, callBack, errorBack } = action.payload;
  try {
    const response = yield call(LiveService.getVideoRecord, url);
    callBack(response.data);
  } catch (error) {
    errorBack(error);
  }
}

function* doGetDistrict(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getDistric, body);
    callBack(response.data);
  } catch (error) {}
}

function* doGetWard(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getWard, body);
    callBack(response.data);
  } catch (error) {}
}

function* doGetDevice(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getDevice, body);
    callBack(response.data);
  } catch (error) {}
}

function* doGetAllCameraStream(action) {
  const { body, callBack } = action.payload;
  try {
    const response = yield call(LiveService.getAllCameraStream, body);
    callBack(response.data);
    yield put(handleCheckGrid(response.data));
  } catch (error) {}
}

function* doStopStream(action) {
  const { id, callBack } = action.payload;
  try {
    const response = yield call(LiveService.stopStream, id);
    callBack(response);
  } catch (error) {}
}

function* doStopAllStream(action) {
  const { id } = action.payload;
  yield call(LiveService.stopAllStream, id);
}
function* doCreateGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.createGroup, body);
    const { data } = response;
    callBack(data);
    message.success("Create success!");
  } catch (error) {
    console.log("Error update: ", error);
    message.error(error.response.data.message);
  }
}
function* doCreateCamera(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.createCamera, body);
    const { data } = response;
    callBack(data);
    message.success("Create success!");
  } catch (error) {
    console.log("Error update: ", error);
    message.error(error.response.data.message);
  }
}
function* doGetSearchCamera(action) {
  try {
    const { body, callback } = action.payload;
    const response = yield call(LiveService.getDataCameraSearch, body);
    const { data } = response;
    callback(data);
    yield put(handleDataSearch(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetGroup(action) {
  try {
    const { body } = action.payload;
    const response = yield call(LiveService.getGroup, body);
    const { data } = response;
    yield put(handleGetGroup(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetCameraNonAddress(action) {
  try {
    const { body } = action.payload;
    const response = yield call(LiveService.getCameraNonAddress, body);
    const { data } = response;
    yield put(handleGetCamera(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doDeleteCameraNonAddress(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(LiveService.deleteCamera, body);
    callBack(response);
    message.success("Delete success!");
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* getRecord(action) {
  const { body,callback } = action.payload;
  try {
    const response = yield call(LiveService.getRecord, body);
    callback(response)
    return response;
  } catch (error) {}
}

function* watchDoGetListProvince() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_LIST_PROVINCE, doGetListProvince);
}

function* watchDoSelectSizeGrid() {
  yield takeEvery(TYPE_ACTION.LIVE.CHANGE_GRID, doSelectSizeGrid);
}
function* watchDoSelectOpenList() {
  yield takeEvery(TYPE_ACTION.LIVE.OPEN_LIST, doSelectOpenListCam);
}
function* watchDoGetListCameraPreview() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_LIST_CAMERA_PREVIEW,
    doGetListCameraPreview
  );
}
function* watchDoGetDataCameraWithProvince() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_DATA_CAMERA_WITH_PROVINCE,
    doGetCameraDataWithProvince
  );
}
function* watchDoGetDataCameraWithID() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID, doGetCameraDataWithID);
}
function* watchDoGetDistrictLive() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_DISTRICT_LIVE, doGetDistrict);
}
function* watchDoGetWardLive() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_WARD_LIVE, doGetWard);
}
function* watchDoGetDevice() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_DEVICE_LIVE, doGetDevice);
}

function* watchDoGetAllCameraStream() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_ALL_CAMERA_STREAM, doGetAllCameraStream);
}

function* watchDoStopStream() {
  yield takeEvery(TYPE_ACTION.LIVE.STOP_STREAM, doStopStream);
}
function* watchDoStopAllStream() {
  yield takeEvery(TYPE_ACTION.LIVE.STOP_ALL_STREAM, doStopAllStream);
}
function* watchCreateGroup() {
  // yield takeEvery(TYPE_ACTION.LIVE.CREATE_GROUP, doCreateGroup);
}
function* watchCreateCamera() {
  yield takeEvery(TYPE_ACTION.LIVE.CREATE_CAMERA, doCreateCamera);
}
function* watchSearchCameraMap() {
  yield takeEvery(TYPE_ACTION.LIVE.SEARCH_CAMERA, doGetSearchCamera);
}
function* watchGetGroup() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_LIVE_GROUP, doGetGroup);
}
function* watchGetCameraNonAddress() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_CAMERA_NON_ADDRESS,
    doGetCameraNonAddress
  );
}
function* watchDeleteCameraNonAddress() {
  yield takeEvery(TYPE_ACTION.LIVE.DELETE_CAMERA, doDeleteCameraNonAddress);
}

function* watchGetCameraWithIDTree() {
  yield takeEvery(
    TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
    doGetCameraDataWithIDTree
  );
}

function* watchCreateTabLayout() {
  yield takeEvery(TYPE_ACTION.LIVE.CREATE_TAB, doCreateTab);
}

function* watchGetTabLayout() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_TAB, doGetTab);
}

function* watchDelete() {
  yield takeEvery(TYPE_ACTION.LIVE.DELETE_TAB, deleteTab);
}
function* watchUpdateTab() {
  yield takeEvery(TYPE_ACTION.LIVE.UPDATE_TAB, doUpdateTab);
}

function* watchGetListGroup() {
  yield takeEvery(TYPE_ACTION.LIVE.GET_LIST_GROUP_LIVE, getListGroup);
}
function* watchAddCameraInTab() {
  yield takeEvery(TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB, addCameraInTab);
}
function* watchRemoveCameraInTab() {
  yield takeEvery(TYPE_ACTION.LIVE.REMOVE_CAMERA_IN_TAB, removeCameraInTab);
}
function* watchDoGetRecord() {
  yield takeEvery(TYPE_ACTION.PLAYBACK.GET_RECORDER_PLAYBACK, getRecord);
}
function* watchDoGetVideoRRecord() {
  yield takeEvery(TYPE_ACTION.PLAYBACK.GET_VIDEO_RECORD_PLAYBACK, doGetCameraRecord);
}



export {
  watchDoSelectSizeGrid,
  watchDoSelectOpenList,
  watchDoGetListCameraPreview,
  watchDoGetListProvince,
  watchDoGetDataCameraWithProvince,
  watchDoGetDataCameraWithID,
  watchDoGetDistrictLive,
  watchDoGetWardLive,
  watchDoGetDevice,
  watchDoGetAllCameraStream,
  watchDoStopStream,
  watchDoStopAllStream,
  watchCreateGroup,
  watchSearchCameraMap,
  watchGetGroup,
  watchCreateCamera,
  watchGetCameraNonAddress,
  watchDeleteCameraNonAddress,
  watchGetCameraWithIDTree,
  watchCreateTabLayout,
  watchGetTabLayout,
  watchDelete,
  watchUpdateTab,
  watchGetListGroup,
  watchAddCameraInTab,
  watchRemoveCameraInTab,
  watchDoGetRecord,
  watchDoGetVideoRRecord
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToggle: 0,
  isSidebar: true,
  isActionCamera: false,
  keyStream: 0,
  isOpenScanMap: false,
  isLoadingMap: false,
  isLoadingTables: false,
  isLoadingTab: false,
  excessWidth: 260,
  treeWidth: 400,
  isShowScanTable: false,
  listCameraScanNew: [],
  listIdScan: [],
  listProcessScan: [],
  currentProcess: 0,
  maxProcess: 0,
  isDoneProcess: false,
  dataCameraSingle: null,
  listCameraTick:[],
  listNVRScan:[]
};

const actionSilce = createSlice({
  name: "action",
  initialState,
  reducers: {
    doIsTogle: (state, action) => {
      state.isToggle = action.payload;
    },
    doIsSidebar: (state, action) => {
      state.isSidebar = action.payload;
    },
    doIsActionCamera: (state, action) => {
      state.isActionCamera = action.payload;
    },
    changeKeyStream: (state, action) => {
      state.keyStream += action.payload;
    },
    handleOpenScanMap: (state, action) => {
      state.isOpenScanMap = action.payload;
    },
    handleLoadingMap: (state, action) => {
      state.isLoadingMap = action.payload;
    },
    handleLoadingTables: (state, action) => {
      state.isLoadingTables = action.payload;
    },
    handleClearToggle: (state, action) => {
      state.isToggle = 0;
    },
    handleLoadingTab: (state, action) => {
      state.isLoadingTab = action.payload;
    },
    handleChangeExcessWidth: (state, action) => {
      const { open, width } = action.payload;
      if (open) {
        state.excessWidth = width;
      } else {
        state.excessWidth = width;
      }
    },
    handleChangeTreeWidth: (state, action) => {
      const { open, width } = action.payload;
      if (open) {
        state.treeWidth = width;
      } else {
        state.treeWidth = width;
      }
    },
    handleChangeDrawerTable: (state, action) => {
      state.isShowScanTable = action.payload;
    },
    handleAddCameraScanNew: (state, action) => {
      const exists = state.listCameraScanNew.some(
        (item) => item.id === action.payload.id
      );
      if (!exists) {
        state.listCameraScanNew.push(action.payload);
      }
    },
    handleAddCameraScanNVR: (state, action) => {
      const existsNVR = state.listNVRScan.some(
        (item) => item.id === action.payload.id
      );
      if (!existsNVR) {
        state.listNVRScan.push(action.payload);
      }
    },
    handleGetIdScan: (state, action) => {
      state.listIdScan.push(action.payload);
    },
    handleClearCameraScanNew: (state, action) => {
      state.listCameraScanNew = [];
    },
    handleProgress: (state, action) => {
      const { id, total, current, isDone, fromIp, toIp } = action.payload;
      const existingIndex = state.listProcessScan.findIndex(
        (item) => item.id === id
      );
      if (existingIndex === -1) {
        state.listProcessScan.push({
          id,
          total,
          current,
          fromIp,
          toIp,
          isDone,
        });
      } else {
        state.listProcessScan[existingIndex].current = current;
        state.listProcessScan[existingIndex].total = total;
        state.listProcessScan[existingIndex].isDone = isDone;
      }
    },
    handleCancelProgress:(state,action)=>{
      state.listProcessScan = state.listProcessScan.filter(
        (item) => item.id !== action.payload
      );
    },
    handleDoneProcess: (state, action) => {
      state.isDoneProcess = action.payload;
    },
    handleGetCameraSingle: (state, action) => {
      state.dataCameraSingle = action.payload;
    },
    handleGetCameraTick: (state, action) => {
      const index = state.listCameraTick.findIndex(
        (camera) => camera.id === action.payload.id
      );
      if (index === -1) {
        state.listCameraTick.push(action.payload);
      } else {
        state.listCameraTick.splice(index, 1);
      }
    },
    handleClearCameraTick : (state, action) => {
      state.listCameraTick = [];
    }
  },
});

export const {
  doIsTogle,
  doIsSidebar,
  doIsActionCamera,
  changeKeyStream,
  handleOpenScanMap,
  handleLoadingMap,
  handleLoadingTables,
  handleClearToggle,
  handleLoadingTab,
  handleChangeExcessWidth,
  handleChangeTreeWidth,
  handleChangeDrawerTable,
  handleAddCameraScanNew,
  handleGetIdScan,
  handleClearCameraScanNew,
  handleProgress,
  handleDoneProcess,
  handleGetCameraSingle,
  handleGetCameraTick,
  handleClearCameraTick,
  handleAddCameraScanNVR,
  handleCancelProgress
} = actionSilce.actions;
export const selectToggle = (state) => state.action.isToggle;
export const selectSidebar = (state) => state.action.isSidebar;
export const selectActionCamera = (state) => state.action.isActionCamera;
export const selectChangeKeyStream = (state) => state.action.keyStream;
export const isOpenScanMap = (state) => state.action.isOpenScanMap;
export const selectIsLoadingMap = (state) => state.action.isLoadingMap;
export const selectisLoadingTables = (state) => state.action.isLoadingTables;
export const selectisLoadingTab = (state) => state.action.isLoadingTab;
export const selectisexcessWidth = (state) => state.action.excessWidth;
export const selectistreeWidth = (state) => state.action.treeWidth;
export const selectisShowScanTable = (state) => state.action.isShowScanTable;
export const selectlistCameraScanNew = (state) =>
  state.action.listCameraScanNew;
export const selectlistIdScan = (state) => state.action.listIdScan;
export const selectcurrentProcess = (state) => state.action.currentProcess;
export const selectmaxProcess = (state) => state.action.maxProcess;
export const selectisDoneProcess = (state) => state.action.isDoneProcess;
export const selectlistProcessScan = (state) => state.action.listProcessScan;
export const selectdataCameraSingle = (state) => state.action.dataCameraSingle;
export const selectlistCameraTick = (state) => state.action.listCameraTick;
export const selectlistNVRScan = (state) => state.action.listNVRScan;
export default actionSilce.reducer;

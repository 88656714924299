import { SERVICE_PATHS } from "@constants/url";
import { get, post, remove, patch, put } from "src/api/api-map";
const DeviceService = {
  getListDevice(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString != "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString == "?") {
      return get(SERVICE_PATHS.CAMERA.GET_DEVICE);
    }

    return get(`${SERVICE_PATHS.CAMERA.GET_DEVICE}${filterString}`);
  },

  getInfoCameraWithId(body) {
    return get(`${SERVICE_PATHS.MAP.GET_CAMERA}?ids=${body}&detail=true`);
  },
  updateCamera: (body) => {
    return patch(`${SERVICE_PATHS.MAP.GET_CAMERA}`, body);
  },
  updateAddress: (body) => {
    return patch(`${SERVICE_PATHS.MAP.GET_ADDRESS}`, body);
  },
  removeCamera: (body) => remove(`${SERVICE_PATHS.MAP.GET_CAMERA}${body}`, ),
};

export default DeviceService;

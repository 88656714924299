import TYPE_ACTION from "@constants/action";
import JobService from "@redux/services/setting-job-servive";
import {} from "@redux/slice/ai-slice";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";

function* doGetAllJob(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(JobService.getAllJob, body);
    const { data } = response;
    callBack(data);
    // yield put(handleGetListAiFlow(data));
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}

function* doCreateJob(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(JobService.createJob, body);
    const { data } = response;
    callBack(data);
    message.success("Thêm mới thành công!");
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doUpdateJob(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(JobService.updateJob, body);
    const { data } = response;
    callBack(data);
    message.success("Chỉnh sửa thành công!");
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doDeleteJob(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(JobService.deleteJob, body);
    const { data } = response;
    callBack(data);
    message.success("Xóa thành công!");
  } catch (error) {
    console.log("Error update: ", error);
  }
}

// -- Watcher
function* watchGetAllJob() {
  yield takeEvery(TYPE_ACTION.SETTING.GET_ALL_JOB, doGetAllJob);
}
function* watchCreateJob() {
  yield takeEvery(TYPE_ACTION.SETTING.CREATE_JOBS, doCreateJob);
}
function* watchUpdateJob() {
  yield takeEvery(TYPE_ACTION.SETTING.UPDATE_JOBS, doUpdateJob);
}
function* watchDeleteJob() {
  yield takeEvery(TYPE_ACTION.SETTING.DELETE_JOBS, doDeleteJob);
}
export { watchGetAllJob, watchCreateJob, watchDeleteJob, watchUpdateJob };

import colors from "@constants/colors";
import { ConfigProvider, Form, Input, Select, Typography } from "antd";
import CustomTable from "@common/table/custom-table";
import { useEffect, useRef, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import icon_dg from "@assets/Dropdown.svg";
import human_icon from "@assets/Human.svg";
import vehicle_icon from "@assets/Vehicle.svg";
import { useLocation, useNavigate } from "react-router";
import PATH from "@constants/path";
import edit_icon from "@assets/Edit.svg";
import delete_icon from "@assets/Delete.svg";
import button_add from "@assets/Button.svg";
import {
  selectDataGroup,
  handleGetDataGroup,
  selectListAddCamera,
  selectDataFill,
  handleDeleteCameraGroup,
  handleReset,
} from "@redux/slice/edit-group-slice";
import {
  selectDataDistrict,
  selectDataProvince,
  selectDataWard,
} from "@redux/slice/map-slice";
import { handleLoadingTables } from "@redux/slice/action-slice";
import CustomSelect from "@common/select/custom-select";
const EditGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listCamera = useSelector(selectDataGroup);
  const listCameraAdd = useSelector(selectListAddCamera);
  const dataGroup = useSelector(selectDataFill);
  const [listCameraCopy, setListCameraCopy] = useState(listCameraAdd);
  const [listCameraDelete, setListCameraDelete] = useState([]);
  const [disableDistrict, setDisableDistrict] = useState(true);
  const dataWard = useSelector(selectDataWard);
  const dataProvince = useSelector(selectDataProvince);
  const dataDistrict = useSelector(selectDataDistrict);
  const [disableWard, setDisableWard] = useState(true);
  const [form] = Form.useForm();
  const location = useLocation();
  const [isNVR, setisNVR] = useState(false);
  const { groupID } = location.state || null;
  const filterDataRef = useRef({
    name: null,
    active: true,
    description: null,
  });
  function compareArrays(oldArray, newArray) {
    const oldIds = new Set(oldArray.map((item) => item.id));
    const addedItems = newArray.filter((item) => !oldIds.has(item.id));
    const newIds = new Set(newArray.map((item) => item.id));
    const removedItems = oldArray.filter((item) => !newIds.has(item.id));

    return {
      added: addedItems,
      removed: removedItems,
    };
  }
  const filterDataCameRef = useRef({
    provinceIds: null,
    districtIds: null,
    communeIds: null,
  });

  //filter vị trí
  const loadDataDistrict = (id) => {
    const callback = (data) => {};
    dispatch({
      type: TYPE_ACTION.MAP.GET_DISTRICT,
      payload: { body: { provinceIds: id }, callback },
    });
  };
  const loadDataWard = (id) => {
    const callback = (data) => {};
    dispatch({
      type: TYPE_ACTION.MAP.GET_WARD,
      payload: { body: { districtIds: id }, callback },
    });
  };
  const loadDataProvince = () => {
    dispatch({
      type: TYPE_ACTION.MAP.GET_PROVINCE,
      payload: { body: {} },
    });
  };
  useEffect(() => {
    loadDataProvince();
  }, []);
  const arrProvince = dataProvince.map((item) => ({
    value: item.id,
    label: item.name,
    center: [item.longitude, item.latitude],
  }));
  const arrDistrict = dataDistrict.map((item) => ({
    value: item.id,
    label: item.name,
    provinId: item.provinceId,
    center: [item.latitude, item.longitude],
  }));

  const arrWard = dataWard.map((item) => ({
    value: item.id,
    label: item.name,
    provinId: item.provinceId,
    center: [item.latitude, item.longitude],
  }));
  const onSelectProvince = async (e, info) => {
    filterDataCameRef.current.provinceIds = e;
    form.setFieldsValue({ province: e, district: null, ward: null });
    filterDataCameRef.current.districtIds = null;
    filterDataCameRef.current.communeIds = null;
    await loadDataDistrict(e);
    setDisableDistrict(false);
  };
  const onSelectDistric = async (e, info) => {
    filterDataCameRef.current.districtIds = e;
    form.setFieldsValue({ district: e, ward: null });
    await loadDataWard(e);
    setDisableWard(false);
  };
  const onSelectWard = async (e, info) => {
    filterDataCameRef.current.communeIds = e;
    form.setFieldsValue({ ward: e });
  };

  const loadDataGroup = () => {
    const callBack = (data) => {
      dispatch(handleGetDataGroup(data));
      setListCameraCopy(listCameraAdd);
      if (data[0].type === "NVR") {
        setisNVR(true);
      }

      filterDataRef.current.active = data[0].activate;
    };
    dispatch({
      type: TYPE_ACTION.GROUP.GET_LIST_GROUP,
      payload: { body: { ids: groupID, detail: true }, callBack: callBack },
    });
  };
  useEffect(() => {
    loadDataGroup();
  }, []);
  const onFinish = (value) => {
    const updateCameraGroup = (values, callBack) => {
      dispatch({
        type: TYPE_ACTION.GROUP.UPDATE_CAMERA_GROUP,
        payload: { body: values, callBack },
      });
    };

    const handleCameraUpdates = (
      cameraIdsDelete,
      cameraIdsAdd,
      listCameraDelete
    ) => {
      if (cameraIdsDelete.length > 0) {
        dispatch({
          type: TYPE_ACTION.GROUP.DELETE_CAMERA_ON_GROUP,
          payload: {
            body: { id: groupID, ids: cameraIdsDelete },
            callBack: () => {},
          },
        });
      }
      if (listCameraDelete.length > 0) {
        dispatch({
          type: TYPE_ACTION.GROUP.DELETE_CAMERA_ON_GROUP,
          payload: {
            body: { id: groupID, ids: listCameraDelete },
            callBack: () => {},
          },
        });
      }

      if (cameraIdsAdd.length > 0) {
        dispatch({
          type: TYPE_ACTION.GROUP.ADD_CAMERA_ON_GROUP,
          payload: {
            body: { id: groupID, ids: cameraIdsAdd },
            callBack: () => {},
          },
        });
      }
    };

    const callBack = async () => {
      navigate("/" + PATH.MAP + "/" + PATH.CAMERA_GROUP);
      const result = compareArrays(listCamera, listCameraAdd);
      const cameraIdsDelete = result.removed.map((item) => item.id);
      const cameraIdsAdd = result.added.map((item) => item.id);
      await handleCameraUpdates(
        cameraIdsDelete,
        cameraIdsAdd,
        listCameraDelete
      );
    };

    const values = {
      id: groupID,
      name: value.name,
      description: value.detail,
      activate: JSON.stringify(filterDataRef.current.active),
    };

    updateCameraGroup(values, callBack);
    dispatch(handleReset());
  };
  useEffect(() => {
    form.setFieldsValue({
      quantity: listCameraCopy.length,
      name: dataGroup?.name,
      detail: dataGroup?.description,
      status: dataGroup?.activate ? "Hoạt động" : "Không hoạt động",
      type: dataGroup?.type,
      lat: dataGroup?.lat,
      long: dataGroup?.long,
      address: dataGroup?.address,
      nation: dataGroup?.nation,
      province: dataGroup?.province,
      district: dataGroup?.district,
      ward: dataGroup?.ward,
    });
  }, [dataGroup]);
  useEffect(() => {
    form.setFieldsValue({
      quantity: listCameraCopy.length,
    });
  }, [listCameraCopy]);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    filterDataRef.current.active = value;
  };

  const data = [];
  const handleAddCameraInGroup = () => {
    dispatch(handleDeleteCameraGroup({ listCamera: listCamera, idRemove: "" }));
    navigate("/" + PATH.MAP + "/" + PATH.ADD_CAMERA);
  };
  const handleBack = () => {
    navigate(-1);
    dispatch(handleReset());
  };
  const handleEdit = (value) => {
    navigate(`/${PATH.MAP}/${PATH.REGION}/edit/${value.id}`);
  };
  const handleDelete = (value) => {
    dispatch(
      handleDeleteCameraGroup({
        listCamera: listCameraCopy,
        idRemove: value.id,
      })
    );
    setListCameraCopy((prevList) =>
      prevList.filter((item) => item.id !== value.id)
    );
  };
  const handleColorStatus = (status) => {
    if (status === "CONNECTED") {
      return "#006400";
    } else if (status === "DISCONNECTED") {
      return "#706F80";
    } else if (status === "CONNECTING") {
      return "#3A5EF6";
    }
  };
  const handleStatus = (status) => {
    if (status === "CONNECTED") {
      return "Hoạt động";
    } else if (status === "DISCONNECTED") {
      return "Mất kết nối";
    } else if (status === "CONNECTING") {
      return "Kết nôi";
    }
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
    },
    {
      title: "Tên camera",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "addressIp",
      key: "addressIp",
      width: 180,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Bài toán AI",
      dataIndex: "ai",
      key: "ai",
      width: 160,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 160,
    },
    {
      title: "Tác vụ",
      dataIndex: "action",
      key: "action",
      width: 160,
    },
  ];
  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(listCameraCopy) &&
      listCameraCopy.map((dataIndex, index) => {
        data.push({
          key: index,
          stt: index + 1,
          id: dataIndex.id,
          name: dataIndex.name,
          company: dataIndex.company,
          address: <div className="truncate">{dataIndex.address}</div>,
          status: (
            <div>
              <CustomButtonCommon
                backgroundColor={handleColorStatus(dataIndex.status)}
                text={handleStatus(dataIndex.status)}
                border="none"
              ></CustomButtonCommon>
            </div>
          ),
          addressIp: dataIndex.ipAddress,
          ai: (
            <div className="flex justify-center items-center gap-[12px]">
              <img className="cursor-pointer" src={human_icon} alt="human" />
              <img
                className="cursor-pointer"
                src={vehicle_icon}
                alt="vehicle"
              />
            </div>
          ),
          action: (
            <div className="flex justify-center items-center gap-[20px]">
              <img
                className="cursor-pointer"
                src={edit_icon}
                onClick={() => handleEdit(dataIndex)}
                alt="delete"
              />
              <img
                className="cursor-pointer"
                src={delete_icon}
                onClick={() => handleDelete(dataIndex)}
                alt="delete"
              />
            </div>
          ),
        });
      });
  }
  return (
    <div className=" w-full h-full">
      <div className="h-full w-full space-y-[12px] p-2">
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex justify-between bg-[#181824] p-[20px]">
            <div className="flex">
              <img
                color="#9D9D9D"
                className="cursor-pointer max-h-[35px]"
                src={icon_dg}
                alt="dropdown"
                onClick={() => {
                  handleBack();
                }}
              />
              <CustomButtonCommon
                text={<span className="font-bold">Quay lại</span>}
                backgroundColor="#181824"
                textColor="#5B5B9F"
                border="none"
                onClick={() => {
                  handleBack();
                }}
              />
              <CustomButtonCommon
                text={dataGroup?.name}
                backgroundColor={"#FDEBF0"}
                textColor="#5B5B9F"
                border="none"
                height="33px"
                className="cursor-default"
              />
            </div>
            <div className="flex gap-[16px]">
              <CustomButtonCommon
                text="Hủy"
                width="101px"
                backgroundColor="#434343"
                border="none"
                onClick={() => {
                  handleBack();
                }}
              />
              <Form.Item>
                <CustomButtonCommon
                  text="Lưu"
                  width="101px"
                  backgroundColor={colors.redGlobal}
                  htmlType="submit"
                />
              </Form.Item>
            </div>
          </div>
          <div className="bg-[#181824] p-[20px]">
            <div className="grid grid-cols-4 gap-[12px]">
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 4,
                    colorBgBase: "#181824",
                    controlHeight: 40,
                    controlOutlineWidth: 0,
                    colorText: colors.textBase,
                    colorTextPlaceholder: "#706F80",
                    colorBorder: colors.bg_new,
                    controlItemBgActive: "#5B5B9F",
                  },
                }}
              >
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Tên nhóm *</span>
                  </Typography.Title>
                  <Form.Item
                    name="name"
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Không được bỏ trống trường này",
                      },
                      {
                        max: 50,
                        message: "Tối đa 50 ký tự",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập tên nhóm" />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Số lượng</span>
                  </Typography.Title>
                  <Form.Item name="quantity">
                    <Input readOnly />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Trạng thái</span>
                  </Typography.Title>
                  <Form.Item name="status">
                    <Select
                      disabled={isNVR}
                      className="w-full"
                      onChange={handleChange}
                      options={[
                        {
                          value: true,
                          label: "Hoạt động",
                        },
                        {
                          value: false,
                          label: "Không hoạt động",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Mô tả</span>
                  </Typography.Title>
                  <Form.Item
                    name="detail"
                    rules={[
                      {
                        max: 100,
                        message: "Tối đa 100 ký tự",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập mô tả" />
                  </Form.Item>
                </div>
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Phân loại</span>
                    </Typography.Title>
                    <Form.Item name="type">
                      <Input readOnly />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Kinh độ</span>
                    </Typography.Title>
                    <Form.Item name="lat">
                      <Input readOnly />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Vĩ độ</span>
                    </Typography.Title>
                    <Form.Item name="long">
                      <Input readOnly />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Vị trí</span>
                    </Typography.Title>
                    <Form.Item name="address">
                      <Input placeholder="Nhập vị trí" />
                    </Form.Item>
                  </div>
                )}

                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Quốc gia</span>
                    </Typography.Title>
                    <Form.Item name="nation">
                      <CustomSelect
                        className="w-full"
                        placeholder="Chọn quốc gia"
                        onChange={handleChange}
                        options={[]}
                        disabled
                      />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Tỉnh/ Thành phố</span>
                    </Typography.Title>
                    <Form.Item name="province">
                      <Form.Item name="city">
                        <CustomSelect
                          lineHeight="0px"
                          font="font-normal"
                          width="100%"
                          title="Tỉnh"
                          placeholder="Chọn tỉnh thành phố"
                          allowClear={true}
                          onSelect={onSelectProvince}
                          onClear={onSelectProvince}
                          option={arrProvince}
                        />
                      </Form.Item>
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Quận/Huyện</span>
                    </Typography.Title>
                    <Form.Item name="district">
                      <CustomSelect
                        lineHeight="0px"
                        font="font-normal"
                        width="100%"
                        title="Huyện"
                        disabled={disableDistrict}
                        disabledClass={false}
                        allowClear={true}
                        placeholder="Chọn Quận huyện"
                        onSelect={onSelectDistric}
                        option={arrDistrict}
                        onClear={onSelectDistric}
                      />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Phường/Xã</span>
                    </Typography.Title>
                    <Form.Item name="ward">
                      <CustomSelect
                        lineHeight="0px"
                        font="font-normal"
                        width="100%"
                        title="Phường"
                        disabled={disableWard}
                        disabledClass={false}
                        allowClear={true}
                        placeholder="Chọn Phường xã"
                        option={arrWard}
                        onSelect={onSelectWard}
                        onClear={onSelectWard}
                        isfilterOption={false}
                      />
                    </Form.Item>
                  </div>
                )}
              </ConfigProvider>
            </div>
          </div>
        </Form>
        <div className="h-[12px] bg-[#181824]">
          <div className="bg-[#181824] p-[20px]">
            <div className="flex items-center">
              <span
                style={{ color: colors.textBase, fontWeight: 600 }}
                className="pr-6"
              >
                DANH SÁCH CAMERA ĐÃ CHỌN
              </span>
              {!isNVR && (
                <img
                  className="cursor-pointer max-h-[36px]"
                  src={button_add}
                  alt="add"
                  width={36}
                  onClick={() => {
                    handleAddCameraInGroup();
                  }}
                />
              )}
            </div>
            <CustomTable
              data={data}
              columns={columns}
              showTotal={false}
              pagination={true}
              showCheckBox={false}
              bordered={false}
              heightPart={680}
              handleRowClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditGroup;

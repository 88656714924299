import colors from "@constants/colors";
import { ConfigProvider, Switch } from "antd";
import CustomTable from "@common/table/custom-table";
import { useEffect, useRef, useState } from "react";
import CustomButtonCommon from "@common/button/custom-button";
import search_icon from "@assets/Them.svg";
import edit_icon from "@assets/Edit.svg";
import delete_icon from "@assets/Delete.svg";
import CustomModal from "@common/modal/modal";
import Search from "@common/search/custom-search";
import ContentSetting from "./modelSetting";
import { useDispatch } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { handleDataEdit, handleReset } from "@redux/slice/setting-job-slice";
const Setting = () => {
  const [dataList, setDataList] = useState([]);
  const dispatch = useDispatch();
  const filterDataJobRef = useRef({
    name: null,
    detail: true,
  });
  const handleGetData = () => {
    const callBack = (data) => {
      setDataList(data);
    };
    dispatch({
      type: TYPE_ACTION.SETTING.GET_ALL_JOB,
      payload: { body: filterDataJobRef.current, callBack },
    });
  };

  useEffect(() => {
    handleGetData();
  }, []);
  const handleEdit = (e, data) => {
    e.stopPropagation();
    setopenTab(true);
    dispatch(handleDataEdit(data));
  };
  const handleDelete = (e, data) => {
    e.stopPropagation();
    const callBack = () => {
      handleGetData();
    };
    dispatch({
      type: TYPE_ACTION.SETTING.DELETE_JOBS,
      payload: { body: data.id, callBack },
    });
  };
  const [isSelectTable, setIsSelectTable] = useState(false);
  const onChange = (checked, dataIndex) => {
    const callBack = () => {
      handleGetData();
    };
    const body = {
      id: dataIndex.id,
      activate: checked,
      fromIp: dataIndex.fromIp,
      toIp: dataIndex.toIp,
    };
    dispatch({
      type: TYPE_ACTION.SETTING.UPDATE_JOBS,
      payload: { body: body, callBack },
    });
  };
  const [openTab, setopenTab] = useState(false);
  const handleSelectCheckbox = (e) => {
    if (e.length > 0 || e === true) {
      if (Array.isArray(e)) {
        let listId = [];
        e.map((item) => {
          listId.push(item.id);
        });
        // setListCameraDelete(listId);
        // setListCameraTab(listId);
      }
      setIsSelectTable(true);
    } else {
      setIsSelectTable(false);
    }
  };
  const data = [];
  const resetContentSetting = () => {
    // Logic để reset state nếu cần
  };
  const handleCancel = () => {
    resetContentSetting();
    setopenTab(false);
    dispatch(handleReset());
  };

  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(dataList) &&
      dataList.map((dataIndex, index) => {
        data.push({
          key: index + 1,
          id: dataIndex.id,
          stt: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {index + 1}
            </div>
          ),
          fromIp: dataIndex.fromIp,
          toIp: dataIndex.toIp,
          period: (
            <div className="truncate">{dataIndex.scanDays.join(", ")}</div>
          ),
          action: (
            <div className="flex justify-center items-center gap-[20px]">
              <Switch
                checked={dataIndex.activate}
                onChange={(checked) => {
                  onChange(checked, dataIndex);
                }}
              />
              <img
                className="cursor-pointer"
                src={edit_icon}
                onClick={(e) => handleEdit(e, dataIndex)}
                alt="edit"
              />
              <img
                className="cursor-pointer"
                src={delete_icon}
                onClick={(e) => handleDelete(e, dataIndex)}
                alt="delete"
              />
            </div>
          ),
        });
      });
  }
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 80,
    },
    {
      title: "TỪ IP",
      dataIndex: "fromIp",
      key: "fromIp",
    },
    {
      title: "ĐẾN IP",
      dataIndex: "toIp",
      key: "toIp",
    },
    {
      title: "CHU KỲ QUÉT(NGÀY)",
      dataIndex: "period",
      key: "period",
    },
    {
      title: "HÀNH ĐỘNG",
      dataIndex: "action",
      key: "action",
      width: 160,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,
          colorBgBase: "#181824",
          colorBorder: colors.borderColor,
          controlHeight: 40,
          controlOutlineWidth: 0,
          colorText: colors.textBase,
          controlItemBgActive: "#5B5B9F",
        },
      }}
    >
      <div className="p-2 w-full h-full space-x-5 flex overflow-hidden">
        <div
          className="h-full w-full space-y-[27px] p-[20px]"
          style={{ background: "#181824" }}
        >
          <div className="flex justify-between items-start">
            <div className="grid grid-cols-2 gap-[12px]">
              <div className="w-[214px]">
                <Search />
              </div>
            </div>
            <div className="flex justify-between items-center px-3 gap-4">
              <CustomButtonCommon
                text="Thêm"
                icon={<img src={search_icon} alt="search" />}
                backgroundColor={colors.redGlobal}
                onClick={() => {
                  setopenTab(true);
                }}
                className="flex justify-center items-center"
              />
              <CustomButtonCommon
                text="Xóa"
                disabled={!isSelectTable}
                icon={<img src={delete_icon} alt="delete" />}
                className="flex justify-center items-center"
              />
            </div>
          </div>
          <CustomTable
            data={data}
            columns={columns}
            showTotal={false}
            pagination={true}
            showCheckBox={true}
            bordered={false}
            heightPart={300}
            hasSelectCheckbox={(e) => handleSelectCheckbox(e)}
            handleRowClick={() => {}}
          />
          <CustomModal
            centered={true}
            open={openTab}
            width={"1076px"}
            handleCancel={handleCancel}
            background="#2B2A3A"
            content={
              <ContentSetting
                handleCancel={handleCancel}
                resetContent={resetContentSetting}
                handleGetData={handleGetData}
              />
            }
            renderHeader={
              <p className="text-[#E3E3E3] text-[16px] uppercase font-semibold">
                THÊM MỚI CẤU HÌNH QUÉT CAMERA
              </p>
            }
          />
        </div>
      </div>
    </ConfigProvider>
  );
};
export default Setting;

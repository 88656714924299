import CustomTable from "@common/table/custom-table";
import CustomHeaderDevice from "@components/device/custom-header-device";
import CustomSelect from "@common/select/custom-select";
import { ConfigProvider, message, Tooltip } from "antd";
import Search from "@common/search/custom-search";
import edit from "@assets/icon/edit.svg";
import live from "@assets/icon/live.svg";
import liveActive from "@assets/icon/live_active.svg";
import trash from "@assets/icon/trash.svg";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import iconPerson1 from "@assets/icon/Person.svg";
import iconPerson from "@assets/icon/Person.svg";
import iconCar from "@assets/icon/Car1.svg";
import iconCar1 from "@assets/icon/Car.svg";
import iconCamera from "@assets/icon/camera.svg";
import CustomModal from "@common/modal/modal";
import { useEffect, useRef, useState } from "react";
import iconzoom from "@assets/icon/zoomcamera.svg";
import iconViewStream from "@assets/icon/viewStream.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectdataCameraTable,
  selecttotalElements,
} from "@redux/slice/map-slice";
import {
  doIsTogle,
  handleChangeDrawerTable,
  handleLoadingTables,
  selectisLoadingTables,
} from "@redux/slice/action-slice";
import { useSearchParams } from "react-router-dom";
import TYPE_ACTION from "@constants/action";
import FLVPlayer from "@components/live/flv-player";
import PopupTab from "./pop-tab";
import { handleAddCamera } from "@redux/slice/edit-group-slice";
import CustomButtonCommon from "@common/button/custom-button";
import colors from "@constants/colors";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  convertDate,
  getCurrentISODate,
  convertToISO,
} from "@utils/format-date";
import { DatePicker } from "antd";
import viVN from "antd/locale/vi_VN";
import dayjs from "dayjs";

import "dayjs/locale/vi"; // Import ngôn ngữ tiếng Việt cho dayjs
import { urlKeyPoint } from "@utils/index";
import iconSuccess from "@assets/map/noti_success.svg";
dayjs.locale("vi");
const { RangePicker } = DatePicker;
const TableDevice = ({ filterLocationCameraRef, listWard,showDistrict }) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let provinceID = searchParams.get("provinceIds");
  let districtIds = searchParams.get("districtIds");
  let communeIds = searchParams.get("communeIds");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const listDataCamera = useSelector(selectdataCameraTable);
  const isLoading = useSelector(selectisLoadingTables);
  const total = useSelector(selecttotalElements);
  const [urls, seturls] = useState();
  const [listgroup, setlistgroup] = useState([]);
  const data = [];
  const [isSelectTable, setIsSelectTable] = useState(false);
  const [openScan, setopenScan] = useState(false);
  const [openTab, setopenTab] = useState(false);
  const [keyChange, setkeyChange] = useState(0);
  const [keyChangecity, setkeyChangecity] = useState(0);
  const [keyChangeStream, setkeyChangeStream] = useState(0);
  const [idCameraTab, setidCameraTab] = useState();
  const [listCameraTab, setListcameraTab] = useState([]);
  const [listCameraDelete, setListcameraDelete] = useState([]);
  const [listCameraGroup, setListcameraGroup] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [listQuan, setListQuan] = useState([
    {
      value: null,
      label: "Tất cả",
    },
  ]);
  const nameCamera = useRef();
  const handle = useFullScreenHandle();
  const handleCopy = (textToCopy) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);
    textarea.select();

    try {
      document.execCommand("copy");
      messageApi.open({
        type: "success",
        content: (
          <div className="flex items-center justify-center space-x-3">
            <img alt="success" src={iconSuccess} />
            <p>
              Đã sao chép thành công
            </p>
          </div>
        ),
        icon: <div></div>,
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    document.body.removeChild(textarea);
  };
  useEffect(() => {
    if (listWard.length > 0) {
      let list = [
        {
          value: null,
          label: "Tất cả",
        },
      ];
      listWard.map((item) => {
        list.push({
          value: item.value,
          label: item.label,
        });
      });
      setListQuan(list);
    }
  }, [listWard]);
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      width: 70,
      fixed: "left",
    },
    {
      title: "TÊN CAMERA",
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left",
    },
    {
      title: "ĐỊA CHỈ IP",
      dataIndex: "ip",
      key: "ip",
      width: 200,
    },
    {
      title: "ĐỊA CHỈ",
      dataIndex: "address",
      key: "address",
      width: 300,
    },
    {
      title: "NHÓM CAMERA",
      dataIndex: "group",
      key: "group",
      width: 200,
    },
    {
      title: "THỜI GIAN QUÉT",
      dataIndex: "time_scan",
      key: "time_scan",
      width: 120,
    },
    {
      title: "CẬP NHẬT LẦN CUỐI",
      dataIndex: "camera_updated_at",
      key: "camera_updated_at",
      width: 120,
    },
    {
      title: "LUỒNG AI",
      dataIndex: "ai",
      key: "ai",
      width: 200,
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "staus",
      width: 200,
    },
    {
      title: "HÀNH ĐỘNG",
      dataIndex: "action",
      key: "action",
      width: 200,
      fixed: "right",
    },
  ];
  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(listDataCamera) &&
      listDataCamera.map((dataIndex, index) => {
        data.push({
          id: dataIndex.id,
          key: index + 1,
          index:
            index +
            1 +
            filterLocationCameraRef.current.pageNumber *
              filterLocationCameraRef.current.pageSize,
          name: (
            <Tooltip title={"Sao chép"}>
              {" "}
              <div
                className="hover:underline"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopy(dataIndex.name);
                }}
              >
                {dataIndex.name}
              </div>
            </Tooltip>
          ),
          ip: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {dataIndex.ipAddress}
            </div>
          ),
          address: (
            <div className="text-start space-x-1">
              {dataIndex
                ? `
              ${
                dataIndex.addressDTO?.full
                  ? `${dataIndex.addressDTO?.full},`
                  : ""
              }
              ${dataIndex.addressDTO?.provinceDTO.type}  
              ${dataIndex.addressDTO?.provinceDTO.name}, 
              ${dataIndex.addressDTO?.districtDTO.type} 
              ${dataIndex.addressDTO?.districtDTO.name}, 
              ${dataIndex.addressDTO?.communeDTO.type} 
              ${dataIndex.addressDTO?.communeDTO.name}`
                : "-"}
            </div>
          ),
          group: (
            <Tooltip
              title={
                Array.isArray(dataIndex.cameraGroupDTOList) &&
                dataIndex.cameraGroupDTOList.map((item) => item.name).join(", ")
              }
            >
              <div
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {Array.isArray(dataIndex.cameraGroupDTOList) &&
                  dataIndex.cameraGroupDTOList
                    .map((item) => item.name)
                    .join(", ")}
              </div>
            </Tooltip>
          ),
          camera_updated_at: <div>{convertDate(dataIndex.createdDate)}</div>,
          time_scan: <div>{convertDate(dataIndex.createdDate)}</div>,
          ai: (
            <div className="w-full flex items-center justify-center space-x-[10px]">
              <img alt="person" src={iconPerson} />
              <img alt="car" src={iconCar} />
            </div>
          ),
          status: (
            <div className="w-full flex items-center justify-center">
              <div
                className="h-[26px] w-[88px] flex items-center justify-center rounded"
                style={{
                  background:
                    dataIndex.state === "DISCONNECTED" ? "#5B5B9F" : "#006400",
                }}
              >
                {dataIndex.state === "DISCONNECTED"
                  ? "Mất kết nối"
                  : "Hoạt động"}
              </div>
            </div>
          ),
          action: (
            <div className="flex items-center justify-center space-x-[12px]">
              <Tooltip title="Sửa thông tin camera">
                <img
                  src={edit}
                  alt="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(dataIndex);
                  }}
                  className="cursor-pointer"
                />
              </Tooltip>
              <Tooltip title="Xem trực tiếp">
                <img
                  src={dataIndex.state === "DISCONNECTED" ? live : liveActive}
                  alt="view"
                  onClick={(e) => {
                    e.stopPropagation();
                    nameCamera.current = dataIndex.name;
                    handleViewStream(dataIndex.id);
                  }}
                  className="cursor-pointer"
                />
              </Tooltip>
              <Tooltip title="Xoá camera">
                <img
                  src={trash}
                  alt="trash"
                  onClick={(e) => {
                    e.stopPropagation();
                    setidCameraTab(dataIndex.id);
                    handleDelete();
                  }}
                  className="cursor-pointer"
                />
              </Tooltip>
            </div>
          ),
        });
      });
  }
  const handleEdit = (dataIndex) => {
    navigate(`/${PATH.MAP}/${PATH.REGION}/edit/${dataIndex.id}`);
  };
  const handleViewStream = (url) => {
    setkeyChangeStream((pre) => pre + 1);
    setidCameraTab(url);
    seturls();
    setOpen(true);
    const callBack = (stream) => {
      seturls(stream);
    };
    const errorBack = (e) => {
      console.log(e);
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
      payload: { url, callBack, errorBack },
    });
  };
  const handleDelete = () => {
    setOpenDelete(true);
  };
  const handleSelectCheckbox = (e) => {
    if (e.length > 0 || e === true) {
      if (Array.isArray(e)) {
        let listId = [];
        let listgroup = [];
        e.map((item) => {
          listId.push(item.id);
          listgroup.push(item);
        });
        setListcameraGroup(listgroup);
        setListcameraTab(listId);
        setListcameraDelete(listId);
      }
      setIsSelectTable(true);
    } else {
      setIsSelectTable(false);
    }
  };

  const loadDataCame = () => {
    dispatch(handleLoadingTables(true));
    const url = urlKeyPoint(filterLocationCameraRef.current);
    navigate(`/${PATH.MAP}/${PATH.REGION}?${url}`);
    const callBack = (data) => {
      dispatch(handleLoadingTables(false));
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
      payload: { body: filterLocationCameraRef.current, callBack: callBack },
    });
  };

  const handleOnChange = (item) => {
    filterLocationCameraRef.current.name = item.target.value;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };
  useEffect(() => {
    const callBack = (data) => {
      let list = [
        {
          value: null,
          label: "Tất cả",
        },
      ];
      data.map((item) => {
        list.push({
          value: item.id,
          label: item.name,
        });
      });
      setlistgroup(list);
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_GROUP_LIVE,
      payload: { body: {}, callBack },
    });
  }, []);
  const onSelectGroup = (e) => {
    filterLocationCameraRef.current.cameraGroupIds = e;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };
  const handleReload = () => {
    filterLocationCameraRef.current.cameraGroupIds = null;
    filterLocationCameraRef.current.communeIds = null;
    filterLocationCameraRef.current.districtIds = null;
    filterLocationCameraRef.current.name = null;
    filterLocationCameraRef.current.dateFrom = null;
    filterLocationCameraRef.current.dateTo = null;
    setkeyChange((pre) => pre + 1);
    loadDataCame();
  };
  const handleSelectState = (e) => {
    filterLocationCameraRef.current.state = e;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };
  const handleCreateGroup = () => {
    listCameraGroup.map((item) => {
      dispatch(handleAddCamera(item));
    });
    navigate("/" + PATH.MAP + "/" + PATH.ADD_GROUP);
  };
  const [showRange, setShowRange] = useState(false);
  const handleSelecTime = (e) => {
    if (e === "today") {
      setShowRange(false);
      filterLocationCameraRef.current.dateFrom = getCurrentISODate();
      filterLocationCameraRef.current.dateTo = getCurrentISODate();
      filterLocationCameraRef.current.pageNumber =0
      loadDataCame();
    } else {
      setShowRange(true);
    }
  };
  const handleChangeRangeTime = (s, e) => {
    const startTime = convertToISO(`${e[0]} 00:00:00`);
    const endTime = convertToISO(`${e[1]} 23:59:59`);
    filterLocationCameraRef.current.dateFrom = startTime;
    filterLocationCameraRef.current.dateTo = endTime;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };

  const onSelectWard = (e) => {
    setkeyChangecity((pre) => pre + 1);
    setListCity([]);
    const callBack = (data) => {
      const listData = [
        {
          value: null,
          label: "Tất cả",
        },
      ];
      data.map((item) => {
        listData.push({
          value: item.id,
          label: item.name,
        });
      });
      setListCity(listData);
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_WARD_LIVE,
      payload: { body: e, callBack },
    });
    filterLocationCameraRef.current.districtIds = e;
    filterLocationCameraRef.current.communeIds = null;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };
  const onSelectCity = (e) => {
    filterLocationCameraRef.current.communeIds = e;
    filterLocationCameraRef.current.pageNumber =0
    loadDataCame();
  };
  return (
    <>
      {contextHolder}
      <div className="px-2">
        <CustomHeaderDevice
          isSelectTable={isSelectTable}
          handleScan={() => {
            // dispatch(handleChangeDrawerTable(true));
            dispatch(doIsTogle(1));
          }}
          handleReload={() => {
            handleReload();
          }}
          handleLive={() => {
            if (isSelectTable) {
              setopenTab(true);
            }
          }}
          handleCreateGroup={() => {
            if (isSelectTable) {
              handleCreateGroup();
            }
          }}
          handleDelete={() => {
            if (isSelectTable) {
              setOpenDelete(true);
            }
          }}
        />
        <div className="w-full h-[76px] border-b border-t border-[#2B2A3A] flex items-center space-x-[12px]">
          <div className="w-[200px]">
            <Search
              placeholder="Tìm kiếm"
              onChange={(e) => handleOnChange(e)}
            />
          </div>
          <div className="w-[200px] flex items-center h-full">
            <CustomSelect
              key={keyChange}
              placeholder="Nhóm camera"
              option={listgroup}
              onSelect={onSelectGroup}
              allowClear={false}
            />
          </div>
          {
            showDistrict &&  <div className="w-[200px] flex items-center h-full">
            <CustomSelect
              key={keyChange}
              placeholder="Chọn quận huyện"
              option={listQuan}
              onSelect={onSelectWard}
              allowClear={false}
              defaultValue={districtIds?districtIds:undefined}
            />
          </div>
          }
         {
          showDistrict && <div className="w-[200px] flex items-center h-full">
          <CustomSelect
            key={keyChangecity}
            placeholder="Chọn phường xã"
            option={listCity}
            onSelect={onSelectCity}
            allowClear={false}
            disabled={listCity.length < 1}
            defaultValue={communeIds?communeIds:undefined}
          />
        </div>
         }
          
          <div className="w-[200px] flex items-center h-full">
            <CustomSelect
              placeholder="Trạng thái"
              defaultValue={null}
              allowClear={false}
              option={[
                {
                  value: null,
                  label: "Tất cả",
                },
                {
                  value: "CONNECTED",
                  label: "Hoạt động",
                },
                {
                  value: "DISCONNECTED",
                  label: "Không hoạt động",
                },
              ]}
              onSelect={(e) => handleSelectState(e)}
            />
          </div>
          <div className="w-[200px] flex items-center h-full">
            <CustomSelect
              key={keyChange}
              placeholder="Thời gian quét"
              allowClear={false}
              option={[
                {
                  value: "today",
                  label: "Hôm nay",
                },
                {
                  value: "range",
                  label: "Khoảng thời gian",
                },
              ]}
              onSelect={(e) => handleSelecTime(e)}
            />
          </div>
          {showRange && (
            <ConfigProvider
              locale={viVN}
              theme={{
                components: {
                  DatePicker: {
                    colorTextPlaceholder: "#444459",
                    colorBgContainer: "transparent",
                    colorBorder: "#444459",
                    borderRadius: 4,
                    controlHeight: 39,
                    colorPrimaryHover: "none",
                    colorBgContainerDisabled: "#444459",
                    colorText: "white",
                    colorTextDisabled: "#444459",
                    controlOutlineWidth: 0,
                  },
                },
              }}
            >
              <RangePicker
                allowClear={false}
                placeholder={["Bắt đầu", "Kết thúc"]}
                onChange={(s, e) => handleChangeRangeTime(s, e)}
              />
            </ConfigProvider>
          )}
        </div>
        <CustomTable
          data={data}
          columns={columns}
          showTotal={true}
          total={total}
          pagination={true}
          showCheckBox={true}
          loading={isLoading}
          bordered={false}
          hasSelectCheckbox={(e) => handleSelectCheckbox(e)}
          handleRowClick={(record) => {
            navigate(`/${PATH.MAP}/${PATH.REGION}/view/${record.id}`);
          }}
          heightPart={380}
          onSendData={() => {}}
          onChange={(page, size) => {
            if (provinceID) {
              filterLocationCameraRef.current.provinceIds = provinceID;
            } else if (districtIds) {
              filterLocationCameraRef.current.districtIds = districtIds;
            } else if (communeIds) {
              filterLocationCameraRef.current.communeIds = communeIds;
            }
            filterLocationCameraRef.current.pageNumber = page - 1;
            filterLocationCameraRef.current.pageSize = size;
            loadDataCame();
          }}
        />
        <CustomModal
          key={keyChangeStream}
          centered={true}
          open={open}
          width={"max-content"}
          handleCancel={() => {
            seturls(null);
            setOpen(false);
          }}
          content={
            <div className="w-[843px] h-[562px] flex justify-center items-center">
              {urls && (
                <FullScreen handle={handle}>
                  <FLVPlayer flvUrl={urls} controls={false} />
                </FullScreen>
              )}
            </div>
          }
          renderHeader={
            <div className="flex items-center justify-between space-x-[6px]">
              <img alt="iconCamera" src={iconCamera} className="h-[18px]" />
              <p className="font-bold">{nameCamera.current}</p>
            </div>
          }
          renderAction={
            <div className="flex items-center justify-center space-x-[10px]">
              <img
                alt="iconzomm"
                src={iconzoom}
                className="h-[24px]"
                onClick={() => handle.enter()}
              />
              <img
                alt="iconViewStream"
                src={iconViewStream}
                className="h-[20px] cursor-pointer"
                onClick={() => {
                  setopenTab(true);
                  setOpen(false);
                }}
              />
            </div>
          }
        />
        <CustomModal
          centered={true}
          open={openTab}
          width={"584px"}
          handleCancel={() => setopenTab(false)}
          background="#444459"
          content={
            <PopupTab
              idCameraTab={idCameraTab}
              listCameraTab={listCameraTab}
              handleCancel={() => setopenTab(false)}
            />
          }
          renderHeader={
            <p className="text-[#E3E3E3] text-[16px] uppercase font-semibold">
              Chọn tab cần di chuyển
            </p>
          }
        />

        <CustomModal
          centered={true}
          open={openDelete}
          width={"460px"}
          handleCancel={() => {
            setOpenDelete(false);
            setidCameraTab();
            setListcameraDelete([]);
          }}
          content={
            <div className="h-[175px] w-full bg-[#2B2A3A]">
              <div className="h-[100px] w-full flex items-center justify-center">
                <p className="font-semibold text-[16px]">
                  Bạn có chắc chắn muốn xoá?
                </p>
              </div>
              <div className="h-[75px] w-full space-x-[16px] flex items-center justify-center px-[36px]">
                <div className="w-full">
                  <CustomButtonCommon
                    width="100%"
                    height="39px"
                    onClick={() => {
                      let params = "?";
                      if (listCameraDelete.length > 0) {
                        listCameraDelete.map((item) => {
                          params += `&ids=${item}`;
                        });
                      }
                      if (idCameraTab) {
                        params += `ids=${idCameraTab}`;
                      }
                      const callback = (data) => {
                        setOpenDelete(false);
                        if (provinceID) {
                          filterLocationCameraRef.current.provinceIds =  provinceID;
                        } else if (districtIds) {
                          filterLocationCameraRef.current.districtIds  = districtIds;
                        } else if (communeIds) {
                          filterLocationCameraRef.current.communeIds = communeIds;
                        }
                        loadDataCame();
                      };

                      dispatch({
                        type: TYPE_ACTION.CAMERA.DELETE_CAMERA_ID,
                        payload: { body: params, callback },
                      });
                    }}
                    backgroundColor={colors.redGlobal}
                    text={"Xoá"}
                  />
                </div>
                <div className="w-full">
                  <CustomButtonCommon
                    width="100%"
                    height="39px"
                    onClick={() => {
                      setOpenDelete(false);
                      setidCameraTab();
                      setListcameraDelete([]);
                    }}
                    text={"Hủy"}
                  />
                </div>
              </div>
            </div>
          }
          isHeader={false}
        />
      </div>
    </>
  );
};
export default TableDevice;

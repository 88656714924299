import { ConfigProvider, Layout, Menu, Tooltip } from "antd";
import "./tab-style.css";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { FaEarthAmericas } from "react-icons/fa6";
import { MdDeviceHub } from "react-icons/md";
import logoSibar from "@assets/logo_halk.svg";
import ActionRight from "@components/management/action-right";
import PATH from "@constants/path";
import group_icon from "@assets/Group.svg";
import iconMap from "@assets/sidebar/map.svg";
import iconRegion from "@assets/sidebar/region.svg";
import iconGroup from "@assets/sidebar/group.svg";
import iconLive from "@assets/sidebar/live.svg";
import iconMap1 from "@assets/sidebar/map1.svg";
import iconRegion1 from "@assets/sidebar/region1.svg";
import iconGroup1 from "@assets/sidebar/group1.svg";
import iconLive1 from "@assets/sidebar/live1.svg";
import iconSetting1 from "@assets/sidebar/setting1.svg";
import iconSetting from "@assets/sidebar/setting.svg";
import iconPlayback from "@assets/sidebar/playback.svg";
import iconPlayback1 from "@assets/sidebar/playback1.svg";
import openIcon from "@assets/open.svg";
import closeIcon from "@assets/close.svg";
import { useDispatch } from "react-redux";
import { handleChangeExcessWidth } from "@redux/slice/action-slice";
import arrowLeft from "@assets/icon/arrow_left.svg";
import { LogoutOutlined } from "@ant-design/icons";
import colors from "@constants/colors";
const { Content, Sider, Header } = Layout;
const MapLayout = () => {
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const location = useLocation();
  const parts = location.pathname.split("/")[2];
  const [activeKey, setActiveKey] = useState(parts);
  const isBack = JSON.parse(localStorage.getItem("back"));
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = [
    getItem(
      "BẢN ĐỒ CHI TIẾT",
      "map-detail",
      <img alt="iconMap" src={parts === "map-detail" ? iconMap1 : iconMap} />
    ),
    getItem(
      "QUẢN LÝ VÙNG",
      "region",
      <img
        alt="iconRegion"
        src={parts === "region" ? iconRegion1 : iconRegion}
      />
    ),
    getItem(
      "QUẢN LÝ NHÓM CAMERA",
      "camera-group",
      <img
        alt="iconGroup"
        src={parts === "camera-group" ? iconGroup1 : iconGroup}
      />
    ),
    getItem(
      "XEM TRỰC TIẾP",
      "live",
      <img alt="iconLive" src={parts === "live" ? iconLive1 : iconLive} />
    ),
    getItem(
      "XEM LẠI VIDEO RECORD",
      "playback",
      <img alt="iconLive" src={parts === "playback" ? iconPlayback1 : iconPlayback} />
    ),
    getItem(
      "CẤU HÌNH THỜI GIAN",
      "setting",
      <img
        alt="iconLive"
        src={parts === "setting" ? iconSetting1 : iconSetting}
      />
    ),
  ];

  const handleSelectMenu = (key) => {
    navigate(`/${PATH.MAP}/${key.key}`);
    const selectedKey = key.key;
    localStorage.setItem('back',JSON.stringify(false))
    setActiveKey(selectedKey);
  };

  useEffect(() => {
    setActiveKey(parts);
  }, [location]);

  const handleBack = () => {
    if(parts === 'region'){
      navigate(`/${PATH.MAP}/${PATH.MAP_DETAIL}`);
      localStorage.setItem('back',JSON.stringify(false))
    }
  };

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
        }}
        className="relative"
      >
        <Sider
          width={261}
          className="border-r border-[#444459]"
          breakpoint="lg"
          collapsed={!open}
          collapsedWidth={41}
        >
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#5B5B9F",
                itemBg: "red",
              },
            }}
          >
            <div className="!bg-[#2B2A3A] h-[40px] flex items-center justify-between px-[4px]">
              {open && <img alt="logo" src={logoSibar} className="h-[70%]" />}
              {open ? (
                <img
                  alt="close"
                  src={closeIcon}
                  onClick={() => {
                    setOpen(false);
                    dispatch(
                      handleChangeExcessWidth({
                        open: false,
                        width: 40,
                      })
                    );
                  }}
                  className="cursor-pointer"
                />
              ) : (
                <img
                  alt="open"
                  src={openIcon}
                  onClick={() => {
                    setOpen(true);
                    dispatch(
                      handleChangeExcessWidth({
                        open: true,
                        width: 260,
                      })
                    );
                  }}
                  className="cursor-pointer"
                />
              )}
            </div>
            <Menu
              theme="dark"
              defaultSelectedKeys={activeKey}
              defaultOpenKeys={activeKey}
              selectedKeys={activeKey}
              mode="inline"
              items={items}
              onClick={(key) => handleSelectMenu(key)}
              style={{
                width: open ? "260px" : "40px",
              }}
            />
            {open && (
              <div className="absolute bottom-0 w-full h-[30px] border-t border-[#706F80] flex items-center px-[5px]">
                <p className="text-[#706F80]">version 1.0</p>
              </div>
            )}
          </ConfigProvider>
        </Sider>
        <div className="w-full overflow-hidden">
          <Header className="flex relative !bg-[#2B2A3A] py-[2px]">
            {Boolean(isBack) && (
              <div className="absolute left-[16px] top-[-12px]">
                <div className="flex items-center" onClick={() => handleBack()}>
                  <img alt="arrow" src={arrowLeft} className="cursor-pointer" />
                  <p
                    className="font-bold ml-[6px] cursor-pointer"
                    style={{ color: colors.redGlobal }}
                  >
                    Quay lại
                  </p>
                </div>
              </div>
            )}
            <ActionRight />
          </Header>
          <Content>
            <div
              style={{
                height: "95vh",
                background: "#0F0F15",
              }}
            >
              <Outlet />
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};
export default MapLayout;

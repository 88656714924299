import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    dataLocation: null,
    dataAddress: [],
    code: "",
    dataProvince: [],
    dataDistrict: [],
    totalCamAll: [],
    dataRecorder: [],
    changeKey: 1,
    isShowDrawerGroup: false,
    listDataCameraGroup: [],
    dataWard:[],
    totalPages:0,
    totalElements:0,
    dataRender:[],
    dataCameraTable:[],
    dataCameraNVR:[]

  },
  reducers: {
    getDataLocationCam: (state, action) => {
      state.dataCameraTable = action.payload.content;
      state.totalPages = action.payload.totalPages;
      state.totalElements = action.payload.totalElements ;
    },
    getDataLocationCamMap: (state, action) => {
      state.dataLocation = action.payload.cameras;
      state.dataCameraNVR = action.payload.nvrCameras;
    },
    getDataCameraSerach: (state, action) => {
      state.dataLocation = action.payload;
    },
    //
    clearDataLocation: (state) => {
      state.dataLocation = [];
    },
    clearDataNVR: (state) => {
      state.dataCameraNVR = [];
    },
    clearDataLocationtable: (state) => {
      state.dataCameraTable = [];
      state.totalElements = 0
    },
    getDataAddress: (state, action) => {
      state.dataAddress = action.payload;
    },
    getDataProvince: (state, action) => {
      state.dataProvince = action.payload;
    },
    getDataDistrict: (state, action) => {
      state.dataDistrict = action.payload;
    },
    getCode: (state, action) => {
      state.code = action.payload;
    },
    getTotalCamAll: (state, action) => {
      state.totalCamAll = action.payload;
    },
    getDataRecorder: (state, action) => {
      state.dataRecorder = action.payload;
    },
    changeKeyHandle: (state, action) => {
      state.changeKey += action.payload;
    },
    getDataCameraGroup: (state, action) => {
      state.isShowDrawerGroup = true;
      state.listDataCameraGroup = action.payload;
    },
    closeGroup:(state,action)=>{
      state.isShowDrawerGroup = false
    },
    getDataWard:(state,action)=>{
      state.dataWard  = action.payload
    },
    getDataRender:(state,action)=>{
      let arr = [];
      action.payload?.forEach((item) => {
        arr.push({
          id: item.id,
          latLng: item.addressDTO,
          status: item.status,
        });
      });
      state.dataRender = arr;
    },
  },
});

export const {
  getDataLocationCam,
  getDataAddress,
  getCode,
  getDataProvince,
  getDataDistrict,
  getTotalCamAll,
  getDataRecorder,
  changeKeyHandle,
  getDataCameraGroup,
  closeGroup,
  getDataWard,
  clearDataLocation,
  getDataRender,
  getDataLocationCamMap,
  getDataCameraSerach,
  clearDataLocationtable,
  clearDataNVR
} = mapSlice.actions;

export const selectDataLocationCam = (state) => state.map.dataLocation;
export const selectdataCameraNVR = (state) => state.map.dataCameraNVR;
export const selectdataCameraTable = (state) => state.map.dataCameraTable;
export const selectDataAddress = (state) => state.map.dataAddress;
export const selectDataProvince = (state) => state.map.dataProvince;
export const selectDataDistrict = (state) => state.map.dataDistrict;
export const selectTotalCamAll = (state) => state.map.totalCamAll;
export const selectDataRecorder = (state) => state.map.dataRecorder;
export const selectCode = (state) => state.map.code;
export const selectChangKey = (state) => state.map.changeKey;
export const selectListCameraGroup = (state) => state.map.listDataCameraGroup;
export const selectIsShowCameraGroup= (state) => state.map.isShowDrawerGroup;
export const selectDataWard= (state) => state.map.dataWard;
export const selectDataRender= (state) => state.map.dataRender;
export const selecttotalElements= (state) => state.map.totalElements;

export default mapSlice.reducer;

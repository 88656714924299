import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import i18n from "../locales/locales";
import Login from "@pages/login/signin";
import PATH from "@constants/path";
// import MapCustom from "@pages/mapdetail/map";
import MapLayout from "src/layout/map-layout";
import DeviceGroup from "@pages/map/device-group";
import LoginLayout from "src/layout/auth";
import NX from "@pages/live/nx";
import CameraGroup from "@pages/map/camera-group";
import ViewCamera from "@pages/map/view-camera";
import AddGroup from "@pages/map/add-group";
import AddCamera from "@pages/map/add-camera";
import EditGroup from "@pages/map/edit-group";
import SettingAi from "@pages/map/setting-ai";
import Setting from "@pages/setting/setting";
import PlayBack from "@pages/playback/playback";
const RegionManagement = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("@pages/map/management_region")), 300);
    })
);
const MapCustom = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import("@pages/mapdetail/map")), 300);
    })
);
const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const Redirect = () => {
    if (location.pathname === "/" || location.pathname === "/login") {
      navigate(PATH.LOGIN);
    }
  };

  React.useEffect(() => {
    Redirect();
  }, []);
  return (
    <>
      <Routes>
        <Route path="" element={<LoginLayout />}>
          <Route path={PATH.LOGIN} element={<Login />}></Route>
        </Route>
        <Route path={PATH.MAP} element={<MapLayout />}>
          <Route
            path={PATH.MAP_DETAIL}
            element={
              <React.Suspense>
                <MapCustom />
              </React.Suspense>
            }
          ></Route>
          <Route path={PATH.DEVICE_GROUP} element={<DeviceGroup />}></Route>
          <Route path={PATH.CAMERA_GROUP} element={<CameraGroup />}></Route>
          <Route path={PATH.VIEW_GROUP} element={<ViewCamera />}></Route>
          <Route path={PATH.ADD_GROUP} element={<AddGroup />}></Route>
          <Route path={PATH.EDIT_GROUP} element={<EditGroup />}></Route>
          <Route path={PATH.ADD_CAMERA} element={<AddCamera />}></Route>
          <Route path={PATH.LIVE} element={<NX />}></Route>
          <Route path={PATH.REGION} element={<DeviceGroup />} />
          <Route path={PATH.SETTING_AI} element={<SettingAi />} />
          <Route path={PATH.SETTING} element={<Setting />} />
          <Route path={PATH.PLAYBACK} element={<PlayBack />} />
          <Route
            path={PATH.REGION_VIEW}
            element={
              <React.Suspense>
                <RegionManagement />
              </React.Suspense>
            }
          />
          <Route
            path={PATH.REGION_EDIT}
            element={
              <React.Suspense>
                <RegionManagement />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default Router;

/* eslint-disable array-callback-return */
import { memo, useEffect, useRef, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "@style/style-grid-layout.css";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import CustomViewVideo from "@common/video/custom-video";
import {
  handleActiveCamera,
  handleChangeLayout,
  selectActiveCamera,
  selectColum,
  selectDrag,
  selectkeyChange,
  selectRow,
} from "@redux/slice/live-slice";
import Selecto from "react-selecto";
import {
  selectListCameraLayout,
  removeItemById,
  handleUpdateCamera,
  selectMinimize,
  selectItemDrag,
} from "@redux/slice/live-slice";
import colors from "@constants/colors";
import GridBackground from "./grid-bg";
import { ScreenHeight } from "@utils/index";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import { selectisLoadingTab } from "@redux/slice/action-slice";
import CustomModal from "@common/modal/modal";
import CustomButtonCommon from "@common/button/custom-button";
const ReactGridLayout = WidthProvider(RGL);
const GridLayout = ({ widthTree }) => {
  const dispatch = useDispatch();
  const selectID = useRef([]);
  const activeID = useSelector(selectActiveCamera);
  const keyChange = useSelector(selectkeyChange);
  let [searchParams] = useSearchParams();
  let tabid = searchParams.get("tabid");
  const isLoading = useSelector(selectisLoadingTab);
  const { screenHeight, screenWidth } = ScreenHeight();
  const [isShowGrid, setIsShowGrid] = useState(false);
  const column = useSelector(selectColum);
  const row = useSelector(selectRow);
  const lengthLayout = Array(column * row).fill(null);
  const layout = useSelector(selectListCameraLayout);
  const isShow = useSelector(selectMinimize);
  const isSwap = useRef(false);
  const itemDrag = useSelector(selectItemDrag);
  const iframeRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [hoverID, setHoverID] = useState(null);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const listDataDragGroup = useRef([]);
  const handleClick = () => {
    setIsShowMenu(false);
  };
  let listData = [];
  const updateItem = (item) => {
    layout.map((camera) => {
      listData.push(camera);
    });
    listData.push(item);
    dispatch(handleUpdateCamera(listData));
  };
  const HandleChange = (newLayout, oldLayout) => {};

  const handleKeyDown = async ({ key }) => {
    if (key === "Delete") {
      await Promise.all(
        selectID.current.map(async (item) => {
          await dispatch(
            removeItemById({ idCamera: Number(item), status: "remove" })
          );
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClick);
    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClick);
    };
  }, []);

  let lastWheelTime = 0;

  window.addEventListener("wheel", () => {
    lastWheelTime = Date.now();
  });

  setTimeout(() => {
    if (Date.now() - lastWheelTime > 500) {
      setIsShowGrid(false);
    }
  }, 500);

  const handleWheel = (event) => {
    setIsShowGrid(true);
    if (event.deltaY < 0) {
      if (row > 1 && column > 1) {
        dispatch(
          handleChangeLayout({
            row: row - 1,
            column: column - 1,
          })
        );
      }
    } else {
        dispatch(
          handleChangeLayout({
            row: row + 1,
            column: column + 1,
          })
        );
    }
  };
  const handleDropItem = async (e) => {
    isSwap.current = false;
    setIsShowGrid(false);
    const updateData = ({ id, url = "", x = e.x, y = e.y, name, h, w }) => {
      return {
        id: id,
        url: url,
        x: x,
        y: y,
        name: name,
        column: column,
        h: h,
        w: w,
      };
    };
    switch (itemDrag?.type) {
      case "single":
        {
          const callBack = (data) => {
            if (data) {
              updateItem(
                updateData({
                  w: 1,
                  h: 1,
                  id: itemDrag?.node.id,
                  url: data,
                  name: itemDrag?.node.title,
                })
              );
            }
          };
          const errorBack = (data) => {
            updateItem(updateData({ id: itemDrag.url }));
          };
          const callbackCamera=(data)=>{}
          dispatch({
            type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
            payload: {body:{
              id: tabid,
              ids: [itemDrag.url],
            },callback: callbackCamera},
          });
          const payload = {
            url: itemDrag.url,
            callBack,
            errorBack,
          };
          dispatch({
            type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
            payload,
          });
        }
        break;
      case "group":
        {
          const dataList = await new Promise((resolve) => {
            const callBack = (data) => {
              resolve(data);
            };
            dispatch({
              type: TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
              payload: {
                body: { communeIds: itemDrag.url, pageSize: 25 },
                callBack,
              },
            });
          });
          if (dataList.length > 0) {
            const callBackCamera = (data)=>{}
            dispatch({
              type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
              payload: {body:{
                id: tabid,
                ids: dataList.map((item) => item.id),
              },callback:callBackCamera},
            });
            const cameras = dataList;
            const tempSliceCameras = [];

            let row = 0;
            let col = 0;
            let max = 1;
            let lengthCamera = cameras.length;
            if (lengthCamera >= 2 && lengthCamera < 5) {
              max = 2;
            } else if (lengthCamera >= 5 && lengthCamera < 10) {
              max = 3;
            } else if (lengthCamera >= 10 && lengthCamera < 17) {
              max = 4;
            } else if (lengthCamera >= 17 && lengthCamera < 26) {
              max = 5;
            } else if (lengthCamera >= 26 && lengthCamera < 37) {
              max = 6;
            } else if (lengthCamera >= 37 && lengthCamera < 50) {
              max = 7;
            } else if (lengthCamera >= 50 && lengthCamera <= 64) {
              max = 8;
            }
            for (const [index, item] of cameras.entries()) {
              // eslint-disable-next-line no-loop-func
              await new Promise((resolve) => {
                const errorBack = () => resolve();

                const callBackGetData = (dataCamera) => {
                  const value = {
                    w: 1,
                    h: 1,
                    id: item.id,
                    url: dataCamera,
                    name: item.name,
                    x: row,
                    y: col,
                    i: item.id,
                  };
                  const existsInSlice = tempSliceCameras.some(
                    (camera) => camera.id === item.id
                  );

                  if (!existsInSlice) {
                    tempSliceCameras.push(value);
                  }
                  row++;
                  if (row >= max) {
                    row = 0;
                    col++;
                  }
                  resolve();
                };

                dispatch({
                  type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
                  payload: {
                    url: item.id,
                    callBack: callBackGetData,
                    errorBack,
                  },
                });
              });
            }
            const length = tempSliceCameras.length;
            let rows = 1;
            let columns = 1;

            if (length >= 2 && length < 5) {
              rows = columns = 2;
            } else if (length >= 5 && length < 10) {
              rows = columns = 3;
            } else if (length >= 10 && length < 17) {
              rows = columns = 4;
            } else if (length >= 17 && length < 26) {
              rows = columns = 5;
            } else if (length >= 26 && length < 37) {
              rows = columns = 6;
            } else if (length >= 37 && length < 50) {
              rows = columns = 7;
            } else if (length >= 50 && length <= 64) {
              rows = columns = 8;
            }
            dispatch(
              handleChangeLayout({
                row: rows,
                column: columns,
              })
            );
            dispatch(handleUpdateCamera(tempSliceCameras.concat(layout)));
          }
        }
        break;
      case "group_list":
        {
          const callBackCamera = (data)=>{}
          dispatch({
            type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
            payload: {body:{id: tabid,ids: itemDrag?.node?.children.map((item) => item.id)},callback:callBackCamera}
          });
          const cameras = itemDrag?.node?.children;
          const tempSliceCameras = [];

          let row = 0;
          let col = 0;
          let max = 1;
          let lengthCamera = cameras.length;
          if (lengthCamera >= 2 && lengthCamera < 5) {
            max = 2;
          } else if (lengthCamera >= 5 && lengthCamera < 10) {
            max = 3;
          } else if (lengthCamera >= 10 && lengthCamera < 17) {
            max = 4;
          } else if (lengthCamera >= 17 && lengthCamera < 26) {
            max = 5;
          } else if (lengthCamera >= 26 && lengthCamera < 37) {
            max = 6;
          } else if (lengthCamera >= 37 && lengthCamera < 50) {
            max = 7;
          } else if (lengthCamera >= 50 && lengthCamera <= 64) {
            max = 8;
          }
          for (const [index, item] of cameras.entries()) {
            // eslint-disable-next-line no-loop-func
            await new Promise((resolve) => {
              const errorBack = () => resolve();

              const callBackGetData = (dataCamera) => {
                const value = {
                  w: 1,
                  h: 1,
                  id: item.id,
                  url: dataCamera,
                  name: item.name,
                  x: row,
                  y: col,
                  i: item.id,
                };
                const existsInSlice = tempSliceCameras.some(
                  (camera) => camera.id === item.id
                );

                if (!existsInSlice) {
                  tempSliceCameras.push(value);
                }
                row++;
                if (row >= max) {
                  row = 0;
                  col++;
                }
                resolve();
              };

              dispatch({
                type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
                payload: {
                  url: item.id,
                  callBack: callBackGetData,
                  errorBack,
                },
              });
            });
          }
          const length = tempSliceCameras.length;
          let rows = 1;
          let columns = 1;

          if (length >= 2 && length < 5) {
            rows = columns = 2;
          } else if (length >= 5 && length < 10) {
            rows = columns = 3;
          } else if (length >= 10 && length < 17) {
            rows = columns = 4;
          } else if (length >= 17 && length < 26) {
            rows = columns = 5;
          } else if (length >= 26 && length < 37) {
            rows = columns = 6;
          } else if (length >= 37 && length < 50) {
            rows = columns = 7;
          } else if (length >= 50 && length <= 64) {
            rows = columns = 8;
          }
          dispatch(
            handleChangeLayout({
              row: rows,
              column: columns,
            })
          );
          dispatch(handleUpdateCamera(tempSliceCameras.concat(layout)));
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className=" h-[94vh] w-full overflow-hidden scroll_default relative ">
      <Selecto
        selectableTargets={[".target"]}
        dragContainer={document.getElementById("container_grid")}
        hitRate={0}
        selectFromInside={false}
        checkInput
        boundContainer
        onSelectStart={(e) => {
          selectID.current = [];
          e.added.forEach((el) => {
            el.classList.add("selected");
          });
          e.removed.forEach((el) => {
            el.classList.remove("selected");
          });
        }}
        onSelectEnd={(e) => {
          e.afterAdded.forEach((el) => {
            el.classList.add("selected");
            const classList = el.className.split(" ");
            const id = classList.find((cls) => /^\d+$/.test(cls));
            if (!selectID.current.includes(id)) {
              selectID.current.push(id);
              dispatch(handleActiveCamera(id));
            }
          });
          e.afterRemoved.forEach((el) => {
            el.classList.remove("selected");
          });
        }}
      />
      {isShowGrid && (
        <GridBackground
          column={column}
          row={row}
          lengthLayout={lengthLayout}
          scale={scale}
          widthTree={300}
        />
      )}
      {isLoading && (
        <div
          className="h-[93vh] w-full absolute top-0 right-0 z-[9999] flex justify-center items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <div className="h-[44px] w-[122px] bg-white rounded flex items-center justify-center space-x-2">
            <Spin className="text-2xl"></Spin>
            <p className="font-normal text-[14px] text-[#181824]">Loading</p>
          </div>
        </div>
      )}

      <div onWheel={handleWheel}>
        <ReactGridLayout
          // allowOverlap={true}
          // onLayoutChange={onLayoutChange}
          // useCSSTransforms={true}
          // measureBeforeMount={false}
          isBounded={true}
          compactType={null}
          preventCollision={true}
          layout={layout.map((item) => ({ ...item }))}
          cols={column}
          rowHeight={(screenHeight - 130) / row}
          items={20}
          isDraggable={useSelector(selectDrag)}
          isDroppable={true}
          isResizable={true}
          containerPadding={[0, 0]}
          style={{
            height: "90vh",
            width: "100%",
            transform: `scale(${scale})`,
            transformOrigin: "center center",
          }}
          margin={[0, 0]}
          onDragStart={(e, p) => {
            setIsShowGrid(true);
            isSwap.current = true;
          }}
          onResizeStart={() => {
            setIsShowGrid(true);
          }}
          onDragStop={(e, p) => {
            HandleChange(e, layout);
            setIsShowGrid(false);
            isSwap.current = false;
            // dispatch(handleSwap({idCameraSelect:Number(selectID.current), idCameraSwap:itemSwap.current,layout:layout}))
          }}
          onResizeStop={(e, p, i) => {
            HandleChange(e, layout);
            setIsShowGrid(false);
          }}
          onDropDragOver={(w, h) => {
            setIsShowGrid(true);
          }}
          onResize={(e) => {}}
          draggableHandle=".headerControl"
          resizeHandles={["nw", "se", "ne", "sw"]}
          onDrop={(e, item) => {
            handleDropItem(item);
          }}
        >
          {layout.map((item, index) => {
            return (
              <div
                key={index}
                data-grid={item}
                className={`target p-2 ${item.id} ${
                  item.id === isShow ? "slide-out-bck-tl" : ""
                }`}
              >
                <div
                  className={`headerControl ${
                    item.isWarning && "absolute top-0 left-0"
                  } h-full w-full`}
                  style={{
                    background: colors.bg_color,
                    border: `1px solid ${
                      activeID === item.id ? "#04AA6D" : ""
                    }`,
                    // transform:`scale(${Number(activeID.current) === item.id ?'1.2':'1'})`,
                  }}
                >
                  <CustomViewVideo
                    item={item}
                    callBackError={(e) => {}}
                    layout={layout}
                  />
                </div>
              </div>
            );
          })}
        </ReactGridLayout>
      </div>
      {/* <CustomModal
        centered={true}
        open={openDelete}
        width={"460px"}
        handleCancel={() => {
          setopenDelete(false);
        }}
        content={
          <div className="h-[175px] w-full bg-[#2B2A3A]">
            <div className="h-[100px] w-full flex items-center justify-center">
              <p className="font-semibold text-[16px]">
                Bạn có chắc chắn muốn xoá?
              </p>
            </div>
            <div className="h-[75px] w-full space-x-[16px] flex items-center justify-center px-[36px]">
              <div className="w-full">
                <CustomButtonCommon
                  width="100%"
                  height="39px"
                  backgroundColor={colors.redGlobal}
                  onClick={() => {
                    const index = tags.findIndex(
                      (item) => item.id === removedTagRef.current.id
                    );
                    if (
                      tags.length > 1 &&
                      selectTab.current === removedTagRef.current.id
                    ) {
                      if (index > 0) {
                        handleCameraStream(tags[index - 1]);
                      } else {
                        handleCameraStream(tags[1]);
                      }
                    }
                    const newTags = tags.filter(
                      (tag) => tag.id !== removedTagRef.current.id
                    );
                    dispatch({
                      type: TYPE_ACTION.LIVE.DELETE_TAB,
                      payload: { ids: removedTagRef.current.id },
                    });
                    setTags(newTags);
                    setopenDelete(false);
                  }}
                  text={"Xoá"}
                />
              </div>
              <div className="w-full">
                <CustomButtonCommon
                  width="100%"
                  height="39px"
                  onClick={() => {
                    setopenDelete(false);
                  }}
                  text={"Hủy"}
                />
              </div>
            </div>
          </div>
        }
        isHeader={false}
      /> */}
    </div>
  );
};
export default memo(GridLayout);

import { Modal } from "antd";
import ConfigProvider from "antd/es/config-provider";
import colors from "@constants/colors";
import { IoClose } from "react-icons/io5";
import close from "@assets/icon/close2.svg";
const CustomModal = ({
  open,
  handleOk,
  handleCancel,
  content,
  title,
  footer = true,
  width = 500,
  centered = false,
  top,
  renderHeader,
  renderAction,
  background = colors.redGlobal,
  isHeader = true,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: colors.background,
          borderRadius: 0,
          controlItemBgHover: colors.background,
          colorText: colors.textBase,
          colorPrimary: colors.redGlobal,
          colorLink: colors.redGlobal,
          colorPrimaryBorder: colors.redGlobal,
          padding: 0,
          paddingContentHorizontal: 0,
          paddingContentHorizontalLG: 0,
          paddingContentHorizontalSM: 0,
          paddingContentVertical: 0,
          paddingContentVerticalLG: 0,
          paddingContentVerticalSM: 0,
          margin: 0,
          marginContentHorizontal: 0,
          marginContentHorizontalLG: 0,
          marginContentHorizontalSM: 0,
          marginContentVertical: 0,
          marginContentVerticalLG: 0,
          paddingLG: 0,
          paddingMD: 0,
          paddingSM: 12,
          paddingXS: 0,
          marginLG: 0,
          marginMD: 0,
          marginSM: 0,
        },
        components: {
          Modal: {
            colorBgMask: "#000000B2",
          },
        },
      }}
    >
      <Modal
        open={open}
        onOk={handleOk}
        style={{ top: top }}
        onCancel={handleCancel}
        closable={false}
        width={width}
        footer={footer}
        centered={centered}
        // maskClosable={false}
      >
        <div>
          {isHeader && (
            <div
              className="flex justify-between items-center h-[40px] px-4 py-6"
              style={{
                borderBottom: `1px solid #656475`,
                background: background,
              }}
            >
              {renderHeader}
              <div className="flex space-x-[10px] items-center justify-center h-full">
                {renderAction}
                <img
                  alt="close"
                  src={close}
                  className="cursor-pointer"
                  onClick={handleCancel}
                />
              </div>
            </div>
          )}

          {content}
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default CustomModal;

/* eslint-disable no-loop-func */
import { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ConfigProvider, Input, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import { selectColum } from "@redux/slice/live-slice";
import { useSearchParams } from "react-router-dom";
import iconViewStream from "@assets/icon/viewStream.svg";
import { handleClearCameraTick } from "@redux/slice/action-slice";
const PopupTab = ({ idCameraTab, listCameraTab, handleCancel }) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const column = useSelector(selectColum);
  const [disabledMove, setDisableMove] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const navigate = useNavigate();
  //
  let [searchParams] = useSearchParams();
  let tabid = localStorage.getItem("tabid");

  const selectTab = useRef(tabid);
  const loadTab = () => {
    const callBack = async (data) => {
      const reversedData = data
        .slice()
        .filter((item) => item.type === "live")
        .reverse();
      setTags(reversedData);
      if (tabid) {
        selectTab.current = tabid;
      }
    };

    dispatch({
      type: TYPE_ACTION.LIVE.GET_TAB,
      payload: { ids: null, callBack: callBack },
    });
  };

  useEffect(() => {
    loadTab();
  }, []);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag.id !== removedTag.id);
    dispatch({
      type: TYPE_ACTION.LIVE.DELETE_TAB,
      payload: { ids: removedTag.id },
    });
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      const callBack = (data) => {
        setTags([...tags, data]);
      };

      dispatch({
        type: TYPE_ACTION.LIVE.CREATE_TAB,
        payload: {
          body: {
            name: inputValue,
            type: "live",
          },
          callBack,
        },
      });
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = (id) => {
    if (editInputValue) {
      setDisableMove(true);
      const newTags = [...tags];
      newTags[editInputIndex].name = editInputValue;
      dispatch({
        type: TYPE_ACTION.LIVE.UPDATE_TAB,
        payload: { id: id, name: editInputValue, type: "live" },
      });
      setTags(newTags);
      setEditInputIndex(-1);
      setEditInputValue("");
    }
  };
  const handleClickTab = (item) => {
    setActiveTab(item.id);
    localStorage.setItem("tabid", item.id);
    setDisableMove(true);
  };
  const handleChangeTab = () => {
    const callback = (data) => {
      dispatch(handleClearCameraTick());
      navigate(`/${PATH.MAP}/${PATH.LIVE}?tabid=${activeTab}`);
    };
    if (listCameraTab.length > 0) {
      dispatch({
        type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
        payload: { body: { id: activeTab, ids: listCameraTab }, callback },
      });
    } else {
      dispatch({
        type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
        payload: {
          body: {
            id: activeTab,
            ids: [idCameraTab],
          },
          callback,
        },
      });
    }
  };

  return (
    <div className="w-full bg-[#181824] h-[444px]">
      <div className="w-full h-[374px] p-[20px]">
        <div className="w-full min-h-[80px]  flex items-center flex-wrap gap-2">
          {tags.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={index}
                  style={{
                    height: "38px",
                    width: "max-content",
                    background: "#2B2A3A",
                    color: "white",
                    border: "none",
                    marginRight: "10px",
                    minWidth: "100px",
                  }}
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={() => handleEditInputConfirm(tag.id)}
                  onPressEnter={() => handleEditInputConfirm(tag.id)}
                />
              );
            }
            const isLongTag = tag.length > 20;
            const tagElem = (
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: "4px",
                    controlHeight: "38px",
                  },
                }}
              >
                <Tag
                  key={index}
                  closable={true}
                  style={{
                    userSelect: "none",
                    background: activeTab === tag.id ? "#5B5B9F" : "#2B2A3A",
                    color: "white",
                    borderRadius: "4px",
                    height: "38px",
                    border: "none",
                  }}
                  className="flex items-center cursor-pointer"
                  onClose={() => handleClose(tag)}
                  onClick={() => handleClickTab(tag)}
                >
                  <span
                    onDoubleClick={(e) => {
                      setEditInputIndex(index);
                      setEditInputValue(tag.name);
                      e.preventDefault();
                    }}
                  >
                    {tag.name}
                  </span>
                </Tag>
              </ConfigProvider>
            );
            return isLongTag ? (
              <Tooltip title={tag} key={tag}>
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible ? (
            <Input
              ref={inputRef}
              type="text"
              style={{
                height: "38px",
                width: "max-content",
                background: "#2B2A3A",
                color: "white",
                border: "none",
                minWidth: "100px",
              }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              className="w-max"
            />
          ) : (
            <Tag
              icon={<PlusOutlined />}
              onClick={showInput}
              style={{
                userSelect: "none",
                background: "#2B2A3A",
                color: "white",
                borderRadius: "4px",
                height: "38px",
                border: "none",
              }}
              className="flex items-center"
            >
              Tab mới
            </Tag>
          )}
        </div>
      </div>
      <div className="w-full h-[70px] flex items-center space-x-[24px] justify-end px-[24px] border-t border-[#656475]">
        <div
          className="h-[40px] w-[113px] bg-[#444459] flex items-center justify-center cursor-pointer rounded"
          onClick={() => handleCancel()}
        >
          Huỷ
        </div>
        <div
          className="h-[40px] w-[113px] flex items-center justify-center cursor-pointer rounded space-x-2"
          onClick={() => {
            if (disabledMove) {
              handleChangeTab();
            }
          }}
          style={{
            background: disabledMove ? "#21A603" : "#444459",
          }}
        >
          <img
            alt="iconViewStream"
            src={iconViewStream}
            className="h-[20px] cursor-pointer"
          />
          <p>Di chuyển</p>
        </div>
      </div>
    </div>
  );
};
export default PopupTab;

import { useState, useRef, useEffect } from "react";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import { useDispatch, useSelector } from "react-redux";
import colors from "@constants/colors";
import CustomButtonCommon from "@common/button/custom-button";
import CustomInput from "@common/input/custom-input";
import { ConfigProvider, Form, Popover, Progress, Tabs } from "antd";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import {
  doIsTogle,
  handleCancelProgress,
  handleDoneProcess,
  handleGetIdScan,
  handleProgress,
  selectcurrentProcess,
  selectisDoneProcess,
  selectlistProcessScan,
  selectmaxProcess,
  selectpercentProgress,
} from "@redux/slice/action-slice";
import iconAdd from "@assets/map/add.svg";
import iconScan from "@assets/map/scan.svg";
import TYPE_ACTION from "@constants/action";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import iconUpfile from "@assets/map/upfile.svg";
import iconUpfile1 from "@assets/map/upfile1.svg";
import iconDowfile from "@assets/map/dowfile.svg";
import "./popupstyle.css";
import iconDelete from "@assets/map/delete.svg";
import { clearDataLocation, clearDataNVR } from "@redux/slice/map-slice";
// const SingleIP = () => {
//   const [form] = Form.useForm();
//   const formRef = useRef(null);
//   const dispatch = useDispatch();
//   const scrollRef = useRef(null);
//   const navigate = useNavigate();
//   const currentProcess = useSelector(selectcurrentProcess);
//   const maxProcess = useSelector(selectmaxProcess);
//   const isScan = useSelector(selectisDoneProcess);
//   const listProcessScan = useSelector(selectlistProcessScan);
//   const [open, setOpen] = useState(false);
//   const listIPRef = useRef([
//     {
//       index: 0,
//       fromIP: "",
//       toIP: "",
//       Port: "",
//       action: true,
//     },
//   ]);
//   const listUserName = useRef([
//     {
//       index: 0,
//       username: "",
//       password: "",
//       action: true,
//     },
//   ]);

//   const [renderTrigger, setRenderTrigger] = useState(false);

//   const handleAddListIP = (index) => {
//     listIPRef.current = [
//       ...listIPRef.current.map((item) => ({ ...item, action: false })),
//       {
//         index: index + 1,
//         fromIP: "",
//         toIP: "",
//         Port: "",
//         action: true,
//       },
//     ];
//     setRenderTrigger((prev) => !prev);
//   };

//   const handleRemove = (index) => {
//     listIPRef.current.splice(index, 1);
//     if (listIPRef.current.length > 0) {
//       listIPRef.current[listIPRef.current.length - 1].action = true;
//     }

//     setRenderTrigger((prev) => !prev);
//   };

//   const handleAddListIF = (index) => {
//     listUserName.current = [
//       ...listUserName.current.map((item) => ({ ...item, action: false })),
//       {
//         index: index + 1,
//         username: "",
//         password: "",
//         action: true,
//       },
//     ];
//     setRenderTrigger((prev) => !prev);
//   };

//   const handleRemoveIF = (index) => {
//     listUserName.current.splice(index, 1);
//     if (listUserName.current.length > 0) {
//       listUserName.current[listUserName.current.length - 1].action = true;
//     }

//     setRenderTrigger((prev) => !prev);
//   };
//   const handleScan = () => {
//     formRef.current
//       .validateFields()
//       .then(() => {
//         if (scrollRef.current) {
//           scrollRef.current.scrollTo({
//             top: 0,
//             behavior: "smooth",
//           });
//         }
//         // dispatch(handleDoneProcess(true));
//         const values = form.getFieldsValue();
//         const listData = values.listIP.map((ip) => ({
//           fromIp: ip.fromIp,
//           toIp: null,
//           username: values.listUser.map((item) => item.username),
//           password: values.listUser.map((item) => item.password),
//         }));
//         async function processListData() {
//           for (const item of listData) {
//             await new Promise((resolve) => {
//               const callback = (data) => {
//                 dispatch(handleGetIdScan(data.id));
//                 dispatch(
//                   handleProgress({
//                     id: data.id,
//                     isDone: false,
//                     fromIp: data.fromIp,
//                     toIp: null,
//                   })
//                 );
//                 form.resetFields();
//                 resolve();
//               };
//               dispatch({
//                 type: TYPE_ACTION.MAP.SCAN_CAMERA_SINGLE_IP,
//                 payload: { body: item, callback },
//               });
//             });
//           }
//         }
//         processListData();
//       })
//       .catch((errorInfo) => {
//         console.log("Validation failed:", errorInfo);
//       });
//   };
//   const handleOpenChange = (newOpen) => {
//     setOpen(newOpen);
//   };
//   return (
//     <div className="overflow-hidden">
//       <div
//         className="h-[57vh] overflow-auto scroll_default overflow-x-hidden"
//         ref={scrollRef}
//       >
//         <div className="w-full">
//           <Popover
//             placement="bottom"
//             className="custom-popover"
//             content={
//               <div className="w-full p-[8px] rounded bg-[#444459] space-y-[8px]">
//                 <div className="w-full h-[40px] rounded bg-[#01D68F] flex items-center justify-center space-x-[8px] cursor-pointer">
//                   <img alt="upfile" src={iconDowfile} />
//                   <p className="text-white font-medium">Tải xuống file mẫu</p>
//                 </div>
//                 <div className="w-full h-[40px] rounded bg-[#DCC51E] flex items-center justify-center space-x-[8px] cursor-pointer">
//                   <img alt="upfile" src={iconUpfile1} />
//                   <p className="text-white font-medium">Tải lên file</p>
//                 </div>
//               </div>
//             }
//             trigger="click"
//             open={open}
//             onOpenChange={handleOpenChange}
//           >
//             <div className="w-full h-[40px] rounded border border-[#01D68F] flex items-center justify-center space-x-[8px] cursor-pointer">
//               <img alt="upfile" src={iconUpfile} />
//               <p className="text-[#01D68F] font-medium">Tải lên file</p>
//             </div>
//           </Popover>
//           {listProcessScan.length > 0 &&
//             listProcessScan.map((item, index) => {
//               return (
//                 <>
//                   {!item.isDone && item.fromIp && !item.toIp && (
//                     <div
//                       className="w-full space-y-2 mt-[10px] pr-[10px] border-b p-[10px]  border-[#444459]"
//                       key={index}
//                     >
//                       <div>
//                         <p className="text-[#01D68F]">
//                           {item.fromIp}
//                         </p>
//                       </div>
//                       <div className="w-full flex items-center justify-between">
//                         <p className="text-white">Đang quét...</p>
//                         <p className="text-white">
//                           {item.current}/{item.total} camera
//                         </p>
//                       </div>
//                       <Progress
//                         strokeLinecap="butt"
//                         percent={Math.floor((item.current / item.total) * 100)}
//                         strokeColor="#01D68F"
//                         strokeWidth={20}
//                         // className="bg-[#444459]"
//                       />
//                     </div>
//                   )}
//                 </>
//               );
//             })}
//         </div>
//         <Form
//           layout="vertical"
//           name="basic"
//           form={form}
//           autoComplete="off"
//           className="w-full mt-[30px] space-y-[24px]"
//           ref={formRef}
//         >
//           {listIPRef.current.map((item, index) => {
//             return (
//               <div key={index}>
//                 <CustomInput
//                   lineHeight="0px"
//                   width="100%"
//                   backgroundColor={colors.background_tag}
//                   font="font-normal"
//                   placeholder="Nhập địa chỉ IP"
//                   label="Địa chỉ IP"
//                   name={["listIP", index, "fromIp"]}
//                   rules={[
//                     {
//                       required: true,
//                       message: "Không được bỏ trống!",
//                     },
//                   ]}
//                 />
//                 {item.action ? (
//                   <div
//                     className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer mb-2 select-none"
//                     onClick={() => handleAddListIP(index)}
//                   >
//                     <img alt="add" src={iconAdd} />
//                     <p>Thêm</p>
//                   </div>
//                 ) : (
//                   <div
//                     className="w-full h-[39px] rounded border border-[#E21A3D] flex items-center justify-center space-x-[10px] cursor-pointer select-none"
//                     onClick={() => handleRemove(index)}
//                   >
//                     <img alt="iconDelete" src={iconDelete} />
//                     <p className="text-[#E21A3D]">Xoá</p>
//                   </div>
//                 )}
//               </div>
//             );
//           })}

//           {listUserName.current.map((item, index) => {
//             return (
//               <div key={index}>
//                 <CustomInput
//                   lineHeight="0px"
//                   width="100%"
//                   backgroundColor={colors.background_tag}
//                   font="font-normal"
//                   placeholder="Nhập tài khoản"
//                   label="Tài khoản"
//                   name={["listUser", index, "username"]}
//                 />
//                 <CustomInput
//                   lineHeight="0px"
//                   width="100%"
//                   backgroundColor={colors.background_tag}
//                   font="font-normal"
//                   placeholder="Nhập mật khẩu"
//                   label="Mật khẩu"
//                   name={["listUser", index, "password"]}
//                   type="password"
//                 />
//                 <CustomInput
//                   lineHeight="0px"
//                   width="100%"
//                   backgroundColor={colors.background_tag}
//                   font="font-normal"
//                   placeholder="Nhập Endpoint"
//                   label="Endpoint"
//                   name={["listUser", index, "endpoint"]}
//                 />
//                 {item.action ? (
//                   <div
//                     className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer mb-2 select-none"
//                     onClick={() => handleAddListIF(index)}
//                   >
//                     <img alt="add" src={iconAdd} />
//                     <p>Thêm</p>
//                   </div>
//                 ) : (
//                   <div
//                     className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer select-none"
//                     onClick={() => handleRemoveIF(index)}
//                   >
//                     <img alt="add" src={iconAdd} />
//                     <p>Xoá</p>
//                   </div>
//                 )}
//               </div>
//             );
//           })}
//         </Form>
//       </div>

//       <div className="mt-[40px] space-y-[16px]">
//         <div
//           className="w-full h-[39px] bg-[#2EB512] rounded cursor-pointer flex items-center justify-center space-x-[10px] select-none"
//           style={{
//             backgroundColor: !isScan ? "#21A603" : "#444459",
//             cursor: !isScan ? "pointer" : "not-allowed",
//           }}
//           onClick={() => {
//             handleScan();
//           }}
//         >
//           <img alt="scan" src={iconScan} />
//           <p className="text-white">Quét</p>
//         </div>
//         <div>
//           <CustomButtonCommon
//             width="100%"
//             height="39px"
//             onClick={() => {
//               localStorage.setItem("toggle", 0);
//               dispatch(doIsTogle(0));
//             }}
//             text={"Hủy"}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const RangeIP = () => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const currentProcess = useSelector(selectcurrentProcess);
  const maxProcess = useSelector(selectmaxProcess);
  const isScan = useSelector(selectisDoneProcess);
  const listProcessScan = useSelector(selectlistProcessScan);
  const [keyResetForm, setKeyResetForm] = useState(0);
  const [open, setOpen] = useState(false);
  const [paused, setPaused] = useState(true);
  const listIPRef = useRef([
    {
      index: 0,
      fromIP: "",
      toIP: "",
      Port: "",
      action: true,
    },
  ]);
  const listUserName = useRef([
    {
      index: 0,
      username: "",
      password: "",
      action: true,
    },
  ]);

  const [renderTrigger, setRenderTrigger] = useState(false);

  const handleAddListIP = (index) => {
    listIPRef.current = [
      ...listIPRef.current.map((item) => ({ ...item, action: false })),
      {
        index: index + 1,
        fromIP: "",
        toIP: "",
        Port: "",
        action: true,
      },
    ];
    setRenderTrigger((prev) => !prev);
  };

  const handleRemove = (index) => {
    listIPRef.current.splice(index, 1);
    if (listIPRef.current.length > 0) {
      listIPRef.current[listIPRef.current.length - 1].action = true;
    }

    setRenderTrigger((prev) => !prev);
  };

  const handleAddListIF = (index) => {
    listUserName.current = [
      ...listUserName.current.map((item) => ({ ...item, action: false })),
      {
        index: index + 1,
        username: "",
        password: "",
        action: true,
      },
    ];
    setRenderTrigger((prev) => !prev);
  };

  const handleRemoveIF = (index) => {
    listUserName.current.splice(index, 1);
    if (listUserName.current.length > 0) {
      listUserName.current[listUserName.current.length - 1].action = true;
    }

    setRenderTrigger((prev) => !prev);
  };
  const handleScan = () => {
    dispatch(clearDataLocation())
    dispatch(clearDataNVR())
    formRef.current
      .validateFields()
      .then(() => {
        listIPRef.current = [
          {
            index: 0,
            fromIP: "",
            toIP: "",
            Port: "",
            action: true,
          },
        ];
        listUserName.current = [
          {
            index: 0,
            username: "",
            password: "",
            action: true,
          },
        ];

        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        const values = form.getFieldsValue();
        const listData = values.listIP.map((ip) => ({
          fromIp: ip.fromIp,
          toIp: ip.toIp,
          username: values.listUser.map((item) => item.username),
          password: values.listUser.map((item) => item.password),
        }));
        async function processListData() {
          for (const item of listData) {
            await new Promise((resolve) => {
              const callback = (data) => {
                dispatch(handleGetIdScan(data.id));
                dispatch(
                  handleProgress({
                    id: data.id,
                    isDone: false,
                    fromIp: data.fromIp,
                    toIp: data.toIp,
                    current:0,
                    total:data.max
                  })
                );
                resolve();
                form.resetFields();
              };
              dispatch({
                type: TYPE_ACTION.MAP.SCAN_CAMERA_SINGLE_IP,
                payload: { body: item, callback },
              });
            });
          }
        }
        processListData();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const handlePause = (item,isPause) => {
    if (isPause) {
      dispatch({
        type:TYPE_ACTION.MAP.SET_STATE_SCANNING,
        payload:{body:{
          id:item.id,
          state:"PAUSED"
        }}
      })
    } else {
      dispatch({
        type:TYPE_ACTION.MAP.SET_STATE_SCANNING,
        payload:{body:{
          id:item.id,
          state:"SCANNING"
        }}
      })
    }
    setPaused(!paused);
  };
  const handleCancel = (item) => {
    dispatch(handleCancelProgress(item.id))
    dispatch({
      type:TYPE_ACTION.MAP.SET_STATE_SCANNING,
      payload:{body:{
        id:item.id,
        state:"STOPPED"
      }}
    })
  };
  return (
    <div className="overflow-hidden">
      <div
        className="h-[57vh] overflow-auto scroll_default overflow-x-hidden"
        ref={scrollRef}
      >
        {/* <div className="w-full">
          <Popover
            placement="bottom"
            className="custom-popover"
            content={
              <div className="w-full p-[8px] rounded bg-[#444459] space-y-[8px]">
                <div className="w-full h-[40px] rounded bg-[#01D68F] flex items-center justify-center space-x-[8px] cursor-pointer">
                  <img alt="upfile" src={iconDowfile} />
                  <p className="text-white font-medium">Tải xuống file mẫu</p>
                </div>
                <div className="w-full h-[40px] rounded bg-[#DCC51E] flex items-center justify-center space-x-[8px] cursor-pointer">
                  <img alt="upfile" src={iconUpfile1} />
                  <p className="text-white font-medium">Tải lên file</p>
                </div>
              </div>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <div className="w-full h-[40px] rounded border border-[#01D68F] flex items-center justify-center space-x-[8px] cursor-pointer">
              <img alt="upfile" src={iconUpfile} />
              <p className="text-[#01D68F] font-medium">Tải lên file</p>
            </div>
          </Popover>
        </div> */}
        {listProcessScan.length > 0 &&
          listProcessScan.map((item, index) => {
            return (
              <>
                {!item.isDone && (
                  <div
                    key={index}
                    className="w-full space-y-2 mt-[10px] pr-[10px] border-b py-[10px]  border-[#444459]"
                  >
                    <div className="w-full flex items-center justify-between">
                      <p className="text-white">
                        Đang quét...{item.current}/{item.total} camera
                      </p>
                      <div className="flex items-center space-x-2">
                        <div
                          onClick={() => handlePause(item,paused)}
                          className="select-none cursor-pointer h-[26px] w-max px-[10px] rounded-[19px] bg-[#706F80] flex items-center justify-center text-white font-semibold"
                        >
                          {paused ? "Dừng" : "Tiếp tục"}
                        </div>
                        <div
                          onClick={() => handleCancel(item)}
                          className="select-none cursor-pointer h-[26px] w-[47px] rounded-[19px] bg-[#E21A3D] flex items-center justify-center text-white font-semibold"
                        >
                          Hủy
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="text-[#01D68F]">
                        {item.fromIp} {`${item.toIp?`- ${item.toIp}`:''}`}
                      </p>
                    </div>
                    <Progress
                      strokeLinecap="butt"
                      percent={Math.floor((item.current / item.total) * 100)}
                      strokeColor="#01D68F"
                      strokeWidth={20}
                      // className="bg-[#444459]"
                    />
                  </div>
                )}
              </>
            );
          })}
        <Form
          layout="vertical"
          name="basic"
          form={form}
          autoComplete="off"
          className="w-full mt-[30px] space-y-[24px]"
          ref={formRef}
        >
          {listIPRef.current.map((item, index) => {
            return (
              <div key={index}>
                <CustomInput
                  lineHeight="0px"
                  width="100%"
                  backgroundColor={colors.background_tag}
                  font="font-normal"
                  placeholder="Nhập địa chỉ IP"
                  label="Từ IP"
                  name={["listIP", index, "fromIp"]}
                  rules={[
                    {
                      required: true,
                      message: "Không được bỏ trống!",
                    },
                  ]}
                />
                <CustomInput
                  lineHeight="0px"
                  width="100%"
                  backgroundColor={colors.background_tag}
                  font="font-normal"
                  placeholder="Nhập địa chỉ IP"
                  label="Đến IP"
                  name={["listIP", index, "toIp"]}
                />

                {item.action ? (
                  <div
                    className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer mb-2 select-none"
                    onClick={() => handleAddListIP(index)}
                  >
                    <img alt="add" src={iconAdd} />
                    <p className="text-white">Thêm</p>
                  </div>
                ) : (
                  <div
                    className="w-full h-[39px] rounded border border-[#E21A3D] flex items-center justify-center space-x-[10px] cursor-pointer select-none"
                    onClick={() => handleRemove(index)}
                  >
                    <img alt="iconDelete" src={iconDelete} />
                    <p className="text-[#E21A3D]">Xoá</p>
                  </div>
                )}
              </div>
            );
          })}

          {listUserName.current.map((item, index) => {
            return (
              <div key={index}>
                {/* <CustomInput
                  lineHeight="0px"
                  width="100%"
                  backgroundColor={colors.background_tag}
                  font="font-normal"
                  placeholder="Nhập tài khoản"
                  label="Tài khoản"
                  name={["listUser", index, "username"]}
                />
                <CustomInput
                  lineHeight="0px"
                  width="100%"
                  backgroundColor={colors.background_tag}
                  font="font-normal"
                  placeholder="Nhập mật khẩu"
                  label="Mật khẩu"
                  name={["listUser", index, "password"]}
                  type="password"
                /> */}
                <CustomInput
                  lineHeight="0px"
                  width="100%"
                  backgroundColor={colors.background_tag}
                  font="font-normal"
                  placeholder="Nhập Endpoint"
                  label="Endpoint"
                  name={["listUser", index, "endpoint"]}
                />
                {item.action ? (
                  <div
                    className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer mb-2 select-none"
                    onClick={() => handleAddListIF(index)}
                  >
                    <img alt="add" src={iconAdd} />
                    <p className="text-white">Thêm</p>
                  </div>
                ) : (
                  <div
                    className="w-full h-[39px] rounded border border-[#5B5B9F] flex items-center justify-center space-x-[10px] cursor-pointer select-none"
                    onClick={() => handleRemoveIF(index)}
                  >
                    <img alt="add" src={iconAdd} />
                    <p>Xoá</p>
                  </div>
                )}
              </div>
            );
          })}
        </Form>
      </div>

      <div className="mt-[40px] space-y-[16px]">
        <div
          className="w-full h-[39px] bg-[#2EB512] rounded cursor-pointer flex items-center justify-center space-x-[10px] select-none"
          style={{
            backgroundColor: !isScan ? "#21A603" : "#444459",
            cursor: !isScan ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            handleScan();
          }}
        >
          <img alt="scan" src={iconScan} />
          <p className="text-white">Quét</p>
        </div>
        <div>
          <CustomButtonCommon
            width="100%"
            height="39px"
            onClick={() => {
              localStorage.setItem("toggle", 0);
              dispatch(doIsTogle(0));
            }}
            text={"Hủy"}
          />
        </div>
      </div>
    </div>
  );
};

const ScanDrawer = () => {
  const dispatch = useDispatch();
  // const items = [
  //   {
  //     key: "1",
  //     label: <p className="font-semibold text-[14px]">ĐỊA CHỈ ĐÃ BIẾT</p>,
  //     children: <SingleIP />,
  //   },
  //   {
  //     key: "2",
  //     label: <p className="font-semibold text-[14px]">QUÉT THEO DẢI</p>,
  //     children: <RangeIP />,
  //   },
  // ];
  const onChange = (key) => {
    console.log(key);
  };
  return (
    <div className="h-full w-[370px] px-[10px] ml-[10px]">
      <div className="w-full h-[40px] flex items-center justify-between">
        <p className="text-white font-bold text-[14px]">QUÉT CAMERA</p>
        <CloseOutlined
          className="text-white cursor-pointer"
          onClick={() => {
            localStorage.setItem("toggle", 0);
            dispatch(doIsTogle(0));
          }}
        />
      </div>
      <div className="mt-[30px]">
        <RangeIP />
      </div>
      {/* <ConfigProvider
        theme={{
          token: {
            colorBgBase: "transparent",
            colorBgContainer: "transparent",
            colorPrimary: colors.redGlobal,
            colorText: "#706F80",
            colorTextBase: "#706F80",
          },
          components: {
            Tabs: {
              itemSelectedColor: "#FFFFFF",
            },
          },
        }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} centered />
      </ConfigProvider> */}
    </div>
  );
};
export default ScanDrawer;

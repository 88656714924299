// Saga effects
import { all } from "redux-saga/effects";
// Auth
import { watchGetAuthInfoSaga } from "../saga/auth-saga";
import {
  watchGetListDevice,
  watchGetCameraWithId,
  watchUpdateCamera,
  watchUpdateAddressCamera,
  watchDeleteCamera,
} from "./device-saga";
import { watchDoIdToggle } from "./action-saga";
import {
  watchDoGetListProvince,
  watchDoSelectSizeGrid,
  watchDoSelectOpenList,
  watchDoGetListCameraPreview,
  watchDoGetDataCameraWithProvince,
  watchDoGetDataCameraWithID,
  watchDoGetDistrictLive,
  watchDoGetWardLive,
  watchDoGetDevice,
  watchDoGetAllCameraStream,
  watchDoStopStream,
  watchDoStopAllStream,
  watchCreateGroup,
  watchSearchCameraMap,
  watchGetGroup,
  watchCreateCamera,
  watchGetCameraNonAddress,
  watchDeleteCameraNonAddress,
  watchGetCameraWithIDTree,
  watchCreateTabLayout,
  watchGetTabLayout,
  watchDelete,
  watchUpdateTab,
  watchGetListGroup,
  watchAddCameraInTab,
  watchRemoveCameraInTab,
  watchDoGetRecord,
  watchDoGetVideoRRecord,
} from "./live-saga";
import {
  watchGetLocationCamera,
  watchGetDataAddress,
  watchGetCode,
  watchGetDataProvince,
  watchGetDataDistrict,
  watchGetTotalCam,
  watchGetDataRecorder,
  watchDrawCircle,
  watchDrawPolygon,
  watchGetPreviewCamera,
  watchStopStream,
  watchChangeKey,
  watchGetCameraDataGroup,
  watchCloseGroup,
  watchGetDataWard,
  watchScanCameraSingleIP,
  watchGetSearchCamera,
  watchGetLocationCameraCircle,
  watchGetLocationCameraRectangle,
  watchGetTotalCamera,
  watchGetProcessing,
  watchGetLocationCameraScanning,
  watchGetSearchCameraAllMap,
  watchSetStateScan,
} from "./map-saga";
import {
  watchGetListGroupSaga,
  watchGetAllCameraSaga,
  watchCreateGroupCamera,
  watchDeleteGroupCamera,
  watchUpdateGroupCamera,
  watchDeleteCameraOnGroup,
  watchAddCameraOnGroup,
} from "./edit-group-saga";
import {
  watchCreateAiFlow,
  watchCreatePolygon,
  watchDeleteAiFlow,
  watchDeletePolygon,
  watchGetAllPolygon,
  watchGetListAiFlow,
  watchAddPolygonAi,
  watchAddAiToCamera,
  watchGetByCameraAndType,
  watchUpdatePolygon,
  watchApplyAiFlow,
} from "./ai-saga";
import {
  watchGetAllJob,
  watchCreateJob,
  watchDeleteJob,
  watchUpdateJob,
} from "./setting-job-saga";

export default function* rootSaga() {
  yield all([
    watchGetAuthInfoSaga(),
    watchGetListDevice(),
    watchDoIdToggle(),
    watchDoGetListProvince(),
    watchDoSelectSizeGrid(),
    watchDoSelectOpenList(),
    watchDoGetListCameraPreview(),
    watchDoGetDataCameraWithProvince(),
    watchDoGetDataCameraWithID(),
    watchDoGetDistrictLive(),
    watchDoGetWardLive(),
    watchDoGetDevice(),
    watchDoGetAllCameraStream(),
    watchDoStopStream(),
    watchGetLocationCamera(),
    watchGetDataAddress(),
    watchGetCode(),
    watchGetDataProvince(),
    watchGetDataDistrict(),
    watchGetTotalCam(),
    watchGetDataRecorder(),
    watchDrawCircle(),
    watchDrawPolygon(),
    watchGetPreviewCamera(),
    watchStopStream(),
    watchChangeKey(),
    watchGetCameraDataGroup(),
    watchCloseGroup(),
    watchGetDataWard(),
    watchDoStopAllStream(),
    watchCreateGroup(),
    watchSearchCameraMap(),
    watchGetGroup(),
    watchCreateCamera(),
    watchGetCameraNonAddress(),
    watchDeleteCameraNonAddress(),
    watchGetListGroupSaga(),
    watchGetAllCameraSaga(),
    watchCreateGroupCamera(),
    watchDeleteGroupCamera(),
    watchGetCameraWithIDTree(),
    watchScanCameraSingleIP(),
    watchUpdateGroupCamera(),
    watchDeleteCameraOnGroup(),
    watchAddCameraOnGroup(),
    watchGetSearchCamera(),
    watchGetLocationCameraCircle(),
    watchGetLocationCameraRectangle(),
    watchCreateTabLayout(),
    watchGetTabLayout(),
    watchDelete(),
    watchUpdateTab(),
    watchGetCameraWithId(),
    watchGetListAiFlow(),
    watchGetAllPolygon(),
    watchCreateAiFlow(),
    watchCreatePolygon(),
    watchDeleteAiFlow(),
    watchDeletePolygon(),
    watchAddPolygonAi(),
    watchAddAiToCamera(),
    watchGetByCameraAndType(),
    watchUpdatePolygon(),
    watchGetTotalCamera(),
    watchGetListGroup(),
    watchAddCameraInTab(),
    watchRemoveCameraInTab(),
    watchUpdateCamera(),
    watchUpdateAddressCamera(),
    watchDeleteCamera(),
    watchApplyAiFlow(),
    watchGetProcessing(),
    watchGetLocationCameraScanning(),
    watchGetAllJob(),
    watchCreateJob(),
    watchDeleteJob(),
    watchUpdateJob(),
    watchGetSearchCameraAllMap(),
    watchSetStateScan(),
    watchDoGetRecord(),
    watchDoGetVideoRRecord()
  ]);
}

import icon1 from "@assets/playback/icon1.svg";
import icon2 from "@assets/playback/icon2.svg";
import icon3 from "@assets/playback/icon3.svg";
import icon4 from "@assets/playback/icon4.svg";
import icon5 from "@assets/playback/icon5.svg";
import icon6 from "@assets/playback/icon6.svg";
import icon7 from "@assets/playback/icon7.svg";
const ControlPlayer = () => {
  return (
    <div className="bg-[#303750] w-full h-[103px] flex mt-[35px]">
      <div className="w-[90%]">
        <div className="flex items-center space-x-3 px-[16px] py-[10px]">
          <div className="h-[1px] w-full border border-[#5C687F]"></div>
          <div className="min-h-[20px] min-w-[20px] rounded-full bg-[#5C687F] "></div>
          <div className="h-[1px] w-full border border-[#5C687F]"></div>
        </div>
        <div className="flex items-center justify-between px-[16px]">
          <img alt="icon1" src={icon1} />
          <img alt="icon2" src={icon2} />
          <img alt="icon3" src={icon3} />
          <img alt="icon4" src={icon4} />
          <img alt="icon5" src={icon5} />
        </div>
        <p className="text-white px-[16px] mt-[10px]">2:30:30 PM</p>
      </div>
      <div className="flex flex-col justify-center items-center space-y-4">
        <img alt="icon6" src={icon6} />
        <img alt="icon7" src={icon7} />
      </div>
    </div>
  );
};
export default ControlPlayer;

import React, { useState } from "react";
import colors, { sizes } from "@constants/colors";
import { ConfigProvider, Form, Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const CustomInput = ({
  placeholder,
  type = "text",
  name,
  width = "100%",
  height = "40px",
  backgroundColor = colors.background,
  defaultValue,
  onInputChange = () => {},
  disabled = false,
  label,
  rules
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const inputType =
    type === "password" && !isPasswordVisible ? "password" : "text";

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="relative">
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          style={{
            width: "100%",
            height: height,
            background: "transparent",
            color: "white",
          }}
          className={`rounded border-[#444459] hover:border-[#444459] text-white custom-input ${
            disabled ? "input_disable" : ""
          }`}
          placeholder={placeholder}
          type={inputType}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onInputChange}
        />
      </Form.Item>
      {type === "password" && (
        <Button
          icon={isPasswordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={togglePasswordVisibility}
          style={{
            position: "absolute",
            right: 10,
            top: "74%",
            transform: "translateY(-50%)",
            background: "transparent",
            border: "none",
            color: "white",
            padding: 0,
          }}
        />
      )}
    </div>
  );
};

export default CustomInput;

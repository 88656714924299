import { Select, ConfigProvider, Tag } from "antd";
import colors, { sizes } from "@constants/colors";
import { useState } from "react";
const CustomSelect = ({
  title,
  mode,
  placeholder,
  option,
  onSelect,
  onClear,
  value,
  onSearch,
  isfilterOption = true,
  defaultValue,
  showSearch = false,
  disabled = false,
  name,
  onDeselect,
  backgroundColor = colors.background,
  disabledClass,
  allowClear=true
}) => {
  const tagRender = (props) => {
    const { label } = props;
    return (
      <Tag
        color={colors.redGlobal}
        style={{
          marginRight: 3,
          height: "30px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {label}
      </Tag>
    );
  };
  const onChange = (list) => {

  };
  const filterOption = (input, option) => {
    const normalizeText = (text) =>
      text?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  
    return normalizeText(option?.label ?? "").includes(normalizeText(input));
  };
  return (
   
    <ConfigProvider
        theme={{
          token: {
            colorPrimaryBg: colors.redGlobal,
            colorBgContainer: 'transparent',
            colorBgElevated: colors.background_form,
            controlHeight: sizes.heightInput,
            colorTextPlaceholder: colors.textPlaceholder,
            colorBorder: '#444459',
            controlItemBgActive: colors.redGlobal,
            colorText: "white",
            controlOutlineWidth: 0,
            colorPrimary: '#444459',
            colorFillSecondary: colors.backgroundSide,
            colorTextBase: "white",
            borderRadius: 4,
            colorIcon: colors.redGlobal,
            colorTextQuaternary: "white",
            paddingSM: 12,
            colorBgContainerDisabled:disabledClass?"transparent":disabled?"#2B2A3A":"transparent",
          },
          components: {
            // multipleItemBg: colors.background_form,
            // optionActiveBg: colors.background_form,
            // colorBgContainerDisabled: "red",
            // colorFillTertiary: colors.background_form,
          },
        }}
      >
         <Select
            mode={mode}
            allowClear={allowClear}
            showSearch={showSearch}
            onClear={onClear}
            placeholder={placeholder}
            maxTagCount={"responsive"}
            style={{
              width: "100%",
            }}
            className={`${disabledClass ? "select_disable" : ""}`}
            value={value}
            tagRender={tagRender}
            onChange={onChange}
            onSearch={onSearch}
            removeIcon={null}
            onSelect={onSelect}
            filterOption={isfilterOption ? filterOption : false}
            options={option}
            defaultValue={defaultValue}
            disabled={disabled}
            onDeselect={onDeselect}
          ></Select>
      </ConfigProvider>
  );
};
export default CustomSelect;

const TYPE_ACTION = {
  CAMERA: {
    GET_LIST_DEVICE: "GET_LIST_DEVICE",
    GET_INFO_CAMERA_ID: "GET_INFO_CAMERA_ID",
    UPDATE_INFO_CAMERA_ID: "UPDATE_INFO_CAMERA_ID",
    UPDATE_ADDRESS_CAMERA: "UPDATE_ADDRESS_CAMERA",
    DELETE_CAMERA_ID: "DELETE_CAMERA_ID",
  },
  LIVE: {
    GET_LIST_PROVINCE: "GET_LIST_PROVINCE",
    CHANGE_GRID: "CHANGE_GRID",
    OPEN_LIST: "OPEN_LIST",
    GET_LIST_CAMERA_PREVIEW: "GET_LIST_CAMERA_PREVIEW",
    GET_LIST_GROUP: "GET_LIST_GROUP",
    GET_DATA_CAMERA_WITH_PROVINCE: "GET_DATA_CAMERA_WITH_PROVINCE",
    GET_PREVIEW_WITH_ID: "GET_PREVIEW_WITH_ID",
    GET_DISTRICT_LIVE: "GET_DISTRICT_LIVE",
    GET_WARD_LIVE: "GET_WARD_LIVE",
    GET_DEVICE_LIVE: "GET_DEVICE_LIVE",
    GET_ALL_CAMERA_STREAM: "GET_ALL_CAMERA_STREAM",
    STOP_STREAM: "STOP_STREAM",
    STOP_ALL_STREAM: "STOP_ALL_STREAM",
    GET_LIVE_GROUP: "GET_LIVE_GROUP",
    CREATE_GROUP: "CREATE_GROUP",
    UPDATE_GROUP: "UPDATE_GROUP",
    DELETE_GROUP: "DELETE_GROUP",
    TREE_GROUP: "TREE_GROUP",
    SEARCH_CAMERA: "SEARCH_CAMERA",
    CREATE_CAMERA: "CREATE_CAMERA",
    GET_CAMERA_NON_ADDRESS: "GET_CAMERA_NON_ADDRESS",
    DELETE_CAMERA: "DELETE_CAMERA",
    GET_CAMERA_ON_TREE: "GET_CAMERA_ON_TREE",
    CREATE_TAB: "CREATE_TAB",
    GET_TAB: "GET_TAB",
    DELETE_TAB: "DELETE_TAB",
    UPDATE_TAB: "UPDATE_TAB",
    GET_LIST_GROUP_LIVE: "GET_LIST_GROUP_LIVE",
    ADD_CAMERA_IN_TAB: "ADD_CAMERA_IN_TAB",
    REMOVE_CAMERA_IN_TAB: "REMOVE_CAMERA_IN_TAB",
  },
  MAP: {
    SCAN_CAMERA_SINGLE_IP: "SCAN_CAMERA_SINGLE_IP",
    //
    GET_LOCATION_CAMERA: "GET_LOCATION_CAMERA",
    GET_LOCATION_CAMERA_SCANNING: "GET_LOCATION_CAMERA_SCANNING",
    GET_ADDRESS: "GET_ADDRESS",
    GET_PROVINCE: "GET_PROVINCE",
    GET_WARD: "GET_WARD",
    GET_CAMERA_SERACH: "GET_CAMERA_SERACH",
    GET_LOCATION_CAMERA_CIRCLE: "GET_LOCATION_CAMERA_CIRCLE",
    GET_LOCATION_CAMERA_REC: "GET_LOCATION_CAMERA_REC",
    GET_TOTAL_CAMERA: "GET_TOTAL_CAMERA",
    GET_LIST_PROCESSING: "GET_LIST_PROCESSING",
    GET_COUNT_CAMERA_MAP:"GET_COUNT_CAMERA_MAP",
    SET_STATE_SCANNING:"SET_STATE_SCANNING",
    //
    GET_DISTRICT: "GET_DISTRICT",
    GET_CODE: "GET_CODE",
    GET_TOTAL: "GET_TOTAL",
    GET_RECORDER: "GET_RECORDER",
    GET_ADDRESS_DRAW_CIRCLE: "GET_ADDRESS_DRAW_CIRCLE",
    GET_ADDRESS_DRAW_POLYGON: "GET_ADDRESS_DRAW_POLYGON",
    GET_PREVIEW: "GET_PREVIEW",
    CLOSE_PRVIEW: "CLOSE_PRVIEW",
    CHANGE_KEY: "CHANGE_KEY",
    GET_DATA_CAMERA_GROUP: "GET_DATA_CAMERA_GROUP",
    CLOSE_GROUP: "CLOSE_GROUP",
  },
  ACTION: {
    IS_TOGGLE: "IS_TOGGLE",
    IS_SIDEBAR: "IS_SIDEBAR",
    IS_ACTION_CAMERA: "IS_ACTION_CAMERA",
    CHANGE_KEY_STREAM: "CHANGE_KEY_STREAM",
  },
  AUTH: {
    SIGN_IN: "SIGN_IN",
  },
  GROUP: {
    GET_GROUP: "GET_GROUP",
    GET_LIST_GROUP: "GET_LIST_GROUP",
    GET_ALL_CAMERA: "GET_ALL_CAMERA",
    GET_CAMERA_GROUP: "GET_CAMERA_GROUP",
    EDIT_CAMERA_GROUP: "EDIT_CAMERA_GROUP",
    DELETE_CAMERA_GROUP: "DELETE_CAMERA_GROUP",
    UPDATE_CAMERA_GROUP: "UPDATE_CAMERA_GROUP",
    ADD_CAMERA_GROUP: "ADD_CAMERA_GROUP",
    CREATE_GROUP: "CREATE_GROUP",
    ADD_CAMERA_ON_GROUP: "ADD_CAMERA_ON_GROUP",
    DELETE_CAMERA_ON_GROUP: "DELETE_CAMERA_ON_GROUP",
  },
  AI: {
    GET_AI_FLOW: "GET_AI_FLOW",
    CREATE_AI_FLOW: "CREATE_AI_FLOW",
    DELETE_AI_FLOW: "DELETE_AI_FLOW",
    GET_POLYGON: "GET_POLYGON",
    CREATE_POLYGON: "CREATE_POLYGON",
    DELETE_POLYGON: "DELETE_POLYGON",
    ADD_POLYGON_TO_AI_FLOW: "ADD_POLYGON_TO_AI_FLOW",
    ADD_AI_FLOW_TO_CAMERA: "ADD_AI_FLOW_TO_CAMERA",
    GET_BY_CAMERA_AND_TYPE: "GET_BY_CAMERA_AND_TYPE",
    UPDATE_POLYGON: "UPDATE_POLYGON",
    APPLY_AI_FLOW: "APPLY_AI_FLOW",
  },
  SETTING: {
    GET_ALL_JOB: "GET_ALL_JOB",
    CREATE_JOBS: "CREATE_JOB",
    DELETE_JOBS: "DELETE_JOB",
    UPDATE_JOBS: "UPDATE_JOB",
  },
  PLAYBACK:{
    GET_RECORDER_PLAYBACK: "GET_RECORDER_PLAYBACK",
    GET_VIDEO_RECORD_PLAYBACK:"GET_VIDEO_RECORD_PLAYBACK"
  }
};
export default TYPE_ACTION;

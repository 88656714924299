import GridLayoutPlayback from "@pages/playback/component/grid-playback";
import colors from "@constants/colors";
import { useDispatch } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomTreeLive from "@common/tree/tree-live";
import HeaderTab from "@pages/live/components/headerTab";
import { ConfigProvider, DatePicker, Tabs } from "antd";
import CustomTreeLiveGroup from "@common/tree/tree-group";
import { handleClearListCameraTab } from "@redux/slice/live-slice";
import openIcon from "@assets/open.svg";
import closeIcon from "@assets/close.svg";
import { handleChangeTreeWidth } from "@redux/slice/action-slice";
import TreePlayBack from "./component/tree-playback";
import HeaderTabPlayBack from "./component/header-tab";
import TimeFilter from "./component/time-filter";
import ControlPlayer from "./component/controls";
import ListFile from "./component/listFile";
import TimeLine from "./component/timeline";
import viVN from "antd/locale/vi_VN";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const PlayBack = () => {
  const [isShowTree, setIsShowTree] = useState(true);
  const widthTree = isShowTree ? 28 : -224;
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false)
  const [dataFile,setDataFile] = useState([])
  const [isShowSearch,setIsShowSearch] = useState({
    select:false,
    time:false
  })
  const formState = useRef({
    nvrGroupId: null,
    dateFrom: null,
    dateTo:null
  });
  const loadDataProvince = () => {
    const callback = () => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: { body: {}, callback },
    });
  };

  useEffect(() => {
    dispatch(handleClearListCameraTab());
    loadDataProvince();
  }, []);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [open, setOpen] = useState(true);
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };
  useEffect(() => {
    setScreenHeight(window.innerHeight);
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  function convertToCustomFormat(inputDate) {
    // Chuyển chuỗi thành đối tượng Date
    const date = new Date(inputDate);
  
    // Định dạng ngày, tháng, năm và giờ
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Tháng bắt đầu từ 0
    const year = date.getFullYear();
  
    // Trả về chuỗi với định dạng "DD/MM/YYYY HH:MM:SS"
    return `${day}/${month}/${year} 00:00:00`;
  }
  const handleSearch = () => {
    setLoading(true)
    const callback = (data)=>{
      console.log(data)
      let listRecord = []
      setLoading(false)
      data.data.map((item,index)=>{
        listRecord.push({
          id:item.id,
          name: `camera record ${index}`,
          time: "15",
          date: `${convertToCustomFormat(item.dateFrom)} - ${convertToCustomFormat(item.dateTo)}`,
        })
      })
      setDataFile(listRecord)
    }
    dispatch({
      type:TYPE_ACTION.PLAYBACK.GET_RECORDER_PLAYBACK,
      payload:{body:formState.current,callback}
    })
  };
  function convertToISO(inputDate) {
    let isoString = inputDate.replace(' ', 'T');
    isoString += '+07:00';
    return isoString;
  }
  const handleChangeRangeTime = (s, e) => {
    let startTime = encodeURIComponent(convertToISO(`${e[0]} 00:00:00`));
  let endTime = encodeURIComponent(convertToISO(`${e[1]} 23:59:59`));
    formState.current.dateFrom = startTime
    formState.current.dateTo = endTime
    setIsShowSearch((pre)=>({
      ...pre,
      time:true
    }))
  };
  return (
    <div className="h-full flex w-full space-x-1 overflow-hidden relative">
      <div
        style={{ background: colors.bg_input, width: open ? "400px" : "40px" }}
        className="relative"
      >
        {open ? (
          <img
            alt="close"
            src={closeIcon}
            onClick={() => {
              dispatch(
                handleChangeTreeWidth({
                  open: false,
                  width: 40,
                })
              );
              setOpen(false);
            }}
            className="cursor-pointer absolute right-[4px] top-[4px]"
          />
        ) : (
          <img
            alt="open"
            src={openIcon}
            onClick={() => {
              dispatch(
                handleChangeTreeWidth({
                  open: true,
                  width: 400,
                })
              );
              setOpen(true);
            }}
            className="cursor-pointer absolute left-[10px] top-[10px]"
          />
        )}
        {open && (
          <div className="mt-[30px] overflow-auto">
            <TreePlayBack width={300} height={200} callbackID={(e)=>{
              formState.current.nvrGroupId = e
              setIsShowSearch((pre)=>({
                ...pre,
                select:true
              }))
            }} />
            <div className="flex items-center justify-center mt-[10px]">
              <ConfigProvider
                locale={viVN}
                theme={{
                  components: {
                    DatePicker: {
                      colorTextPlaceholder: "#444459",
                      colorBgContainer: "transparent",
                      colorBorder: "#444459",
                      borderRadius: 4,
                      controlHeight: 39,
                      colorPrimaryHover: "none",
                      colorBgContainerDisabled: "#444459",
                      colorText: "white",
                      colorTextDisabled: "#444459",
                      controlOutlineWidth: 0,
                    },
                  },
                }}
              >
                <RangePicker
                  allowClear={false}
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  onChange={(s, e) => handleChangeRangeTime(s, e)}
                />
              </ConfigProvider>
            </div>
            <div className="flex items-center space-x-[8px] px-[6px] mt-[16px]">
              <div
                style={{
                  background:(isShowSearch.select && isShowSearch.time)?"#5B5B9F":"#444459"
                }}
                className="w-full h-[30px] flex items-center justify-center  rounded text-white cursor-pointer select-none"
                onClick={() => {
                  if(isShowSearch.select && isShowSearch.time){
                    handleSearch()
                  }
                }}
              >
                Tìm kiếm
              </div>
              <div className="w-full h-[30px] flex items-center justify-center bg-[#444459] rounded text-white cursor-pointer select-none">
                Huỷ
              </div>
            </div>
            {/* <TimeFilter handleSearch={()=>handleSearch()}/> */}
            <ControlPlayer />
          </div>
        )}
      </div>
      <div className="w-full overflow-hidden h-full">
        <HeaderTabPlayBack />
        <div>
          <DndProvider backend={HTML5Backend}>
            <GridLayoutPlayback widthTree={widthTree} />
          </DndProvider>
        </div>
        <TimeLine />
      </div>
      <div className="h-full bg-[#181824]">
        <ListFile  loading={loading} dataFile={dataFile}/>
      </div>
    </div>
  );
};
export default PlayBack;

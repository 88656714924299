import { ConfigProvider, Spin, Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import { useDrag } from "react-dnd";
import { handleDragItem, selectListCameraLayout, selectListProvince } from "@redux/slice/live-slice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Search from "@common/search/custom-search";
import { imagesGroup } from "@common/images/image";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
const CustomTreeLive = ({
  onSelect,
  onExpand,
  height = null,
  width,
  isGetDataTable,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [keyChange,setkeyChange] = useState(0)
  const layout = useSelector(selectListCameraLayout);
  const dispatch = useDispatch();
  const dataListTree = useSelector(selectListProvince);
  let [searchParams] = useSearchParams();
  let tabid = searchParams.get("tabid");
  const filterLocationCameraRef = useRef({
    communeIds: null,
    districtIds: null,
    ids: null,
    ips: null,
    pageNumber: 0,
    pageSize: 10,
    provinceIds: null,
    status: null,
    name: null,
  });
  useEffect(() => {
    mapData(dataListTree);
  }, [dataListTree]);

  const mapData = (data) => {
    const arr = [];
    data?.map((item) => {
      const key = item.id;
      if (item.id === 1) {
        // console.log(item)
      }
      const treeNode = {
        title: item.name,
        key,
        id: item.id,
        isLeaf: false,
        children: [],
        cameraCount: item.cameraConnectedCount,
        level: 0,
        isloading:false
      };
      arr.push(treeNode);
    });
    setTreeData(arr);
  };
  const recursiveUpdateData = async (dataTree, keyToFind, TYPE, level = 1) => {
    const updatedDataTree = await Promise.all(
      dataTree.map(async (node) => {
        if (node.key === keyToFind && !node.loaded) {
          node.isloading = true
          const data = await new Promise((resolve) => {
            const callBack = (data) => {
              resolve(data);
            };
            dispatch({
              type: TYPE,
              payload: { body: node.id, callBack },
            });
          });
          const updatedChildren = data.map((e, i) => ({
            title: e.name,
            key: `${node.key}-${i}`,
            id: e.id,
            children: [],
            item: e,
            level: level,
            cameraCount: e.cameraConnectedCount,
            provinceId: node.id,
            loaded: false,
          }));
          return {
            ...node,
            children: updatedChildren,
            loaded: true,
            isloading:false
          };
        } else if (node.children && node.children.length > 0) {
          if (level < 2) {
            await Promise.all(
              node.children.map(async (node) => {
                if (node.key === keyToFind && !node.loaded) {
                  node.isloading = true
                  const data = await new Promise((resolve) => {
                    const callBack = (data) => {
                      resolve(data);
                    };
                    dispatch({
                      type: TYPE_ACTION.LIVE.GET_WARD_LIVE,
                      payload: { body: node.id, callBack },
                    });
                  });
                  const updatedChildren = data.map((item, i) => ({
                    title: item.name,
                    key: `${node.key}-${i}`,
                    id: item.id,
                    children: [],
                    item: item,
                    level: level + 1,
                    cameraCount: item.cameraConnectedCount,
                    provinceId: node.provinceId,
                    wardId: node.id,
                    isloading:false
                  }));
                  if (updatedChildren.length > 0) {
                    node.children = updatedChildren;
                    node.loaded = true;
                    node.isloading = false;
                  } else {
                     node.children=[];
                  }
                } else if (node.children && node.children.length > 0) {
                  if (level < 3) {
                    await Promise.all(
                      node.children.map(async (node) => {
                        if (node.key === keyToFind && !node.loaded) {
                          node.isloading = true
                          const data = await new Promise((resolve) => {
                            const callBack = (data) => {
                              resolve(data);
                            };
                            dispatch({
                              type: TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
                              payload: {
                                body: { communeIds: node.id,pageSize:1000,state:'CONNECTED' },
                                callBack,
                              },
                            });
                          });
                          const updatedChildren = data.map((item, i) => ({
                            index: i,
                            title: item.name,
                            key: `${node.key}-${i}`,
                            camera: true,
                            isLeaf: true,
                            url: item.urlMainstream,
                            status: item.status,
                            id: item.id,
                            level: level + 2,
                            name: item.ipAddress,
                            provinceId: node.provinceId,
                            wardId: node.wardId,
                            cityId: node.id
                          }));
                          if (updatedChildren.length > 0) {
                            node.children = updatedChildren;
                            node.loaded = true;
                            node.isloading = false;
                          } else {
                             node.children = [];
                             node.isloading = false;
                          }
                        } else if (node.children && node.children.length > 0) {
                          return node;
                        }
                      })
                    );
                  }
                }
              })
            );
          }
        }
        return node;
      })
    );

    return updatedDataTree;
  };

  const treeDataCopy = useRef();
  const onLoadData = async (treeNode) => {
    try {
      const updatedTreeData = await recursiveUpdateData(
        treeData,
        treeNode.key,
        TYPE_ACTION.LIVE.GET_DISTRICT_LIVE
      );
      treeDataCopy.current = updatedTreeData;
      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  const loadDataCame = () => {
    const callBack = (data) => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_CAMERA_ON_TREE,
      payload: { body: filterLocationCameraRef.current, callBack: callBack },
    });
  };
  const handleExpandToggle = (key, nodeData) => {
    if (isGetDataTable) {
      filterLocationCameraRef.current.provinceIds = nodeData.id;
      loadDataCame();
    }
    const listData = [
      nodeData.id,
      ...(nodeData?.children ? nodeData.children.map((item) => item.id) : []),
    ];

    setSelectedKeys(listData);
    setExpandedKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };

  const handleDbClickStreamCamera =(item)=>{
    console.log("item",item);
    const callBack = (data) => {
      if (data) {
        console.log(data);
        console.log("layout",layout);
      //   {
      //     "id": "a8f16862-ce76-40d8-b82b-245e1e038d23",
      //     "url": "https://streaming-scn.ai-vlab.com/camera/a8f16862-ce76-40d8-b82b-245e1e038d23.flv",
      //     "x": 0,
      //     "y": 0,
      //     "name": "CAMERA_RTSP_118.70.215.52",
      //     "column": 1,
      //     "h": 1,
      //     "w": 1
      // }
      }
    };
    const errorBack = (data) => {
    };
    const callback = (data)=>{}
    dispatch({
      type: TYPE_ACTION.LIVE.ADD_CAMERA_IN_TAB,
      payload: {body:{
        id: tabid,
        ids: [item.id],
      },callback},
    });
    const payload = {
      url: item.id,
      callBack,
      errorBack,
    };
    dispatch({
      type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
      payload,
    });
  }
  const CameraSingle = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "single",
          url: node.id,
          node: node,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <>
        <div
            ref={drag}
            className="flex items-center justify-between  border-[#2E2E2E] pr-[20px]"
            style={{ paddingLeft: `${node.level * 20}px`, width: width }}
            onClick={(e) => {
              e.stopPropagation();
              handleExpandToggle(node.key, node);
            }}
            onDoubleClick={(e)=>{
              e.stopPropagation();
              handleDbClickStreamCamera(node)
            }}
          >
            <div className="flex items-center w-full p-2 space-x-2">
              <img alt="icon" src={imagesGroup.camera_connected} />
              <span className="flex-1">
                {node.title}{" "}
                {node.level < 3 && (
                  <>
                    <span className="text-[#01D68F]">
                      ({node.cameraCount > 0 ? node.cameraCount : 0})
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
      </>
    );
  };
  const CameraGroup = ({ node, name, key }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "GET",
      item: () => {
        const dragItem = {
          type: "group",
          url: node.id,
          node: node,
        };
        dispatch(handleDragItem(dragItem));
        return dragItem;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));
    return (
      <div
        ref={drag}
        className="flex items-center justify-between  border-[#2E2E2E] px-[14px]"
        style={{ paddingLeft: `${node.level * 20 + 10}px`, width: width - 10 }}
        onClick={(e) => {
          e.stopPropagation();
          handleExpandToggle(node.key, node);
        }}
      >
        <div className="flex items-center w-full p-2">
          <span className="flex-1">
            {node.title}{" "}
            {node.level < 3 && (
              <>
                <span className="text-[#01D68F]">
                  ({node.cameraCount > 0 ? node.cameraCount : 0})
                </span>
              </>
            )}
          </span>
        </div>
        {
          node.isloading ?<Spin></Spin>:<DownOutlined style={{ color: "#444459" }} />
        }
       
      </div>
    );
  };
  const memoizedTitleRender = useMemo(() => {
    return (node) => {
      const hasChildren = node.children && node.children.length >= 0;
      if (hasChildren) {
        return <CameraGroup node={node} name={node.title} />;
      } else {
        return <CameraSingle node={node} name={node.title} />;
      }
    };
  }, []);
  const loadDataProvince = () => {
    const callback = () => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_LIST_PROVINCE,
      payload: { body: filterLocationCameraRef.current, callback },
    });
  };
  const debouncedLoadData = useCallback(
    _.debounce(() => {
      setkeyChange(pre=>pre+1)
      loadDataProvince();
    }, 500), // 500ms là thời gian chờ để debounce
    []
  );
  const handleOnChange = (e) => {
    filterLocationCameraRef.current.name = e.target.value;
    debouncedLoadData()
  };
  const handleMoreView = async (node) => {
    for (const province of treeDataCopy.current) {
      if (province.id === node.provinceId) {
        for (const ward of province.children) {
          if (ward.id === node.wardId) {
            for (const city of ward.children) {
              if (city.id === node.cityId) {
                city.children =[
                  {
                      "index": 0,
                      "title": "CAMERA_test2",
                      "key": "4e4f1d80-ecd8-4d2a-85d0-110754fecbbb-0-6-0",
                      "camera": true,
                      "isLeaf": true,
                      "url": "rtsp://admin:abcd1234@14.248.173.211:554/Streaming/Channels/401/",
                      "id": "2e9957f1-f70d-4e45-8715-20903af0bee1",
                      "level": 3,
                      "name": "14.248.173.211",
                      "provinceId": "4e4f1d80-ecd8-4d2a-85d0-110754fecbbb",
                      "wardId": "17dcb167-6db5-4221-8003-4d76051085cf",
                      "cityId": "6295d710-a22c-41de-b9de-003bf8d65dc7"
                  },
              ]
              }
            }
          }
        }
      }
    }
    setTreeData([...treeDataCopy.current]);
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="h-[900px]  scroll_default overflow-auto"
        style={{
          width: width,
        }}
      >
        <div className="px-[20px] py-[12px]">
          <Search
            placeholder="Nhập từ khóa tìm kiếm"
            onChange={(e) => handleOnChange(e)}
          />
        </div>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "rgba(16, 16, 22, 0)",
              controlItemBgActive: colors.transparent,
              lineHeight: 2,
              colorText: colors.textBase,
              colorPrimary: colors.redGlobal,
              colorBorder: colors.borderColor,
              paddingXS: 5,
            },
          }}
        >
          <Tree
            key={keyChange}
            checkable={false}
            loadData={onLoadData}
            treeData={treeData}
            showIcon={false}
            height={height}
            selectedKeys={selectedKeys}
            switcherIcon={null}
            showLine={false}
            onSelect={onSelect}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            titleRender={memoizedTitleRender}
          />
        </ConfigProvider>
      </div>
    </DndProvider>
  );
};
export default CustomTreeLive;

import { SERVICE_PATHS } from "@constants/url";
import { get, remove, post, patch } from "../../api/api-base";
const JobService = {
  getAllJob(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.SETTING.GET_ALL_JOB);
    }

    return get(`${SERVICE_PATHS.SETTING.GET_ALL_JOB}${filterString}`);
  },

  createJob: (body) => post(SERVICE_PATHS.SETTING.CREATE_JOBS, body),
  deleteJob: (body) =>
    remove(`${SERVICE_PATHS.SETTING.DELETE_JOBS}?ids=${body}`),
  updateJob: (body) => patch(SERVICE_PATHS.SETTING.UPDATE_JOBS, body),
};

export default JobService;

import { SERVICE_PATHS } from "@constants/url";
import { get, post,remove,patch,put } from "src/api/api-map";

const LiveService = {
  getDatalistProvince: (body) => {
    var filterString = "?";
    Object.keys(body).forEach((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString) {
      return get(`${SERVICE_PATHS.LIVE.GET_PROVINCE}${filterString}`);
    } else {
      return get(SERVICE_PATHS.LIVE.GET_PROVINCE);
    }
  },

  getCameraDataWithProvince: (body) => {
    var filterString = "?";
    Object.keys(body).forEach((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    console.log("filterString",filterString);
    // if (filterString === "?") {
    //   return get(SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE);
    // } else {
    //   return get(
    //     `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}${filterString}`
    //   );
    // }
  },

  createTab: (body) => post(SERVICE_PATHS.LIVE.CREATE_TAB, body),
  getTab: (body) => {
    if(body){
      return get(`${SERVICE_PATHS.LIVE.GET_TAB}?ids=${body}&detail=true`);
    }
    else{
      return get(`${SERVICE_PATHS.LIVE.GET_TAB}`);
    }
    
  },
  deleteTabs: (body) => {
    return remove(`${SERVICE_PATHS.LIVE.DELETE_TAB}?ids=${body}`);
  },
  updateTabs: (body) => {
    return patch(`${SERVICE_PATHS.LIVE.UPDATE_TAB}`,body);
  },
  getListGroup: (body) => {
    var filterString = "?";
    Object.keys(body).forEach((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(`${SERVICE_PATHS.GROUP.GET_LIST_GROUP}`);
    } else {
      return get(
        `${SERVICE_PATHS.GROUP.GET_LIST_GROUP}${filterString}`
      );
    }
  },
  getRecord: (body) => {
    var filterString = "?";
    Object.keys(body).forEach((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(`${SERVICE_PATHS.PLAYBACK.GET_RECORD}`);
    } else {
      return get(
        `${SERVICE_PATHS.PLAYBACK.GET_RECORD}${filterString}`
      );
    }
  },
  addCameraTab: (body) => post(SERVICE_PATHS.LIVE.ADD_CAMERA_IN_TAB, body),
  removeCameraTab: (body) => post(SERVICE_PATHS.LIVE.REMOVE_CAMERA_IN_TAB, body),
  ///////





  getPreviewCamWithID: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_PREVIEW_WITH_ID}?cameraId=${body}&format=FLV`);
  },
  getVideoRecord: (body) => {
    return get(`${SERVICE_PATHS.PLAYBACK.GET_VIDEO_RECORD}?nvrRecId=${body}&format=FLV&isPublic=true`);
  },
  getPreviewCamWithIDTree: (body) => {
    let filterString = "?";
    Object.keys(body).forEach((index) => {
      // Kiểm tra nếu body[index] khác null và undefined, đồng thời không phải false
      if (filterString !== "?" && body[index] != null && body[index] !== false) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index] != null && body[index] !== false) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.LIVE.GET_CAMERA_WITH_ID_TREE}${filterString}`);
  },
  getDistric: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_DISTRICT}?provinceIds=${body}`);
  },
  getWard: (body) => {
    return get(
      `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}?districtIds=${body}`
    );
  },
  getDevice: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_DEVICE}?ward_ids=${body}`);
  },
  getAllCameraStream: (body) => {
    return get(`${SERVICE_PATHS.LIVE.GET_ALL_CAMERA_STREAM}`);
  },
  stopStream: (id) => {
    return get(`${SERVICE_PATHS.LIVE.STOP_STREAM}?ids=${id}`);
  },
  stopAllStream: (id) => {
    return get(`${SERVICE_PATHS.LIVE.STOP_ALL_STREAM}`);
  },
  getGroup: (body) => {
    return get(SERVICE_PATHS.LIVE.GET_LIVE_GROUP);
  },
  getCameraNonAddress: (body) => {
    return get(SERVICE_PATHS.LIVE.GET_CAMERA_NON_ADDRESS);
  },
  // createGroup: (body) => post(SERVICE_PATHS.LIVE.CREATE_GROUP, body),
  createCamera: (body) => post(SERVICE_PATHS.LIVE.CREATE_CAMERA, body),
  deleteCamera: (body) => post(SERVICE_PATHS.LIVE.DELETE_CAMERA, body),

  getDataCameraSearch: (body) => {
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index] !== null) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE);
    } else {
      return get(
        `${SERVICE_PATHS.LIVE.GET_DATA_CAMERA_WITH_PROVINCE}${filterString}&status=true`
      );
    }
  },
};

export default LiveService;

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import colors from "@constants/colors";
import button_add from "@assets/AddScan.svg";
import button_del from "@assets/DeleteScan.svg";
import { ConfigProvider, Form, Input, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import CustomButtonCommon from "@common/button/custom-button";
import { useDispatch, useSelector } from "react-redux";
import { selectDataEdit } from "@redux/slice/setting-job-slice";
import TYPE_ACTION from "@constants/action";

const ButtonGrid = ({ isModalOpen, setActiveTexts, setIsActive }) => {
  const dataEdit = useSelector(selectDataEdit);
  const initialActiveState = Array(30).fill(false);
  const [activeButtons, setActiveButtons] = useState(initialActiveState);

  const resetStates = () => {
    setActiveButtons(initialActiveState);
    setActiveTexts([]);
  };

  dataEdit &&
    dataEdit.scanDays.forEach((index) => {
      if (index < initialActiveState.length) {
        initialActiveState[index] = true;
      }
    });

  useEffect(() => {
    if (isModalOpen) {
      resetStates();
    }
  }, [isModalOpen]);
  useEffect(() => {
    if (activeButtons.some((isActive) => isActive)) {
      setIsActive(false);
    } else {
      // setIsActive(true);
    }
  }, [activeButtons]);
  const handleButtonClick = (index) => {
    if (index !== 0) {
      setIsActive(false);
      const newActiveButtons = [...activeButtons];
      newActiveButtons[index] = !newActiveButtons[index];
      const newActiveTexts = newActiveButtons
        .map((isActive, i) => (isActive ? i : null))
        .filter(Boolean);

      setActiveButtons(newActiveButtons);
      setActiveTexts(newActiveTexts);
    }
  };

  const handleText = (index) => {
    if (index === 0) {
      return "";
    } else if (index < 10) {
      return "0" + index;
    } else {
      return index + "";
    }
  };

  const renderButton = (index) => (
    <CustomButtonCommon
      key={index}
      width="56px"
      height="60px"
      backgroundColor={activeButtons[index] ? "#21A603" : "#444459"}
      border="none"
      text={handleText(index)}
      onClick={() => handleButtonClick(index)}
    />
  );

  return (
    <div className="p-2 flex flex-col gap-2">
      {[0, 1].map((row) => (
        <div key={row} className="flex gap-2">
          {Array.from({ length: 16 }, (_, index) =>
            renderButton(row * 16 + index)
          )}
        </div>
      ))}
    </div>
  );
};

const ContentSetting = ({ resetContent, handleCancel, handleGetData }) => {
  useEffect(() => {
    return () => {
      resetContent();
    };
  }, []);
  const [form] = Form.useForm();
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [activeTexts, setActiveTexts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const dataEdit = useSelector(selectDataEdit);
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const inputAdminRefs = useRef([]);

  const listIPRef = useRef([
    {
      index: 0,
      fromIP: "",
      toIP: "",
      Port: "",
      action: true,
    },
  ]);
  const listUserName = useRef([
    {
      index: 0,
      username: "",
      password: "",
      action: true,
    },
  ]);

  useEffect(() => {
    form.setFieldsValue({
      listIP: {
        0: {
          fromIp: dataEdit?.fromIp ? dataEdit?.fromIp : "",
          toIp: dataEdit?.toIp ? dataEdit?.toIp : "",
        },
      },
    });
    if (dataEdit) {
      setActiveTexts(dataEdit?.scanDays);
    }
  }, [dataEdit]);

  const handleAddListIP = (index) => {
    listIPRef.current = [
      ...listIPRef.current.map((item) => ({ ...item, action: false })),
      {
        index: index + 1,
        fromIP: "",
        toIP: "",
        Port: "",
        action: true,
      },
    ];
    setRenderTrigger((prev) => !prev);
    setTimeout(() => {
      if (inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }, 0);
  };

  const handleBack = () => {
    form.resetFields();
    setIsActive(false);
    handleCancel();
    handleGetData();
  };

  const handleRemove = (index) => {
    listIPRef.current.splice(index, 1);
    if (listIPRef.current.length > 0) {
      listIPRef.current[listIPRef.current.length - 1].action = true;
    }

    setRenderTrigger((prev) => !prev);
  };

  const handleAddListIF = (index) => {
    listUserName.current = [
      ...listUserName.current.map((item) => ({ ...item, action: false })),
      {
        index: index + 1,
        username: "",
        password: "",
        action: true,
      },
    ];
    setRenderTrigger((prev) => !prev);
    setTimeout(() => {
      if (inputAdminRefs.current[index + 1]) {
        inputAdminRefs.current[index + 1].focus();
      }
    }, 0);
  };

  const handleRemoveIF = (index) => {
    listUserName.current.splice(index, 1);
    if (listUserName.current.length > 0) {
      listUserName.current[listUserName.current.length - 1].action = true;
    }
    setRenderTrigger((prev) => !prev);
  };
  const handleSave = async () => {
    // setIsActive(true);
    await form.validateFields();
    const values = form.getFieldsValue();
    if (dataEdit) {
      //sửa
      try {
        if (activeTexts.length > 0) {
          const listData = {
            id: dataEdit.id,
            ...(values.listIP[0]?.fromIp && {
              fromIp: values.listIP[0].fromIp,
            }),
            ...(values.listIP[0]?.toIp && { toIp: values.listIP[0].toIp }),
            ...(values.listUser[0]?.username && {
              username: values.listUser[0].username,
            }),
            ...(values.listUser[0]?.password && {
              password: values.listUser[0].password,
            }),
            ...(activeTexts.length > 0 && { scanDays: activeTexts }),
            activate: true,
          };
          const callBack = (data) => {
            handleBack();
            setIsActive(false);
          };
          dispatch({
            type: TYPE_ACTION.SETTING.UPDATE_JOBS,
            payload: { body: listData, callBack },
          });
        }
      } catch (error) {
        setIsActive(true);
      }
    } else {
      // tạo
      try {
        if (activeTexts.length > 0) {
          const listData = values.listIP.map((ip) => ({
            fromIp: ip.fromIp,
            toIp: ip.toIp,
            scanDays: activeTexts,
            activate: true,
          }));
          const callBack = (data) => {
            handleBack();
          };
          dispatch({
            type: TYPE_ACTION.SETTING.CREATE_JOBS,
            payload: { body: listData, callBack },
          });
          setIsActive(false);
        }
      } catch (error) {
        setIsActive(true);
      }
    }
  };
  return (
    <Form name="basic" form={form} autoComplete="off" className="w-full">
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 4,
            colorBgBase: "#2B2A3A",
            colorBorder: colors.borderColor,
            controlHeight: 40,
            controlOutlineWidth: 0,
            colorText: colors.textBase,
            controlItemBgActive: "#5B5B9F",
            colorTextPlaceholder: "#706F80",
            colorTextDisabled: colors.textBase,
          },
        }}
      >
        <div className="p-4 gap-[20px] bg-[#2B2A3A]">
          <div className="max-h-[200px] overflow-auto scroll_default">
            {listIPRef.current.map((item, index) => {
              return (
                <div key={index} className="flex pb-[16px] gap-[24px]">
                  <div className="w-[470px]">
                    <Typography.Text>
                      <span style={{ color: "#F7F0F7" }}>Từ IP</span>
                      <span style={{ color: "red" }}>*</span>
                    </Typography.Text>
                    <div className="h-1"></div>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Hãy nhập IP bắt đầu",
                        },
                      ]}
                      name={["listIP", index, "fromIp"]}
                    >
                      <Input
                        ref={(el) => (inputRefs.current[index] = el)}
                        placeholder="Nhập dải IP bắt đầu"
                        disabled={dataEdit}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-[470px]">
                    <Typography.Text>
                      <span style={{ color: "#F7F0F7" }}>Đến IP</span>
                    </Typography.Text>
                    <div className="h-1"></div>
                    <Form.Item name={["listIP", index, "toIp"]}>
                      <Input
                        disabled={dataEdit}
                        placeholder="Nhập dải IP kết thúc"
                      />
                    </Form.Item>
                  </div>
                  <div className=" items-center">
                    <div className="h-[32px]"></div>
                    {item.action ? (
                      <>
                        <img
                          className="cursor-pointer h-[40px]"
                          src={button_add}
                          alt="add"
                          width={36}
                          onClick={() => {
                            if (!dataEdit) {
                              handleAddListIP(index);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <img
                        className="cursor-pointer h-[40px]"
                        src={button_del}
                        alt="del"
                        width={36}
                        onClick={() => handleRemove(index)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="max-h-[200px] overflow-auto scroll_default">
            {listUserName.current.map((item, index) => {
              return (
                <div key={index} className="flex pb-[16px] gap-[24px]">
                  <div className="w-[470px]">
                    <Typography.Text>
                      <span style={{ color: "#F7F0F7" }}>Username</span>
                    </Typography.Text>
                    <div className="h-1"></div>
                    <Form.Item name={["listUser", index, "username"]}>
                      <Input
                        ref={(el) => (inputAdminRefs.current[index] = el)}
                        placeholder="Nhập tài khoản"
                        disabled={dataEdit}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-[470px]">
                    <Typography.Text>
                      <span style={{ color: "#F7F0F7" }}>Password </span>
                    </Typography.Text>
                    <div className="h-1"></div>

                    <Form.Item name={["listUser", index, "password"]}>
                      <Input.Password
                        placeholder="Nhập mật khẩu"
                        disabled={dataEdit}
                        iconRender={(visible) =>
                          visible ? (
                            <EyeOutlined style={{ color: "#fff" }} />
                          ) : (
                            <EyeInvisibleOutlined style={{ color: "#fff" }} />
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="flex items-center mt-[30px]">
                    {item.action ? (
                      <img
                        className="cursor-pointer h-[40px]"
                        src={button_add}
                        alt="add"
                        width={36}
                        onClick={() => {
                          if (!dataEdit) {
                            handleAddListIF(index);
                          }
                        }}
                      />
                    ) : (
                      <img
                        className="cursor-pointer h-[40px]"
                        src={button_del}
                        alt="del"
                        width={36}
                        onClick={() => handleRemoveIF(index)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <span>Chọn ngày quét định kỳ trong tháng </span>
            <span style={{ color: "red" }}>*</span>
            <ButtonGrid
              isModalOpen={resetContent}
              setActiveTexts={setActiveTexts}
              setIsActive={setIsActive}
            />
            {isActive && (
              <span
                style={{
                  color: "#ff4d4f",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                Hãy chọn ngày quét định kỳ trong tháng
              </span>
            )}
          </div>
        </div>
      </ConfigProvider>
      <div
        className=" bg-[#2B2A3A] flex justify-end items-center px-4 py-6 gap-4"
        style={{ borderTop: "1px solid #656475" }}
      >
        <CustomButtonCommon
          onClick={handleBack}
          text="Hủy"
          width="60px"
          backgroundColor="#656475"
          border="none"
        />
        <CustomButtonCommon
          text="Lưu"
          width="60px"
          backgroundColor={colors.redGlobal}
          onClick={handleSave}
        />
      </div>
    </Form>
  );
};

export default ContentSetting;

const TimeLine = () => {
  const dataFake = Array(100).fill(null);
  return (
    <div className="w-full h-[103px]">
      <div className="w-full h-[20px] bg-[#5C687F] flex items-center px-[12px]">
        <p className="text-white">Tháng 08 năm 2024</p>
      </div>
      <div className="w-full bg-[#303750] h-[83px] flex items-center justify-start relative ">
        <div className="absolute flex top-[20px] space-x-5">
          {dataFake.map((_, index) => (
            <div key={index} className="h-[15px] w-[1px] bg-[#5C687F] "></div>
          ))}
        </div>
        <div className="absolute flex w-[400px] h-[10px] bg-[#16BD1D] ">
        </div>
        <div className="h-[1px] w-full border border-[#5C687F]"></div>
      </div>
    </div>
  );
};
export default TimeLine;

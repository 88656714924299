import TYPE_ACTION from "@constants/action";
import {
  handleAddToolBox,
  handleMinimize,
  handleRotate,
  selectColum,
  selectRow,
  warningItemById,
} from "@redux/slice/live-slice";
import { FaMinus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { RiFullscreenFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineRotateRight, AiFillAlert } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import { GrPowerReset } from "react-icons/gr";
import edit from "@assets/icon/edit2.svg";
import close from "@assets/icon/close2.svg";
import iconPerson from "@assets/icon/Person.svg";
import iconCar from "@assets/icon/Car.svg";
import iconCamera from "@assets/icon/camera.svg";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
const HeaderVideo = ({ idCamera, removeItemById, name, onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const column = useSelector(selectColum);
  const row = useSelector(selectRow);
  return (
    <div
      className="h-[10%] bg-[#000000a8] flex items-center justify-between px-[5px] absolute min-h-[30px]"
      onClick={(e) => e.stopPropagation()}
      style={{
        width: "100%",
        top: 0,
        zIndex: 9999,
      }}
    >
      <div className="flex items-center space-x-2">
        
        {row < 5 && <img alt="camera" src={iconCamera} className="h-[18px]" />}
        {row < 5 && <p className="text-white text-[100%] truncate ">{name}</p>}

        <img alt="person" src={iconPerson} />
        <img alt="car" src={iconCar} />
      </div>
      <div className="flex items-center space-x-1 z-[9999px]">
        <div
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${PATH.MAP}/${PATH.REGION}/edit/${idCamera}`);
          }}
        >
          <img alt="edit" src={edit} className="cursor-pointer" />
        </div>
        <div
          onClick={() => {
            removeItemById();
          }}
        >
          <img alt="close" src={close} className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default HeaderVideo;

export const SERVICE_PATHS = {
  AUTH: {
    SIGN_IN: "/api/auth/login",
    GET_INFO: "/AIViewService/api/aiview/getUserInfo",
  },
  CAMERA: {
    GET_DEVICE: "/api/vms/camera/get/",
  },
  EVENT: {
    GET_EVENT_ALL: "/api/cctv/get/",
  },
  PROFILE: {
    GET_PROFILE: "/api/ocr/get/",
  },
  VEHICLE: {
    GET_VEHICLE: "/api/anpr/get/",
  },
  LIVE: {
    GET_PROVINCE: "/api/address/province",
    GET_DISTRICT: "/api/address/district",
    GET_DATA_CAMERA_WITH_PROVINCE: "/api/address/commune",
    GET_CAMERA_WITH_ID_TREE: "/api/cameras",
    GET_PREVIEW_WITH_ID: "/api/stream/url",
    //
    GET_CAMERA: "/api/camera",
    GET_DEVICE: "/api/device",
    GET_ALL_CAMERA_STREAM: "/api/camera/all-cameras-playing-of-user",
    STOP_STREAM: "/api/camera/stop-stream",
    STOP_ALL_STREAM: "/api/camera/stop-stream/all",
    CREATE_GROUP: "/api/group/",
    UPDATE_GROUP: "/api/group/",
    DELETE_GROUP: "/api/group/",
    TREE_GROUP: "/api/group/trees",
    GET_LIVE_GROUP: "/api/group/",
    CREATE_CAMERA: "api/camera/create",
    GET_CAMERA_NON_ADDRESS: "/api/camera/get-non-address",
    DELETE_CAMERA: "api/camera/delete",
    CREATE_TAB: "/api/tab",
    GET_TAB: "/api/tab",
    DELETE_TAB: "/api/tab",
    UPDATE_TAB: "/api/tab",
    ADD_CAMERA_IN_TAB: "/api/tab/add-cameras",
    REMOVE_CAMERA_IN_TAB: "/api/tab/remove-cameras",
  },
  GROUP: {
    GET_GROUP: "/api/cctv/profile/get/",
    GET_LIST_GROUP: "/api/cameragroups",
    GET_CAMERA_GROUP: "GET_CAMERA_GROUP",
    EDIT_CAMERA_GROUP: "EDIT_CAMERA_GROUP",
    DELETE_GROUP: "/api/cameragroups",
    ADD_CAMERA_GROUP: "ADD_CAMERA_GROUP",
    GET_ALL_CAMERA: "/api/cameras",
    CREATE_GROUP: "/api/cameragroups",
    UPDATE_GROUP: "/api/cameragroups",
    ADD_CAMERA_ON_GROUP: "/api/cameragroups/add-cameras",
    DELETE_CAMERA_ON_GROUP: "/api/cameragroups/remove-cameras",
  },
  MAP: {
    GET_LOCATION: "/api/cameras/within-polygon",
    GET_ADDRESS: "/api/address",
    GET_PROVINCE: "/api/address/province",
    GET_DISTRICT: "/api/address/district",
    GET_WARD: "/api/address/commune",
    GET_CAMERA: "/api/cameras",
    GET_DRAW_CIRCLE: "/api/cameras/within-circle",
    GET_CAMERA_COUNT: "/api/cameras/count",
    GET_TOTAL: "/api/camera/count",
    GET_RECORDER: "/api/recorder",
    GET_PROCESSING: "/api/discovery-info",
    GET_COUNT_CAMERA:"/api/cameras/count",
    SET_STATE_SCAN:"api/discovery-info/set-state",

    GET_DRAW_POLYGON: "/api/address/inside-polygon",
    GET_PREVIEW_WITH_ID: "/api/camera/start-stream",
    STOP_STREAM: "/api/camera/stop-stream/all",
    GET_DATA_CAMERA_GROUP: "/api/device/inside-circle",
    SCAN_CAMERA_SINGLE: "/api/cameras/discovery/single",
  },
  AI: {
    GET_AI_FLOW: "/api/aiflows",
    CREATE_AI_FLOW: "/api/aiflows",
    DELETE_AI_FLOW: "/api/aiflows",
    GET_POLYGON: "/api/polygons",
    CREATE_POLYGON: "/api/polygons",
    DELETE_POLYGON: "/api/polygons",
    ADD_POLYGON_TO_AI_FLOW: "/api/aiflows/add-polygons",
    ADD_AI_FLOW_TO_CAMERA: "/api/cameras/add-aiflows",
    GET_BY_CAMERA_AND_TYPE: "/api/aiflows/get-by-camera-and-type",
    UPDATE_POLYGON: "/api/polygons",
    APPLY_AI_FLOW: "/api/cameras/apply-ai-flow",
  },
  SETTING: {
    GET_ALL_JOB: "/api/discovery-info/schedule",
    CREATE_JOBS: "/api/discovery-info",
    DELETE_JOBS: "/api/discovery-info",
    UPDATE_JOBS: "/api/discovery-info",
  },
  PLAYBACK:{
    GET_RECORD:"/api/cameragroups/nvr/record",
    GET_VIDEO_RECORD:"/api/stream/record-nvr"
  }
};

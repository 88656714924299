import colors from "@constants/colors";
import { handleAddTabs } from "@redux/slice/tabs-slice";
import { useEffect, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { BsCalendar2EventFill } from "react-icons/bs";
import { FaCamera } from "react-icons/fa";
import { FaEarthAmericas, FaUserGear } from "react-icons/fa6";
import { GiBrain, GiFilmProjector } from "react-icons/gi";
import { GrStorage } from "react-icons/gr";
import { ImSphere } from "react-icons/im";
import { RiAlarmWarningFill, RiLiveFill } from "react-icons/ri";
import { useDispatch } from "react-redux";

export function convertStringToArrayNumber(inputString) {
  const stringArray = inputString.split(",");
  return stringArray.map((str) => parseFloat(str));
}

export function convertStringToArrayString(inputString) {
  const stringArray = inputString.split(",");
  return stringArray.map((str) => str);
}

export const noData = <center>-</center>;

export function urlKeyPoint(filter) {
  let urlstring = "";
  for (const key in filter) {
    if (
      // key === keyPoint
      key === "distinct" ||
      key === "detail_less" ||
      key === "exact" ||
      key === "ids"
    ) {
      // console.log("khong filter")
    } else {
      const value = filter[key];
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        // console.log("value is null or an empty array", key);
      } else {
        urlstring += `${key}=${value}&`;
      }
    }
  }
  return urlstring;
}

const CustomItem = ({ children, label, id }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="flex space-x-3"
      onClick={() => {
        dispatch(
          handleAddTabs({
            key: id,
            path: id,
            label: label,
            icon: children,
          })
        );
      }}
    >
      {children}
      <p
        style={{
          color: colors.textBase,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export const listTabs = [
  {
    key: "live",
    icon: (
      <RiLiveFill className="text-[100px]" style={{ color: colors.textBase }} />
    ),
    iconTab: (
      <RiLiveFill className="text-[22px]" style={{ color: colors.textBase }} />
    ),
    title: "xem trước",
    sub: "Xem video trực tiếp",
  },
  {
    key: "map",
    icon: (
      <FaEarthAmericas
        className="text-[100px]"
        style={{ color: colors.textBase }}
      />
    ),
    iconTab: (
      <FaEarthAmericas
        className="text-[22px]"
        style={{ color: colors.textBase }}
      />
    ),
    title: "BẢN ĐỒ",
    sub: "Vị trí camera trên hệ thống bản đồ",
  },
  {
    key: "ai",
    icon: (
      <GiBrain className="text-[100px]" style={{ color: colors.textBase }} />
    ),
    iconTab: (
      <GiBrain className="text-[22px]" style={{ color: colors.textBase }} />
    ),
    title: "AI",
    sub: "Truy vấn video AI",
  },
  {
    key: "warning",
    icon: (
      <RiAlarmWarningFill
        className="text-[100px]"
        style={{ color: colors.textBase }}
      />
    ),
    iconTab: (
      <RiAlarmWarningFill
        className="text-[22px]"
        style={{ color: colors.textBase }}
      />
    ),
    title: "Cảnh báo",
    sub: "Tìm kiếm thông tin sự kiện,cấu hình cảnh báo",
  },
  {
    key: "playback",
    icon: (
      <GiFilmProjector
        className="text-[100px]"
        style={{ color: colors.textBase }}
      />
    ),
    iconTab: (
      <GiFilmProjector
        className="text-[22px]"
        style={{ color: colors.textBase }}
      />
    ),
    title: "Xem lại",
    sub: "Truy vấn video và phát lại",
  },
  {
    key: "event",
    icon: (
      <BsCalendar2EventFill
        className="text-[100px]"
        style={{ color: colors.textBase }}
      />
    ),
    iconTab: (
      <BsCalendar2EventFill
        className="text-[22px]"
        style={{ color: colors.textBase }}
      />
    ),
    title: "Sự kiện",
    sub: "Xem thông tin sự kiện và xuất báo cáo",
  },
  {
    label: (
      <CustomItem label="Camera" id="camera">
        <FaCamera
          className="text-[20px] "
          style={{
            color: colors.textBase,
          }}
        />
      </CustomItem>
    ),
    title: "camera",
    key: "camera",
    iconTab: (
      <FaCamera className="text-[22px]" style={{ color: colors.textBase }} />
    ),
  },
  {
    label: (
      <CustomItem label="Tài Khoản" id="user">
        <FaUserGear
          className="text-[20px]"
          style={{
            color: colors.textBase,
          }}
        />
      </CustomItem>
    ),
    key: "user",
    iconTab: (
      <FaUserGear className="text-[22px]" style={{ color: colors.textBase }} />
    ),
    title: "user",
  },
  {
    label: (
      <CustomItem label="Mạng Lưới" id="net">
        <ImSphere
          className="text-[20px]"
          style={{
            color: colors.textBase,
          }}
        />
      </CustomItem>
    ),
    key: "net",
    iconTab: (
      <ImSphere className="text-[22px]" style={{ color: colors.textBase }} />
    ),
    title: "net",
  },
  {
    label: (
      <CustomItem label="Lưu Trữ" id="storage">
        <GrStorage
          className="text-[20px]"
          style={{
            color: colors.textBase,
          }}
        />
      </CustomItem>
    ),
    key: "storage",
    iconTab: (
      <GrStorage className="text-[22px]" style={{ color: colors.textBase }} />
    ),
    title: "storage",
  },
  {
    label: (
      <CustomItem label="Hệ thống" id="system">
        <AiFillSetting
          className="text-[20px]"
          style={{
            color: colors.textBase,
          }}
        />
      </CustomItem>
    ),
    key: "system",
    iconTab: (
      <AiFillSetting
        className="text-[22px]"
        style={{ color: colors.textBase }}
      />
    ),
    title: "system",
  },
];

export  function findMax(arr) {
  if (arr.length === 0) {
    throw new Error("Array is empty");
  }

  return arr.reduce((max, num) => (num > max ? num : max), arr[0]);
}
export function findMin(arr) {
  if (arr.length === 0) {
    throw new Error("Array is empty");
  }

  return arr.reduce((min, num) => (num < min ? num : min), arr[0]);
}
export const ScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
  };
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", updateScreenHeight);
    window.removeEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
      window.addEventListener("resize", updateScreenWidth);
    };
  }, []);
  return {screenHeight, screenWidth};
};

export * from "./canvas";

export function functionChangeGrid(layout){
  const ranges = [
    { min: 0, max: 1, row: 1, column: 1 },
    { min: 2, max: 4, row: 2, column: 2 },
    { min: 5, max: 9, row: 3, column: 3 },
    { min: 10, max: 16, row: 4, column: 4 },
    { min: 17, max: 25, row: 5, column: 5 },
    { min: 26, max: 36, row: 6, column: 6 },
    { min: 37, max: 49, row: 7, column: 7 },
    { min: 50, max: 64, row: 8, column: 8 },
    { min: 65, max: 81, row: 9, column: 9 },
    { min: 82, max: 100, row: 10, column: 10 },
  ];

  for (const range of ranges) {
    if (layout >= range.min && layout <= range.max) {
      return { row: range.row, column: range.column };
    }
  }
  return { row: 1, column: 1 };
}
export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};

export const minMax = (points) => {
  return points.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};


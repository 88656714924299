import colors from "@constants/colors";
import { ConfigProvider, Form, Input, Select, Typography } from "antd";
import CustomTable from "@common/table/custom-table";
import { useEffect, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import icon_dg from "@assets/Dropdown.svg";
import human_icon from "@assets/Human.svg";
import vehicle_icon from "@assets/Vehicle.svg";
import { useLocation, useNavigate } from "react-router";
import PATH from "@constants/path";
import {
  handleGetDataGroup,
  handleListCameraGroup,
  selectDataFill,
  selectDataGroup,
} from "@redux/slice/edit-group-slice";
const ViewCamera = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataGroup = useSelector(selectDataGroup);
  const dataFill = useSelector(selectDataFill);
  const location = useLocation();
  const { groupID } = location.state || null;
  const [form] = Form.useForm();
  const data = [];
  const [dataEdit, setDataEdit] = useState([]);
  const [isNVR, setisNVR] = useState(false);

  const loadDataGroup = () => {
    const callBack = (data) => {
      dispatch(handleGetDataGroup(data));
      setDataEdit(data);
      if (data[0].type === "NVR") {
        setisNVR(true);
      }
    };
    dispatch({
      type: TYPE_ACTION.GROUP.GET_LIST_GROUP,
      payload: { body: { ids: groupID, detail: true }, callBack: callBack },
    });
  };
  useEffect(() => {
    loadDataGroup();
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      quantity: dataFill.cameraIds?.length,
      name: dataFill?.name,
      detail: dataFill?.description,
      type: dataFill?.type,
      lat: dataFill?.lat,
      long: dataFill?.long,
      status: dataFill?.activate ? "Hoạt động" : "Không hoạt động",
      address: dataFill?.address,
      nation: dataFill?.nation,
      province: dataFill?.province,
      district: dataFill?.district,
      ward: dataFill?.ward,
    });
  }, [dataGroup]);

  const handleBack = () => {
    navigate(-1);
  };
  const handleEdit = () => {
    dispatch(handleGetDataGroup(dataEdit));
    navigate(`/${PATH.MAP}/${PATH.CAMERA_GROUP}/edit/${groupID}`, {
      state: {
        groupID: groupID,
        lisAdd: dataGroup,
      },
    });
    dispatch(handleListCameraGroup(dataEdit));
  };
  const onFinish = () => {};
  const onFinishFailed = () => {};
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
    },
    {
      title: "Tên camera",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "addressIp",
      key: "addressIp",
      width: 180,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Bài toán AI",
      dataIndex: "ai",
      key: "ai",
      width: 160,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 160,
    },
  ];
  const handleColorStatus = (status) => {
    if (status === "CONNECTED") {
      return "#006400";
    } else if (status === "DISCONNECTED") {
      return "#706F80";
    } else if (status === "CONNECTING") {
      return "#3A5EF6";
    }
  };
  const handleStatus = (status) => {
    if (status === "CONNECTED") {
      return "Hoạt động";
    } else if (status === "DISCONNECTED") {
      return "Mất kết nối";
    } else if (status === "CONNECTING") {
      return "Kết nôi";
    }
  };
  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(dataGroup) &&
      dataGroup.map((dataIndex, index) => {
        data.push({
          key: index,
          stt: index + 1,
          id: dataIndex.id,
          name: dataIndex.name,
          company: dataIndex.company,
          address: <div className="truncate">{dataIndex?.address}</div>,
          status: (
            <div>
              <CustomButtonCommon
                backgroundColor={handleColorStatus(dataIndex.status)}
                text={handleStatus(dataIndex.status)}
                border="none"
              ></CustomButtonCommon>
            </div>
          ),
          addressIp: dataIndex.ipAddress,
          ai: (
            <div className="flex justify-center items-center gap-[12px]">
              <img className="cursor-pointer" src={human_icon} alt="human" />
              <img
                className="cursor-pointer"
                src={vehicle_icon}
                alt="vehicle"
              />
            </div>
          ),
        });
      });
  }
  return (
    <div className=" w-full h-full">
      <div className="h-full w-full space-y-[12px] p-2">
        <div className="flex justify-between bg-[#181824] p-[20px]">
          <div className="flex">
            <img
              color="#9D9D9D"
              className="cursor-pointer max-h-[35px]"
              src={icon_dg}
              alt="dropdown"
              onClick={() => {
                handleBack();
              }}
            />
            <CustomButtonCommon
              text={<span className="font-bold p-0">Quay lại</span>}
              backgroundColor="#181824"
              textColor="#5B5B9F"
              border="none"
              onClick={() => {
                handleBack();
              }}
            />
            <CustomButtonCommon
              text={dataFill.name}
              backgroundColor={"#FDEBF0"}
              textColor="#5B5B9F"
              border="none"
              height="33px"
              className="cursor-default"
            />
          </div>
          <CustomButtonCommon
            text="Sửa"
            width="101px"
            backgroundColor={colors.redGlobal}
            onClick={() => {
              handleEdit();
            }}
          />
        </div>
        <div className="bg-[#181824] p-[20px]">
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="grid grid-cols-4 gap-[12px]">
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 4,
                    colorBgBase: "#181824",
                    controlHeight: 40,
                    controlOutlineWidth: 0,
                    colorPrimary: colors.bg_new,
                    colorBgElevated: colors.background,
                    colorText: colors.textBase,
                  },
                }}
              >
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Tên nhóm</span>
                  </Typography.Title>
                  <Form.Item name="name" className="">
                    <Input readOnly className="input-border-bottom" />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Số lượng</span>
                  </Typography.Title>
                  <Form.Item name="quantity">
                    <Input readOnly className="input-border-bottom" />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Trạng thái</span>
                  </Typography.Title>
                  <Form.Item name="status">
                    <Input readOnly className="input-border-bottom" />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Mô tả</span>
                  </Typography.Title>
                  <Form.Item name="detail">
                    <Input readOnly className="input-border-bottom" />
                  </Form.Item>
                </div>

                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Phân loại</span>
                    </Typography.Title>
                    <Form.Item name="type">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}

                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Kinh độ</span>
                    </Typography.Title>
                    <Form.Item name="lat">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Vĩ độ</span>
                    </Typography.Title>
                    <Form.Item name="long">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Vị trí</span>
                    </Typography.Title>
                    <Form.Item name="address">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}

                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Quốc gia</span>
                    </Typography.Title>
                    <Form.Item name="nation">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Tỉnh/ Thành phố</span>
                    </Typography.Title>
                    <Form.Item name="province">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Quận/Huyện</span>
                    </Typography.Title>
                    <Form.Item name="district">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
                {isNVR && (
                  <div>
                    <Typography.Title level={5}>
                      <span style={{ color: "#706F80" }}>Phường/Xã</span>
                    </Typography.Title>
                    <Form.Item name="ward">
                      <Input readOnly className="input-border-bottom" />
                    </Form.Item>
                  </div>
                )}
              </ConfigProvider>
            </div>
          </Form>
        </div>
        <div className="h-[12px] bg-[#181824]">
          <div className="bg-[#181824] p-[20px]">
            <span style={{ color: colors.textBase, fontWeight: 600 }}>
              DANH SÁCH CAMERA
            </span>
            <CustomTable
              data={data}
              columns={columns}
              showTotal={false}
              pagination={true}
              showCheckBox={false}
              bordered={false}
              heightPart={680}
              handleRowClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewCamera;

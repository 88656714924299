import { FaHome } from "react-icons/fa";
import { useState } from "react";
import { format } from "date-fns";
import { vi } from "date-fns/locale";
import colors from "@constants/colors";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
const ActionRight = () => {
  let time = new Date().toLocaleTimeString();
  const navigate = useNavigate()
  const [ctime, setTime] = useState(time);
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);
  const formattedDate = format(new Date(), "EEEE, dd-MM-yyyy", { locale: vi });
  return (
    <div className="absolute right-[16px] top-[-12px] flex items-center space-x-3">
      <p>{formattedDate}</p>
      <div className="w-[90px] flex justify-center">
        <p>{ctime}</p>
      </div>
      <LogoutOutlined className="cursor-pointer" onClick={()=>navigate(`${PATH.LOGIN}`)}/>
    </div>
  );
};
export default ActionRight;

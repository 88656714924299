import Search from "@common/search/custom-search";
import { MdDelete } from "react-icons/md";
import { FaFileExport } from "react-icons/fa6";
import { IoMdRefreshCircle, IoIosCreate } from "react-icons/io";
import { BiSolidFileImport } from "react-icons/bi";
import colors from "@constants/colors";
import CustomSelect from "@common/select/custom-select";
import iconLive from "@assets/live.svg";
import iconCreateGroup from "@assets/create_group.svg";
import iconScan from "@assets/scan.svg";
import iconDelete from "@assets/trash.svg";
import iconReload from "@assets/reload.svg";
import { useEffect, useState } from "react";
const CustomHeaderDevice = ({isSelectTable,handleScan,handleReload,handleLive,handleCreateGroup,handleDelete}) => {
  const [listAction, setListAction] = useState([
    {
      id: "live",
      name: "Xem trực tiếp",
      icon: <img src={iconLive} alt="live" />,
      action: false
    },
    {
      id: "create_group",
      name: "Tạo nhóm camera",
      icon: <img src={iconCreateGroup} alt="live" />,
      action: false
    },
    {
      id: "delete",
      name: "Xoá",
      icon: <img src={iconDelete} alt="live" />,
      action: false
    },
    {
      id: "scan",
      name: "Quét",
      icon: <img src={iconScan} alt="live" />,
      action: true
    },
    {
      id: "reload",
      name: "Làm mới",
      icon: <img src={iconReload} alt="live" />,
      action: true
    },
  ]);
  
  useEffect(() => {
    setListAction(prevActions =>
      prevActions.map(action => ({
        ...action,
        action: action.id === "reload" ? true : isSelectTable || action.id === "scan",
      }))
    );
  }, [isSelectTable]);

  const handleActionClick = (id) => {
    switch (id) {
      case "live":
        handleLive()
        break;
      case "create_group":
        handleCreateGroup();
        break;
      case "scan":
        handleScan();
        break;
      case "delete":
        handleDelete()
        break;
      case "reload":
        handleReload()
        break;
      default:
        return;
    }
  };

  return (
    <div className="p-[20px] w-full flex items-start justify-between">
      <p
        className="font-semibold"
        style={{ color: colors.textBase, fontSize: "16px" }}
      >
        DANH SÁCH CAMERA
      </p>
      <div className="flex items-center justify-center space-x-[16px]">
        {listAction.map((item) => (
          <div
            key={item.id}
            onClick={() => handleActionClick(item.id)}
            className="cursor-pointer flex items-center px-[16px] py-[8px] rounded space-x-[14px]"
            style={{
              background:!item.action ? "#444459" : colors.redGlobal,
            }}
          >
            {item.icon}
            <span className="text-[14px]" style={{ color: colors.textBase }}>
              {item.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomHeaderDevice;

import TYPE_ACTION from "@constants/action";
import colors from "@constants/colors";
import PATH from "@constants/path";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import bg_logo from "@assets/bg_login.png";
import logo_img from "@assets/logo_halk.svg";
import logo_text from "@assets/logo_text.svg";
import { Button, ConfigProvider, Form, Input } from "antd";
import { images } from "@common/images/image";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = (values) => {
    navigate("/" + PATH.MAP + "/" + PATH.MAP_DETAIL);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="flex w-full h-screen overflow-hidden">
      <div class="w-[70%]">
        <img src={bg_logo} alt="bg-cygate" class="w-full h-full" />
      </div>
      <div
        className="h-full min-w-[518px] w-[30%] bg-cover flex justify-center items-center flex-col space-y-[24px]"
        style={{ background: colors.formBGLogin }}
      >
        <img src={logo_img} alt="logo_img" className="h-[150px]"/>
        <p className="uppercase text-[#F7F0F7] text-[20px] font-bold">
          Đăng nhập
        </p>
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-full px-[82px]"
        >
          <Form.Item
            label="Tài khoản"
            name="username"
            className="text-white"
            rules={[
              {
                required: true,
                message: "vui lòng nhập tài khoản",
              },
            ]}
          >
            <Input className="h-[54px]" placeholder="Nhập tài khoản"/>
          </Form.Item>

          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[
              {
                required: true,
                message: "vui lòng nhập mật khẩu",
              },
            ]}
          >
            <Input.Password className="h-[54px]" placeholder="Nhập mật khẩu"/>
          </Form.Item>

          <ConfigProvider
            theme={{
              token:{
                colorPrimary:colors.redGlobal
              },
            }}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full h-[54px] mt-[24px]"
                style={{
                  background:colors.redGlobal
                }}
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </ConfigProvider>
        </Form>
      </div>
    </div>
  );
};
export default Login;

const colors = {
  background: "#D9D9D9",
  textBase: "#F7F0F7",
  text_active: "white",
  plahacoder: "rgba(255, 255, 255, 0.4)",
  background_event: "#212228",
  backgroundSide: "#323539",
  text_color_dark: "#5B5B9F",
  background_header: "#767676",
  borderColor: "#444459",
  border_color_side: "rgba(204, 204, 204, 0.2)",
  background_tag: "#101016",
  redGlobal: "#5B5B9F",
  bg_chart: "#2B2F33",
  bg_input: "#181824",
  bg_new: "#2B2A3A",
  bg_disable: "#8D9DB1",
  bg_color: "rgb(33, 34, 40)",
  background_active: "rgba(151, 71, 255, 1)",
  background_disable: "rgba(92, 104, 127, 1)",
  background_form: "rgba(70, 72, 82, 1)",
  bgShape: "rgba(119, 119, 255, 1)",
  colorLoading: "#1CD1A1",
  formBGLogin: "#2B2A3A",
};
export default colors;

export const sizes = {
  heightInput: 40,
  sizeButton: "100px",
};

import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, Input } from "antd";
import colors from "@constants/colors";
import _ from "lodash";

const Search = ({
  onChange,
  options,
  onSelect,
  color = colors.bg_new,
  width = "100%",
  className = "",
  icon,
  placeholder = "Tìm kiếm",
}) => (
  <ConfigProvider
    theme={{
      token: {
        borderRadius: 4,
        colorBgContainer: "transparent",
        colorBorder: colors.borderColor,
        controlHeight: 32,
        controlOutlineWidth: 0,
        colorPrimary: colors.bg_new,
        colorBorderBg: "red",
        colorBorderSecondary: "red",
        colorPrimaryBorder: "red",
        colorBgElevated: colors.background,
        colorText: colors.textBase,
        colorTextPlaceholder: colors.textPlaceholder,
      },
    }}
  >
    <Input
      size="large"
      onChange={onChange}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
    />
  </ConfigProvider>
);
export default Search;

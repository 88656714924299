import colors from "@constants/colors";
import { ConfigProvider, Form, Input, Select, Typography } from "antd";
import CustomTable from "@common/table/custom-table";
import { useEffect, useRef, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import icon_dg from "@assets/Dropdown.svg";
import button_add from "@assets/Button.svg";
import delete_icon from "@assets/Delete.svg";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import {
  handleDeleteCameraGroup,
  handleReset,
  selectListAddCamera,
} from "@redux/slice/edit-group-slice";
import human_icon from "@assets/Human.svg";
import vehicle_icon from "@assets/Vehicle.svg";
const AddGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listCamera = useSelector(selectListAddCamera);
  const [listCameraCopy, setListCameraCopy] = useState(listCamera);
  const [form] = Form.useForm();
  const data = [];
  const filterDataRef = useRef({
    name: null,
    active: true,
    description: null,
  });
  useEffect(() => {
    form.setFieldsValue({
      quantity: listCameraCopy.length,
      status: "on",
    });
  }, [listCameraCopy]);
  const handleDelete = (value) => {
    dispatch(
      handleDeleteCameraGroup({ listCamera: listCamera, idRemove: value.id })
    );
    setListCameraCopy((prevList) =>
      prevList.filter((item) => item.id !== value.id)
    );
  };
  const handleBack = () => {
    navigate(-1);
    dispatch(handleReset());
  };
  const handleAddCamera = () => {
    navigate("/" + PATH.MAP + "/" + PATH.ADD_CAMERA);
  };
  const handleChange = (value) => {
    filterDataRef.current.active = value;
  };
  const onFinish = (value) => {
    const callBack = () => {
      navigate("/" + PATH.MAP + "/" + PATH.CAMERA_GROUP);
      dispatch(handleReset());
    };
    const values = {
      name: value.name,
      cameraIds: listCamera.map((item) => item.id),
      description: value.detail,
      activate: JSON.stringify(filterDataRef.current.active),
    };
    dispatch({
      type: TYPE_ACTION.GROUP.CREATE_GROUP,
      payload: { body: values, callBack },
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
    },
    {
      title: "Tên camera",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "addressIp",
      key: "addressIp",
      width: 180,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Bài toán AI",
      dataIndex: "ai",
      key: "ai",
      width: 160,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 160,
    },
    {
      title: "Tác vụ",
      dataIndex: "action",
      key: "action",
      width: 120,
    },
  ];
  const handleColorStatus = (status) => {
    if (status === "CONNECTED") {
      return "#006400";
    } else if (status === "DISCONNECTED") {
      return "#706F80";
    } else if (status === "CONNECTING") {
      return "#3A5EF6";
    }
  };
  const handleStatus = (status) => {
    if (status === "CONNECTED") {
      return "Hoạt động";
    } else if (status === "DISCONNECTED") {
      return "Mất kết nối";
    } else if (status === "CONNECTING") {
      return "Kết nôi";
    }
  };
  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(listCameraCopy) &&
      listCameraCopy.map((dataIndex, index) => {
        data.push({
          key: index,
          stt: index + 1,
          id: dataIndex.id,
          name: <div className="truncate">{dataIndex.name}</div>,
          address: <div className="truncate">{dataIndex.address}</div>,
          status: (
            <div>
              <CustomButtonCommon
                backgroundColor={handleColorStatus(dataIndex.status)}
                text={handleStatus(dataIndex.status)}
                border="none"
              ></CustomButtonCommon>
            </div>
          ),
          addressIp: dataIndex.ipAddress,
          action: (
            <div className="flex justify-center items-center gap-[20px]">
              <img
                className="cursor-pointer"
                src={delete_icon}
                onClick={() => handleDelete(dataIndex)}
                alt="delete"
              />
            </div>
          ),
          ai: (
            <div className="flex justify-center items-center gap-[12px]">
              <img src={human_icon} alt="human" />
              <img src={vehicle_icon} alt="vehicle" />
            </div>
          ),
        });
      });
  }
  return (
    <div className=" w-full h-full">
      <div className="h-full w-full space-y-[12px] p-2">
        <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="flex justify-between bg-[#181824] p-[20px]">
            <div className="flex">
              <img
                color="#9D9D9D"
                className="cursor-pointer"
                src={icon_dg}
                alt="dropdown"
                onClick={() => {
                  handleBack();
                }}
              />
              <span
                className="font-bold bg-[#181824] text-[#5B5B9F] cursor-pointer"
                onClick={handleBack}
              >
                Quay lại
              </span>
              <CustomButtonCommon
                text="Tạo nhóm camera"
                backgroundColor={"#FDEBF0"}
                textColor="#5B5B9F"
                border="none"
                height="33px"
              />
            </div>
            <div className="flex gap-[16px]">
              <CustomButtonCommon
                text="Hủy"
                width="101px"
                backgroundColor="#434343"
                border="none"
                onClick={() => {
                  handleBack();
                }}
              />
              <Form.Item>
                <CustomButtonCommon
                  text="Lưu"
                  width="101px"
                  backgroundColor={colors.redGlobal}
                  htmlType="submit"
                />
              </Form.Item>
            </div>
          </div>
          <div className="bg-[#181824] p-[20px]">
            <div className="grid grid-cols-4 gap-[12px]">
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 4,
                    colorBgBase: "#181824",
                    controlHeight: 40,
                    controlOutlineWidth: 0,
                    colorText: colors.textBase,
                    colorTextPlaceholder: "#706F80",
                    colorBorder: colors.bg_new,
                    controlItemBgActive: "#5B5B9F",
                  },
                }}
              >
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Tên nhóm *</span>
                  </Typography.Title>
                  <Form.Item
                    name="name"
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Không được bỏ trống trường này",
                      },
                      {
                        max: 50,
                        message: "Tối đa 50 ký tự",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập tên nhóm" />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Số lượng</span>
                  </Typography.Title>
                  <Form.Item name="quantity">
                    <Input readOnly />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Trạng thái</span>
                  </Typography.Title>
                  <Form.Item name="status">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgBase: "#181824",
                        },
                      }}
                    >
                      <Select
                        className="w-full"
                        defaultValue={true}
                        onChange={handleChange}
                        options={[
                          {
                            value: true,
                            label: "Hoạt động",
                          },
                          {
                            value: false,
                            label: "Không hoạt động",
                          },
                        ]}
                      />
                    </ConfigProvider>
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <span style={{ color: "#706F80" }}>Mô tả</span>
                  </Typography.Title>
                  <Form.Item
                    name="detail"
                    rules={[
                      {
                        max: 100,
                        message: "Tối đa 100 ký tự",
                      },
                    ]}
                  >
                    <Input placeholder="Nhập mô tả" />
                  </Form.Item>
                </div>
              </ConfigProvider>
            </div>
          </div>
        </Form>
        <div className="h-[12px] bg-[#181824]">
          <div className="bg-[#181824] p-[20px]">
            <div className="flex items-center">
              <span
                style={{ color: colors.textBase, fontWeight: 600 }}
                className="pr-6"
              >
                {listCamera.length > 0
                  ? "DANH SÁCH CAMERA ĐÃ CHỌN"
                  : "DANH SÁCH CAMERA"}
              </span>
              <img
                className="cursor-pointer max-h-[36px]"
                src={button_add}
                alt="add"
                width={36}
                onClick={() => {
                  handleAddCamera();
                }}
              />
            </div>
            <CustomTable
              data={data}
              columns={columns}
              showTotal={false}
              pagination={true}
              showCheckBox={false}
              bordered={false}
              heightPart={500}
              handleRowClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddGroup;

import { handleSelectLine } from "@redux/slice/ai-slice";
import { dragBoundFunc, minMax } from "@utils/index";
import React, { useEffect, useRef, useState } from "react";
import { Line, Circle, Group, Transformer } from "react-konva";
import { useDispatch } from "react-redux";
// import { minMax, dragBoundFunc } from "./utils/index";

const DrawPolygon = (props) => {
  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis
  const [selected, setSelected] = useState(false);
  const [lineSelect, setLineSelect] = useState(null);
  const [stage, setStage] = useState();
  const dispatch = useDispatch();

  const circleRef = useRef(null);
  const trRef = useRef();
  const polyRef = useRef();

  //props of drawPolygon to be used when called
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handleMouseOnMdpt,
    handleMouseOffMdpt,
    mdPts,
    predictLine,
    sendPosition,
    bgDraw,
    handlePointDragEnd,
    linePoint,
  } = props;

  //used for the size of the points
  const vertexRadius = 3;

  //handler for when the user hovers over the polygon
  const handleGroupMouseOver = (e) => {
    setSelected(false);
    if (!isFinished) return;
    e.target.getStage().container().style.cursor = "move";
    setStage(e.target.getStage());
  };

  //handler for when the user is not hovering over the polygon
  const handleGroupMouseOut = (e) => {
    // setSelected(false);
    e.target.getStage().container().style.cursor = "default";
  };

  const handleGroupDragStart = (e) => {
    let arrX = points.map((p) => p[0]);
    let arrY = points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };

  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };

  useEffect(() => {
    // if (selected) {
    //   trRef.current.nodes([polyRef.current]);
    //   trRef.current.getLayer().batchDraw();
    // }

    if (polyRef.current) {
      polyRef.current.zIndex(0);
    }

    if (circleRef.current) {
      circleRef.current.zIndex(1);
    }
  }, [selected]);

  const [testPoint, setTestPoint] = useState([]);
  useEffect(() => {
    let arr = [];
    points.map((item) => {
      item.map((point) => {
        arr.push(point);
      });
    });
    setTestPoint(arr);
  }, [points]);

  function convertArray(arr) {
    var result = [];
    for (var i = 0; i < arr.length; i += 2) {
      result.push(arr.slice(i, i + 2));
    }
    return result;
  }

  function handlePolyDrag(e) {
    const absolutePoints = [];

    const points = polyRef.current.points();
    const transform = polyRef.current.getAbsoluteTransform();

    let i = 0;
    while (i < points.length) {
      const point = {
        x: points[i],
        y: points[i + 1],
      };
      absolutePoints.push(transform.point(point));
      i = i + 2;
    }

    const newPoints = [];
    for (let val of absolutePoints) {
      newPoints.push(val.x);
      newPoints.push(val.y);
    }
    sendPosition(convertArray(newPoints));
    setTestPoint(newPoints);
    e.target.position({ x: 0, y: 0 });
    e.target.scale({ x: 1, y: 1 });
    return newPoints;
  }
  const handleClick = (e, index) => {
    if (props.isFinished) {
      e.target.stroke("orange");
      e.target.strokeWidth(5);
      e.target.hitStrokeWidth(12);
      setLineSelect(index);
      dispatch(handleSelectLine(e.target.attrs.points));
    }
  };

  const handleOn = (e, index) => {
    if (props.isFinished) {
      e.target.stroke("orange");
      e.target.strokeWidth(5);
      e.target.hitStrokeWidth(12);
      e.target.getStage().container().style.cursor = "pointer";
    } else {
      setLineSelect(null);
    }
  };

  const handleLeave = (e, index) => {
    if (lineSelect === index) {
      e.target.stroke("orange");
      e.target.strokeWidth(3);
    } else {
      e.target.stroke("transparent");
      e.target.strokeWidth(2);
    }
  };
  return (
    <Group
      onClick={() => {
        setSelected(true);
      }}
      onDbClick={() => {
        setSelected(false);
      }}
    >
      <Line
        points={predictLine}
        stroke={bgDraw}
        strokeWidth={4}
        dash={[5, 7]}
      />

      <Group
        name="polygon"
        draggable={isFinished}
        onDragStart={handleGroupDragStart}
        onDragEnd={handleGroupDragEnd}
        dragBoundFunc={groupDragBound}
        onMouseOver={handleGroupMouseOver}
        onMouseOut={handleGroupMouseOut}
        onClick={() => {
          setSelected(true);
        }}
        onDbClick={() => {
          setSelected(false);
        }}
      >
        <Line
          points={flattenedPoints}
          stroke={bgDraw}
          strokeWidth={2}
          closed={isFinished}
          // fill={bgDraw}
          onDragEnd={handlePolyDrag}
          onTransformEnd={handlePolyDrag}
        />
        {linePoint.map((line, index) => (
          <Line
            key={index}
            points={line}
            stroke={lineSelect === index ? "orange" : "transparent"}
            strokeWidth={5}
            closed={false}
            onClick={(event) => handleClick(event, index)}
            onMouseEnter={(event) => handleOn(event, index)}
            onMouseLeave={(event) => handleLeave(event, index)}
          />
        ))}
        {points.map((point, index) => {
          const x = point[0] - vertexRadius / 2;
          const y = point[1] - vertexRadius / 2;
          const startPointAtr =
            index === 0
              ? {
                  hitStrokeWidth: 12,
                  onMouseOver: handleMouseOverStartPoint,
                  onMouseOut: handleMouseOutStartPoint,
                }
              : null;
          return (
            <Circle
              name="pt"
              key={index}
              x={x}
              y={y}
              radius={vertexRadius}
              fill="white"
              stroke="white"
              strokeWidth={6}
              hitStrokeWidth={20}
              draggable
              onDragMove={handlePointDragMove}
              onDragEnd={handlePointDragEnd}
              onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "grabbing";
              }}
              dragBoundFunc={(pos) =>
                dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)
              }
              {...startPointAtr}
              isConvex={false}
            />
          );
        })}

        {/* if user clicks add midpoint button */}

        {mdPts.map((tempPt, index) => {
          const x = tempPt[0] - vertexRadius / 2;
          const y = tempPt[1] - vertexRadius / 2;
          let startPointAttr = mdPts
            ? {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOnMdpt,
                onMouseOut: handleMouseOffMdpt,
              }
            : null;
          return (
            <Circle
              name="pt"
              key={index}
              x={x}
              y={y}
              radius={vertexRadius}
              fill={bgDraw}
              stroke={bgDraw}
              strokeWidth={2}
              draggable
              onDragMove={handlePointDragMove}
              onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "grabbing";
              }}
              dragBoundFunc={(pos) =>
                dragBoundFunc(
                  stage?.width(),
                  stage?.height(),
                  vertexRadius,
                  pos
                )
              }
              {...startPointAttr}
            />
          );
        })}
      </Group>
      <Line
        closed={isFinished}
        draggable
        fill="transparent"
        ref={polyRef}
        strokeWidth={0}
        points={testPoint}
        onDragEnd={handlePolyDrag}
        onTransformEnd={handlePolyDrag}
      />
    </Group>
  );
};

export default DrawPolygon;

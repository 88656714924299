import HeaderVideo from "@components/live/NX/header-video";
import FLVPlayer from "@components/live/flv-player";
import "@style/style-grid-layout.css";
import { handleActiveCamera, handleChangeLayout, handleUpdateCamera, removeItemById } from "@redux/slice/live-slice";
import { useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useSearchParams } from "react-router-dom";

const CustomViewVideo = ({ height = "100%", width = "100%", item,layout }) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [hover, setHover] = useState();
  let [searchParams] = useSearchParams();
  let tabid = searchParams.get("tabid");
  const handle = useFullScreenHandle();
  const isFull = useRef(false);
  return (
    <>
      <div
        onMouseMove={(e) => {
          setHover(item.id);
        }}
        onMouseLeave={() => {
          setHover(null);
        }}
        onDoubleClick={()=>{
            if(isFull.current){
              handle.exit()
            }
            else{
              handle.enter()
            }
          }}
        onClick={(e) => {
          dispatch(handleActiveCamera(item.id));
        }}
        key={item.id}
        className={
          "flex justify-center cursor-pointer overflow-hidden items-center bg-[#00000033]"
        }
        style={{
          height: height,
          width: width,
          aspectRatio: "16 / 9",
          position: "relative",
        }}
      >
        {hover === item.id && (
          <HeaderVideo
            name={item.name}
            idCamera={item.id}
            removeItemById={() => {
              dispatch({
                type: TYPE_ACTION.LIVE.REMOVE_CAMERA_IN_TAB,
                payload: {
                  id: tabid,
                  ids: [item.id],
                },
              });
              dispatch(removeItemById({ idCamera: item.id, status: "remove" }));
            }}
            item={item}
          />
        )}
        <FullScreen handle={handle} onChange={(e)=>isFull.current = e}>
          {item.url && <FLVPlayer flvUrl={item.url} />}
        </FullScreen>
      </div>
    </>
  );
};

export default CustomViewVideo;

import colors from "@constants/colors";
import { Checkbox, ConfigProvider, Form } from "antd";
import CustomTable from "@common/table/custom-table";
import { useEffect, useRef, useState } from "react";
import TYPE_ACTION from "@constants/action";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import icon_dg from "@assets/Dropdown.svg";
import Search from "@common/search/custom-search";
import { useNavigate } from "react-router";
import human_icon from "@assets/Human.svg";
import vehicle_icon from "@assets/Vehicle.svg";
import {
  handleDeleteCameraGroup,
  handleGetAllCamera,
  selectAllCamera,
  selectListAddCamera,
  selectTotalCamera,
} from "@redux/slice/edit-group-slice";
import {
  selectDataDistrict,
  selectDataProvince,
  selectDataWard,
} from "@redux/slice/map-slice";
import CustomSelect from "@common/select/custom-select";
import {
  handleLoadingTables,
  selectisLoadingTables,
} from "@redux/slice/action-slice";

const AddCamera = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataProvince = useSelector(selectDataProvince);
  const dataDistrict = useSelector(selectDataDistrict);
  const listAllCamera = useSelector(selectAllCamera);
  const listCameraCheck = useSelector(selectListAddCamera);
  const isLoading = useSelector(selectisLoadingTables);
  const total = useSelector(selectTotalCamera);
  const [listCamera, setListCamera] = useState(listCameraCheck);
  const [disableDistrict, setDisableDistrict] = useState(true);
  const dataWard = useSelector(selectDataWard);
  const [disableWard, setDisableWard] = useState(true);
  const data = [];
  const [provinceID, setProvinceID] = useState();
  const [districtID, setDistrictID] = useState();
  const [warID, setWarID] = useState();
  const [form] = Form.useForm();
  const filterDataCameRef = useRef({
    pageNumber: 0,
    pageSize: 10,
    name: null,
    provinceIds: null,
    districtIds: null,
    communeIds: null,
  });
  const loadAllCamera = () => {
    dispatch(handleLoadingTables(true));
    const callBack = (data) => {
      dispatch(handleLoadingTables(false));
      dispatch(handleGetAllCamera(data));
    };
    dispatch({
      type: TYPE_ACTION.GROUP.GET_ALL_CAMERA,
      payload: { body: filterDataCameRef.current, callBack: callBack },
    });
  };
  const loadDataProvince = () => {
    dispatch(handleLoadingTables(true));
    dispatch({
      type: TYPE_ACTION.MAP.GET_PROVINCE,
      payload: { body: {} },
    });
  };
  const loadDataDistrict = (id) => {
    const callback = (data) => {};
    dispatch({
      type: TYPE_ACTION.MAP.GET_DISTRICT,
      payload: { body: { provinceIds: id }, callback },
    });
  };
  const loadDataWard = (id) => {
    const callback = (data) => {};
    dispatch({
      type: TYPE_ACTION.MAP.GET_WARD,
      payload: { body: { districtIds: id }, callback },
    });
  };
  useEffect(() => {
    loadAllCamera();
    loadDataProvince();
  }, []);
  const arrProvince = dataProvince.map((item) => ({
    value: item.id,
    label: item.name,
    center: [item.longitude, item.latitude],
  }));
  const arrDistrict = dataDistrict.map((item) => ({
    value: item.id,
    label: item.name,
    provinId: item.provinceId,
    center: [item.latitude, item.longitude],
  }));
  const arrWard = dataWard.map((item) => ({
    value: item.id,
    label: item.name,
    provinId: item.provinceId,
    center: [item.latitude, item.longitude],
  }));
  const onSelectProvince = async (e, info) => {
    filterDataCameRef.current.provinceIds = e;
    form.setFieldsValue({ city: e, district: null, ward: null });
    filterDataCameRef.current.districtIds = null;
    filterDataCameRef.current.communeIds = null;
    setProvinceID(e);
    await loadDataDistrict(e);
    setDisableDistrict(false);
    loadAllCamera();
  };
  const onSelectDistric = async (e, info) => {
    filterDataCameRef.current.districtIds = e;
    form.setFieldsValue({ district: e, ward: null });
    setDistrictID(e);
    await loadDataWard(e);
    setDisableWard(false);
    loadAllCamera();
  };
  const onSelectWard = async (e, info) => {
    filterDataCameRef.current.communeIds = e;
    form.setFieldsValue({ ward: e });
    setWarID(e);
    loadAllCamera();
  };

  const onCheckBox = (e, value) => {
    setListCamera((prevList) => {
      if (e.target.checked) {
        return [...prevList, value];
      } else {
        return prevList.filter((item) => item.id !== value.id);
      }
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleDataSearch = (e) => {
    filterDataCameRef.current.name = e.target.value;
    loadAllCamera();
  };
  const handleAdd = () => {
    dispatch(
      handleDeleteCameraGroup({
        listCamera: listCamera,
        idRemove: "",
      })
    );
    navigate(-1);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 100,
    },
    {
      title: "Tên camera",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Địa chỉ IP",
      dataIndex: "addressIp",
      key: "addressIp",
      width: 180,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Bài toán AI",
      dataIndex: "ai",
      key: "ai",
      width: 160,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 160,
    },
    {
      title: "Tác vụ",
      dataIndex: "action",
      key: "action",
      width: 120,
    },
  ];
  const getStt = (item) => {
    const { pageNumber, pageSize } = filterDataCameRef.current;
    return item + 1 + pageNumber * pageSize;
  };
  const handleColorStatus = (status) => {
    if (status === "CONNECTED") {
      return "#006400";
    } else if (status === "DISCONNECTED") {
      return "#706F80";
    } else if (status === "CONNECTING") {
      return "#3A5EF6";
    }
  };
  const handleStatus = (status) => {
    if (status === "CONNECTED") {
      return "Hoạt động";
    } else if (status === "DISCONNECTED") {
      return "Mất kết nối";
    } else if (status === "CONNECTING") {
      return "Kết nôi";
    }
  };
  // eslint-disable-next-line no-lone-blocks
  {
    Array.isArray(listAllCamera) &&
      listAllCamera.map((dataIndex, index) => {
        data.push({
          key: index,
          stt: getStt(index),
          id: dataIndex.id,
          name: dataIndex.name,
          address: <div className="truncate">{dataIndex.address}</div>,
          status: (
            <div>
              <CustomButtonCommon
                backgroundColor={handleColorStatus(dataIndex.status)}
                text={handleStatus(dataIndex.status)}
                border="none"
              ></CustomButtonCommon>
            </div>
          ),
          addressIp: (
            <p style={{ color: colors.textBase }}>{dataIndex.ipAddress}</p>
          ),
          action: (
            <div className="flex justify-center items-center">
              <Checkbox
                checked={listCamera
                  .map((item) => item.id)
                  .includes(dataIndex.id)}
                onChange={(e) => {
                  onCheckBox(e, dataIndex);
                }}
              />
            </div>
          ),
          ai: (
            <div className="flex justify-center items-center gap-[12px]">
              <img src={human_icon} alt="human" />
              <img src={vehicle_icon} alt="vehicle" />
            </div>
          ),
        });
      });
  }
  return (
    <Form name="basic" form={form} autoComplete="off">
      <div className=" w-full h-full">
        <div className="h-full w-full space-y-[12px] p-2">
          <div className="flex justify-between bg-[#181824] p-[20px]">
            <div className="flex">
              <img
                color="#9D9D9D"
                className="cursor-pointer"
                src={icon_dg}
                alt="dropdown"
                onClick={() => {
                  handleBack();
                }}
              />
              <span
                className="font-bold bg-[#181824] text-[#5B5B9F] cursor-pointer"
                onClick={handleBack}
              >
                Quay lại
              </span>
            </div>
          </div>
          <div className="bg-[#181824] p-[20px]">
            <div className="grid grid-cols-6 gap-[12px] justify-center items-center">
              <ConfigProvider
                theme={{
                  token: {
                    borderRadius: 4,
                    colorBgBase: "#181824",
                    controlHeight: 40,
                    controlOutlineWidth: 0,
                    colorText: colors.textBase,
                    colorTextPlaceholder: "#706F80",
                    colorBorder: colors.borderColor,
                    controlItemBgActive: "#5B5B9F",
                  },
                }}
              >
                <Form.Item>
                  <span className="text-[#D9D9D9] font-semibold">
                    CHỌN CAMERA VÀO NHÓM
                  </span>
                </Form.Item>
                <div className="col-span-2">
                  <Form.Item>
                    <Search onChange={handleDataSearch} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="city">
                    <CustomSelect
                      lineHeight="0px"
                      font="font-normal"
                      width="100%"
                      title="Tỉnh"
                      placeholder="Chọn tỉnh thành"
                      allowClear={true}
                      onSelect={onSelectProvince}
                      onClear={onSelectProvince}
                      option={arrProvince}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="district">
                    <CustomSelect
                      lineHeight="0px"
                      font="font-normal"
                      width="100%"
                      title="Huyện"
                      disabled={disableDistrict}
                      disabledClass={false}
                      allowClear={true}
                      placeholder="Chọn Quận huyện"
                      onSelect={onSelectDistric}
                      option={arrDistrict}
                      onClear={onSelectDistric}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="ward">
                    <CustomSelect
                      lineHeight="0px"
                      font="font-normal"
                      width="100%"
                      title="Phường"
                      disabled={disableWard}
                      disabledClass={false}
                      allowClear={true}
                      placeholder="Chọn Phường xã"
                      option={arrWard}
                      onSelect={onSelectWard}
                      onClear={onSelectWard}
                      isfilterOption={false}
                      loading={isLoading}
                    />
                  </Form.Item>
                </div>
              </ConfigProvider>
            </div>
          </div>
          <div className="h-[12px] bg-[#181824]">
            <div className="bg-[#181824] p-[20px]">
              <span style={{ color: colors.textBase, fontWeight: 600 }}>
                Đã chọn:
                {
                  <span className="text-[#5B5B9F] font-semibold pl-2">
                    {listCamera.length} Camera
                  </span>
                }
              </span>
              <CustomTable
                data={data}
                columns={columns}
                showTotal={true}
                total={total}
                loading={isLoading}
                pagination={true}
                showCheckBox={false}
                heightPart={500}
                bordered={false}
                handleRowClick={() => {}}
                onChange={(page, size) => {
                  const filters = {
                    provinceIds: provinceID,
                    districtIds: districtID,
                    communeIds: warID,
                    pageSize: size,
                  };
                  Object.keys(filters).forEach((key) => {
                    if (filters[key]) {
                      filterDataCameRef.current[key] = filters[key];
                    }
                  });
                  filterDataCameRef.current.pageNumber = page - 1;
                  loadAllCamera();
                  setListCamera(listCameraCheck);
                }}
              />
              <div className="flex justify-end gap-[16px] pt-[20px]">
                <CustomButtonCommon
                  text="Hủy"
                  width="101px"
                  backgroundColor="#434343"
                  border="none"
                  onClick={() => {
                    handleBack();
                  }}
                />
                <CustomButtonCommon
                  text="Thêm"
                  width="101px"
                  backgroundColor={colors.redGlobal}
                  onClick={() => {
                    handleAdd();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default AddCamera;


import { SERVICE_PATHS } from "@constants/url";
import { get, get_stream, post } from "src/api/api-map";

const MapService = {
  getDataLocationCamera: (body) => {
    let filterString = "?";
    Object.keys(body).forEach((index) => {
      const value = body[index];
  
      if (Array.isArray(value) && value.length > 0) {
        // Nếu giá trị là mảng và không rỗng, tạo các cặp `key=value` cho từng phần tử
        value.forEach((item) => {
          filterString += `${filterString === "?" ? "" : "&"}${index}=${item}`;
        });
      } else if (value && !Array.isArray(value)) {
        // Nếu giá trị không phải mảng và khác null/undefined/false, xử lý như bình thường
        filterString += `${filterString === "?" ? "" : "&"}${index}=${value}`;
      }
    });
    
    return get(`${SERVICE_PATHS.MAP.GET_LOCATION}${filterString}&detail=false`);
},
  scanCameraSingle: (body) => {
    return post(SERVICE_PATHS.MAP.SCAN_CAMERA_SINGLE,body);
  },
  getDataProvince:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_PROVINCE}${filterString}`)
  },
  getDataDistrict:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_DISTRICT}${filterString}`)    
  },
  getDataWard:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_WARD}${filterString}`);
  },

  getSearchCamera:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_CAMERA}${filterString}`);
  },
  getDataDrawCircle:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_DRAW_CIRCLE}${filterString}`);
  },
  getDataLocationCameraRec: (body) => {
    return get(`${SERVICE_PATHS.MAP.GET_LOCATION}${body}&detail=false`);
  },
  getTotalCamera: (body) => {
    return get(`${SERVICE_PATHS.MAP.GET_CAMERA_COUNT}`);
  },
  getProcessing: (body) => {
    return get(`${SERVICE_PATHS.MAP.GET_PROCESSING}`);
  },
  setStateScanning: (body) => {
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return post(`${SERVICE_PATHS.MAP.SET_STATE_SCAN}${filterString}`);
  },
  //

  
  
  getDataRecorder: (body) => {
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.MAP.GET_RECORDER);
    } else {
      return get(`${SERVICE_PATHS.MAP.GET_RECORDER}${filterString}`);
    }
  },
  getDataAddress:(body)=>{
    return get(SERVICE_PATHS.MAP.GET_ADDRESS)
  },
  
  
  getDataWard:(body)=>{
    var filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    return get(`${SERVICE_PATHS.MAP.GET_WARD}${filterString}`);
  },
  getTotalCamAll:(body)=>{
    return get(SERVICE_PATHS.MAP.GET_TOTAL)
  },
  getTotalCamAllMap:(body)=>{
    return get(SERVICE_PATHS.MAP.GET_COUNT_CAMERA)
  },
  getDataDrawPolygon:(body)=>{
    return post(SERVICE_PATHS.MAP.GET_DRAW_POLYGON,body)
  },
  getPreviewCameraWithID:(body)=>{
    return get_stream(`${SERVICE_PATHS.MAP.GET_PREVIEW_WITH_ID}?ids=${body}`)
  },
  closeCamera:(body)=>{
    return get_stream(`${SERVICE_PATHS.MAP.STOP_STREAM}`)
  },
  getDataCameraGroup:(body)=>{
    return post(SERVICE_PATHS.MAP.GET_DATA_CAMERA_GROUP,body)
  }
};

export default MapService;

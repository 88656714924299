import { selectHeightVideo, selectWidthVideo } from "@redux/slice/ai-slice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const PolygonSVG = ({ polygons, width, height }) => {
  const dispatch = useDispatch();
  const widthPolygon = useSelector(selectWidthVideo);
  const heightPolygon = useSelector(selectHeightVideo);
  const [sizeWidth, setSizeWidth] = useState(width);
  const [sizeHeight, setSizeHeight] = useState(height);

  useEffect(() => {
    setSizeWidth(widthPolygon);
    setSizeHeight(heightPolygon);
  }, [heightPolygon, widthPolygon]);

  const getPolygonPointsString = (points) =>
    points && points.map(([x, y]) => `${x},${y}`).join(" ");

  const getPolygonCenterX = (points) =>
    points && points.reduce((sum, [x]) => sum + x, 0) / points.length;

  const getPolygonCenterY = (points) =>
    points && points.reduce((sum, [, y]) => sum + y, 0) / points.length;

  const getLineStartX = (points) =>
    points && points.length > 0 ? points[0][0] : 0;
  const getLineStartY = (points) =>
    points && points.length > 0 ? points[0][1] : 0;
  const getLineEndX = (points) =>
    points && points.length > 1 ? points[1][0] : 0;
  const getLineEndY = (points) =>
    points && points.length > 1 ? points[1][1] : 0;

  return (
    <svg width={sizeWidth} height={sizeHeight}>
      {polygons?.map((polygon, index) => (
        <React.Fragment key={index}>
          <polygon
            points={getPolygonPointsString(polygon.points)}
            fill="none"
            stroke={polygon.stroke && polygon.stroke}
            strokeWidth="2"
          />
          {polygon.line && (
            <line
              x1={getLineStartX(polygon.line)}
              y1={getLineStartY(polygon.line)}
              x2={getLineEndX(polygon.line)}
              y2={getLineEndY(polygon.line)}
              stroke="orange"
              strokeWidth="3"
            />
          )}
          <text
            x={getPolygonCenterX(polygon.points)}
            y={getPolygonCenterY(polygon.points)}
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="10"
            fontWeight="600"
            fill={polygon.fontColor && polygon.fontColor}
            className="select-none"
          >
            {polygon.name ? polygon.name.toUpperCase() : ""}
          </text>
        </React.Fragment>
      ))}
    </svg>
  );
};

export default PolygonSVG;

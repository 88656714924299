import { createSlice } from "@reduxjs/toolkit";

export const settingJobSlice = createSlice({
  name: "setting",
  initialState: {
    dataJob: null,
  },
  reducers: {
    handleDataEdit: (state, action) => {
      state.dataJob = action.payload;
    },
    handleReset: (state) => {
      state.dataJob = null;
    },
  },
});

export const { handleDataEdit, handleReset } = settingJobSlice.actions;

export const selectDataEdit = (state) => state.setting.dataJob;

export default settingJobSlice.reducer;

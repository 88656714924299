/* eslint-disable no-loop-func */
import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { ConfigProvider, Input, Popover, Tag, theme, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TYPE_ACTION from "@constants/action";
import { useNavigate } from "react-router";
import PATH from "@constants/path";
import {
  handleChangeLayout,
  handleClearListCameraTab,
  handleUpdateCamera,
  selectColum,
} from "@redux/slice/live-slice";
import { useSearchParams } from "react-router-dom";
import { handleLoadingTab } from "@redux/slice/action-slice";
import iconGrip from "@assets/grid/Grid.svg";
import grid1 from "@assets/grid/g1.svg";
import grid2 from "@assets/grid/g2.svg";
import grid3 from "@assets/grid/g3.svg";
import grid4 from "@assets/grid/g4.svg";
import grid5 from "@assets/grid/g5.svg";
import grid6 from "@assets/grid/g6.svg";
import grid8 from "@assets/grid/g7.svg";
import CustomButtonCommon from "@common/button/custom-button";
import CustomModal from "@common/modal/modal";
import colors from "@constants/colors";
const HeaderTab = () => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const column = useSelector(selectColum);
  const [openDelete, setopenDelete] = useState(false);
  const removedTagRef = useRef();
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const navigate = useNavigate();
  //
  let [searchParams] = useSearchParams();
  let tabid = localStorage.getItem("tabid");

  const selectTab = useRef(tabid);
  const loadTab = () => {
    const callBack = async (data) => {
      const reversedData = data
        .filter((item) => item.type === "live")
        .reverse();
      setTags(reversedData);
      if (tabid) {
        navigate(`/${PATH.MAP}/${PATH.LIVE}?tabid=${tabid}`);
        selectTab.current = tabid;
        loadCameraStream(tabid);
      }
    };

    dispatch({
      type: TYPE_ACTION.LIVE.GET_TAB,
      payload: { ids: null, callBack: callBack },
    });
  };

  const loadCameraStream = (id) => {
    const callBackTab = async (tabData) => {
      if (tabData[0]?.cameraDTOs.length === 0) {
        dispatch(handleLoadingTab(false));
      }
      const cameras = tabData[0]?.cameraDTOs || [];
      const tempSliceCameras = [];

      let row = 0;
      let col = 0;
      let max = 1;
      let lengthCamera = cameras.length;
      if (lengthCamera >= 2 && lengthCamera < 5) {
        max = 2;
      } else if (lengthCamera >= 5 && lengthCamera < 10) {
        max = 3;
      } else if (lengthCamera >= 10 && lengthCamera < 17) {
        max = 4;
      } else if (lengthCamera >= 17 && lengthCamera < 26) {
        max = 5;
      } else if (lengthCamera >= 26 && lengthCamera < 37) {
        max = 6;
      } else if (lengthCamera >= 37 && lengthCamera < 50) {
        max = 7;
      } else if (lengthCamera >= 50 && lengthCamera <= 64) {
        max = 8;
      }
      for (const [index, item] of cameras.entries()) {
        await new Promise((resolve) => {
          const errorBack = () => resolve();

          const callBackGetData = (dataCamera) => {
            dispatch(handleLoadingTab(false));
            const value = {
              w: 1,
              h: 1,
              id: item.id,
              url: dataCamera,
              name: item.name,
              x: row,
              y: col,
              i: item.id,
            };
            const existsInSlice = tempSliceCameras.some(
              (camera) => camera.id === item.id
            );

            if (!existsInSlice) {
              tempSliceCameras.push(value);
            }
            row++;
            if (row >= max) {
              row = 0;
              col++;
            }
            resolve();
          };

          dispatch({
            type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
            payload: {
              url: item.id,
              callBack: callBackGetData,
              errorBack,
            },
          });
        });
      }
      const length = tempSliceCameras.length;
      let rows = 1;
      let columns = 1;

      if (length >= 2 && length < 5) {
        rows = columns = 2;
      } else if (length >= 5 && length < 10) {
        rows = columns = 3;
      } else if (length >= 10 && length < 17) {
        rows = columns = 4;
      } else if (length >= 17 && length < 26) {
        rows = columns = 5;
      } else if (length >= 26 && length < 37) {
        rows = columns = 6;
      } else if (length >= 37 && length < 50) {
        rows = columns = 7;
      } else if (length >= 50 && length <= 64) {
        rows = columns = 8;
      }
      dispatch(
        handleChangeLayout({
          row: rows,
          column: columns,
        })
      );
      dispatch(handleUpdateCamera(tempSliceCameras));
    };

    dispatch({
      type: TYPE_ACTION.LIVE.GET_TAB,
      payload: { ids: id, callBack: callBackTab },
    });
  };
  useEffect(() => {
    loadTab();
  }, []);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);
  const handleClose = (removedTag) => {
    setopenDelete(true);
    removedTagRef.current = removedTag;
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      const callBack = (data) => {
        setTags([...tags, data]);
      };

      dispatch({
        type: TYPE_ACTION.LIVE.CREATE_TAB,
        payload: {
          body: {
            name: inputValue,
            type: "live",
          },
          callBack,
        },
      });
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = (id) => {
    if (editInputValue) {
      const newTags = [...tags];
      newTags[editInputIndex].name = editInputValue;
      dispatch({
        type: TYPE_ACTION.LIVE.UPDATE_TAB,
        payload: { id: id, name: editInputValue, type: "live" },
      });
      setTags(newTags);
      setEditInputIndex(-1);
      setEditInputValue("");
    }
  };
  const handleClickTab = (item) => {
    handleCameraStream(item);
  };
  const handleCameraStream = (item) => {
    dispatch(handleClearListCameraTab());
    selectTab.current = item.id;
    dispatch(handleLoadingTab(true));
    loadCameraStream(item.id);
    navigate(`/${PATH.MAP}/${PATH.LIVE}?tabid=${item.id}`);
    localStorage.setItem("tabid", item.id);
  };
  const listItem = [
    {
      title: 1,
      icon: grid1,
    },
    {
      title: 4,
      icon: grid2,
    },
    {
      title: 9,
      icon: grid3,
    },
    {
      title: 16,
      icon: grid4,
    },
    {
      title: 25,
      icon: grid5,
    },
    {
      title: 36,
      icon: grid6,
    },
    {
      title: 64,
      icon: grid8,
    },
  ];
  const handleChangeGrid = (item) => {
    dispatch(
      handleChangeLayout({
        row: Math.sqrt(item),
        column: Math.sqrt(item),
      })
    );
  };
  const PopUpGrip = () => {
    return (
      <div className="w-[236px] h-[126px] bg-[#2B2A3A] rounded grid grid-cols-4 p-[10px]">
        {listItem.map((item, index) => {
          return (
            <div
              key={index}
              className="w-[40px] h-[50px] cursor-pointer flex flex-col items-center justify-center"
              onClick={() => handleChangeGrid(item.title)}
              style={{
                background: column === Math.sqrt(item.title) ? "#575757" : "",
              }}
            >
              <img alt="icon" src={item.icon} />
              <p className="text-white">{item.title}</p>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="w-full bg-[#181824] h-[80px] flex items-center justify-between pr-10">
      <div className="w-full h-[80px]  flex items-center px-[24px] max-w-[50vw] overflow-x-auto scroll_default ">
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={editInputRef}
                key={index}
                style={{
                  height: "38px",
                  width: "max-content",
                  background: "#2B2A3A",
                  color: "white",
                  border: "none",
                  marginRight: "10px",
                  minWidth: "100px",
                }}
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={() => handleEditInputConfirm(tag.id)}
                onPressEnter={() => handleEditInputConfirm(tag.id)}
              />
            );
          }
          const isLongTag = tag.length > 20;
          const tagElem = (
            <ConfigProvider
              key={index}
              theme={{
                token: {
                  borderRadius: "4px",
                  controlHeight: "38px",
                },
              }}
            >
              <Tag
                key={index}
                closable={true}
                style={{
                  userSelect: "none",
                  background:
                    selectTab.current === tag.id ? "#5B5B9F" : "#2B2A3A",
                  color: "white",
                  borderRadius: "4px",
                  height: "38px",
                  border: "none",
                  // minWidth:"100px",
                  width: "max-content",
                }}
                className="flex items-center cursor-pointer justify-between"
                onClose={() => handleClose(tag)}
                onClick={() => handleClickTab(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    setEditInputIndex(index);
                    setEditInputValue(tag.name);
                    e.preventDefault();
                  }}
                >
                  {tag.name}
                </span>
              </Tag>
            </ConfigProvider>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            style={{
              height: "38px",
              width: "max-content",
              background: "#2B2A3A",
              color: "white",
              border: "none",
              minWidth: "100px",
            }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            className="w-max"
          />
        ) : (
          <Tag
            icon={<PlusOutlined />}
            onClick={showInput}
            style={{
              userSelect: "none",
              background: "#2B2A3A",
              color: "white",
              borderRadius: "4px",
              height: "38px",
              border: "none",
            }}
            className="flex items-center"
          >
            Tab mới
          </Tag>
        )}
      </div>
      <Popover
        content={<PopUpGrip />}
        open={open}
        onOpenChange={handleOpenChange}
        className="bg-transparent"
      >
        <img alt="icon" src={iconGrip} className="cursor-pointer" />
      </Popover>
      <CustomModal
        centered={true}
        open={openDelete}
        width={"460px"}
        handleCancel={() => {
          setopenDelete(false);
        }}
        content={
          <div className="h-[175px] w-full bg-[#2B2A3A]">
            <div className="h-[100px] w-full flex items-center justify-center">
              <p className="font-semibold text-[16px]">
                Bạn có chắc chắn muốn xoá?
              </p>
            </div>
            <div className="h-[75px] w-full space-x-[16px] flex items-center justify-center px-[36px]">
              <div className="w-full">
                <CustomButtonCommon
                  width="100%"
                  height="39px"
                  backgroundColor={colors.redGlobal}
                  onClick={() => {
                    const index = tags.findIndex(
                      (item) => item.id === removedTagRef.current.id
                    );
                    if (
                      tags.length > 1 &&
                      selectTab.current === removedTagRef.current.id
                    ) {
                      if (index > 0) {
                        handleCameraStream(tags[index - 1]);
                      } else {
                        handleCameraStream(tags[1]);
                      }
                    }
                    const newTags = tags.filter(
                      (tag) => tag.id !== removedTagRef.current.id
                    );
                    dispatch({
                      type: TYPE_ACTION.LIVE.DELETE_TAB,
                      payload: { ids: removedTagRef.current.id },
                    });
                    setTags(newTags);
                    setopenDelete(false);
                  }}
                  text={"Xoá"}
                />
              </div>
              <div className="w-full">
                <CustomButtonCommon
                  width="100%"
                  height="39px"
                  onClick={() => {
                    setopenDelete(false);
                  }}
                  text={"Hủy"}
                />
              </div>
            </div>
          </div>
        }
        isHeader={false}
      />
    </div>
  );
};
export default HeaderTab;

import { createSlice } from "@reduxjs/toolkit";

export const deviceGroupSlice = createSlice({
  name: "deviceGroup",
  initialState: {
    listGroup: [],
    dataGroup: [],
    listCameraGroup: [],
    listAddCamera: [],
    listAllCamera: [],
    totalCamera: 0,
    dataFill: [],
    widthPolygon: null,
    heightPolygon: null,
  },
  reducers: {
    handleGetListGroup(state, action) {
      state.listGroup = action.payload;
    },
    handleGetDataGroup(state, action) {
      const listCamera = [];
      const cameraDTOs = action.payload[0]?.cameraDTOs || [];
      cameraDTOs.forEach((item) => {
        const address = item.addressDTO
          ? `${item.addressDTO.communeDTO.name}, ${item.addressDTO.districtDTO.type} ${item.addressDTO.districtDTO.name}, ${item.addressDTO.provinceDTO.type} ${item.addressDTO.provinceDTO.name}`
          : "-";

        listCamera.push({
          id: item.id,
          name: item.name,
          status: item.state,
          ipAddress: item.ipAddress,
          address: address,
        });
      });
      state.dataGroup = listCamera;
      state.dataFill = action.payload[0];
    },
    handleGetAllCamera(state, action) {
      state.totalCamera = action.payload.totalElements;
      const listCamera = [];
      const cameraDTOs = action.payload.content || [];
      cameraDTOs.forEach((item) => {
        const address = item.addressDTO
          ? `${item.addressDTO.communeDTO.name}, ${item.addressDTO.districtDTO.type} ${item.addressDTO.districtDTO.name}, ${item.addressDTO.provinceDTO.type} ${item.addressDTO.provinceDTO.name}`
          : "-";

        listCamera.push({
          id: item.id,
          name: item.name,
          status: item.state,
          ipAddress: item.ipAddress,
          address: address,
        });
      });
      state.listAllCamera = listCamera;
    },
    handleEditGroup: (state, action) => {
      state.listCameraGroup = action.payload;
    },
    handleListCameraGroup: (state, action) => {
      const listCamera = [];
      const cameraDTOs = action.payload[0]?.cameraDTOs || [];
      cameraDTOs.forEach((item) => {
        const address = item.addressDTO
          ? `${item.addressDTO.communeDTO.name}, ${item.addressDTO.districtDTO.type} ${item.addressDTO.districtDTO.name}, ${item.addressDTO.provinceDTO.type} ${item.addressDTO.provinceDTO.name}`
          : "-";

        listCamera.push({
          id: item.id,
          name: item.name,
          status: item.state,
          ipAddress: item.ipAddress,
          address: address,
        });
      });
      state.listAddCamera = listCamera;
    },
    handleAddCamera(state, action) {
      state.listAddCamera.push(action.payload);
    },
    handleDeleteCameraGroup(state, action) {
      const { listCamera, idRemove } = action.payload;
      const updatedList = listCamera.filter((item) => item.id !== idRemove);
      state.listAddCamera = updatedList;
    },
    handleReset(state) {
      state.listGroup = [];
      state.dataGroup = [];
      state.listCameraGroup = [];
      state.listAddCamera = [];
      state.listAllCamera = [];
    },
    handleGetSizeVideo(state, action) {
      state.widthPolygon = action.payload.width;
      state.heightPolygon = action.payload.height;
    },
  },
});

export const {
  handleGetListGroup,
  handleEditGroup,
  handleAddCamera,
  handleDeleteCameraGroup,
  handleGetAllCamera,
  handleReset,
  handleGetDataGroup,
  handleListCameraGroup,
  handleGetSizeVideo,
} = deviceGroupSlice.actions;

export const selectListGroup = (state) => state.deviceGroup.listGroup;
export const selectDataGroup = (state) => state.deviceGroup.dataGroup;
export const selectDataFill = (state) => state.deviceGroup.dataFill;
export const selectAllCamera = (state) => state.deviceGroup.listAllCamera;
export const selectListAddCamera = (state) => state.deviceGroup.listAddCamera;
export const selectTotalCamera = (state) => state.deviceGroup.totalCamera;
export const selectWidth = (state) => state.deviceGroup.widthPolygon;
export const selectHeight = (state) => state.deviceGroup.heightPolygon;

export default deviceGroupSlice.reducer;

/* eslint-disable array-callback-return */
import {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Stage, Layer } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { pointPolygon } from "@redux/slice/live-slice";
import DrawPolygon from "./drawn-polygon";
import convexHull from "convex-hull";
import {
  handleSelectLine,
  selectHeightVideo,
  selectWidthVideo,
} from "@redux/slice/ai-slice";
import { selectEditPolygon } from "@redux/slice/ai-slice";

const DrawnCanvas = forwardRef(
  (
    {
      bgDraw,
      onData,
      idCamera,
      reset,
      viewportWidth,
      viewportHeight,
      pointEdit,
    },
    ref
  ) => {
    const [points, setPoints] = useState([]);
    const [flattenedPoints, setFlattenedPoints] = useState();
    const [position, setPosition] = useState([0, 0]);
    const [isMouseOverPoint, setMouseOverPoint] = useState(false);
    const [isPolyComplete, setPolyComplete] = useState(false);
    const [predictLine, setPredictLine] = useState([]);
    const [flatLinePts, setFlatLinePts] = useState();
    const [mdpts, setMdPts] = useState([]);
    const [isMouseOverMdpt, setMouseOverMdpt] = useState(false);
    const [isAddPts, setAddPts] = useState(false);
    const [isPolygon, setIsPolygon] = useState(false);
    const pointPolygons = useSelector(pointPolygon);
    const refCanvas = useRef(null);
    const [sizeWidth, setSizeWidth] = useState(viewportWidth);
    const [sizeHeight, setSizeHeight] = useState(viewportHeight);
    const widthPolygon = useSelector(selectWidthVideo);
    const heightPolygon = useSelector(selectHeightVideo);
    const isEditPolygon = useSelector(selectEditPolygon);
    const [linePoint, setLinePoint] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
      if (reset) {
        resetPts();
      } else {
        if (pointPolygons && pointPolygons.point) {
          if (pointPolygons.point?.length > 0) {
            setPolyComplete(true);
            setPoints(pointPolygons.point);
          }
        }
      }
    }, [pointPolygons, reset]);
    useEffect(() => {
      if (isEditPolygon?.length > 0) {
        setPoints(isEditPolygon);
        setPolyComplete(true);
      } else {
        setPolyComplete(false);
      }
    }, [isEditPolygon]);

    useEffect(() => {
      if (pointEdit.length > 0) {
        setPoints(pointEdit);
        setPolyComplete(true);
      } else {
        setPolyComplete(false);
      }
    }, []);

    useEffect(() => {
      setSizeWidth(widthPolygon);
      setSizeHeight(heightPolygon);
    }, [heightPolygon, widthPolygon]);

    const sendDataToParent = (data) => {
      onData(points, isPolygon);
    };

    const getMousePos = (stage) => {
      return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    };
    const IsConvexPolygon = (points) => {
      if (points.length > 3) {
        const hull = convexHull(points);
        const isConvex = hull.length === points.length;
        return isConvex;
      }
      return true;
    };
    function isConvex(polygon) {
      let prevSign = 0;

      for (let i = 0; i < polygon.length; i++) {
        const p1 = polygon[i];
        const p2 = polygon[(i + 1) % polygon.length];
        const p3 = polygon[(i + 2) % polygon.length];

        const crossProduct =
          (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p2[1] - p1[1]) * (p3[0] - p1[0]);

        if (crossProduct !== 0) {
          const currentSign = Math.sign(crossProduct);
          if (prevSign === 0) {
            prevSign = currentSign;
          } else if (currentSign !== prevSign) {
            return false; // Đa giác lõm
          }
        }
      }

      return true; // Đa giác lồi
    }
    //handler when the mouse is clicked, adds point/midpt to array
    const handleMouseDown = (e) => {
      // as long as there is no right click and image is not draggable then add point as needed
      if (e.evt.button !== 2) {
        const stage = e.target.getStage();
        const mousePos = getMousePos(stage);

        //add midpoints after polygon is complete
        if (isPolyComplete && isAddPts && isMouseOverMdpt) {
          let ind = -1;

          //find the closest point from the midpoint array
          for (let i = 0; i < mdpts.length; ++i) {
            let p = mdpts[i];
            let xLow = p[0] - 15;
            let xHigh = p[0] + 15;
            let yLow = p[1] - 15;
            let yHigh = p[1] + 15;

            if (
              mousePos[0] >= xLow &&
              mousePos[0] <= xHigh &&
              mousePos[1] >= yLow &&
              mousePos[1] <= yHigh
            ) {
              ind = i;
            }
          }
          points.splice(ind + 1, 0, mdpts[ind]);
          setMdPts([]);
          setAddPts(false);
        }
        //don't add pts if polygon is complete and button not clicked
        else if (isPolyComplete) return;
        //finish the polygon if user clicks on the first point
        else if (isMouseOverPoint && points.length === 4) {
          setPolyComplete(true);
          setPredictLine([]);
        } else if (points.length === 4) {
          setPolyComplete(true);
          setPredictLine([]);
        } else {
          setPoints([...points, mousePos]);
          setPredictLine([]);
        }
      } else {
        undo();
      }
    };
    const undo = () => {
      setPoints(points.slice(0, -1));
      setPolyComplete(false);
      setPredictLine([]);
    };

    //handler for when the mouse moves + used for the temp dotted line
    const handleMouseMove = (e) => {
      const stage = e.target.getStage();
      const mousePos = getMousePos(stage);
      setPosition(mousePos);
      if (!isPolyComplete && points.length > 0) {
        setPredictLine([points[points.length - 1], mousePos]);
      }
    };

    //enlarger point when mouse is over midpoint, easier for user
    const handleMouseOnMdpt = (e) => {
      if (!isAddPts) return;
      e.target.scale({ x: 1.5, y: 1.5 });
      setMouseOverMdpt(true);
    };

    // keep point at regular size when mouse is not on top
    const handleMouseOffMdpt = (e) => {
      e.target.scale({ x: 1, y: 1 });
      setMouseOverMdpt(false);
    };

    // enlarge point when the mouse is over the start pt, easier for user
    const handleMouseOverStartPoint = (e) => {
      if (isPolyComplete || points.length < 3) return;
      e.target.scale({ x: 3, y: 3 });
      setMouseOverPoint(true);
    };

    // if the mouse if not on the start point, need to make every point
    //appear the same
    const handleMouseOutStartPoint = (e) => {
      e.target.scale({ x: 1, y: 1 });
      setMouseOverPoint(false);
    };

    //when the user moves a point after polygon is complete
    const handlePointDragMove = (e) => {
      const stage = e.target.getStage();
      const index = e.target.index - 1 - points.length;
      const pos = [e.target._lastPos.x, e.target._lastPos.y];
      if (pos[0] < 0) pos[0] = 0;
      if (pos[1] < 0) pos[1] = 0;
      if (pos[0] > stage.width()) pos[0] = stage.width();
      if (pos[1] > stage.height()) pos[1] = stage.height();
      const newPoints = [
        ...points.slice(0, index),
        pos,
        ...points.slice(index + 1),
      ];
      if (IsConvexPolygon(newPoints) && isConvex(newPoints)) {
        setIsPolygon(true);
      } else {
        setIsPolygon(false);
      }
      setPoints(newPoints);
    };
    const handlePointDragEnd = (e) => {};
    const handleLinePoint = (points) => {
      let result = [];
      if (points) {
        for (let i = 0; i < points.length - 2; i += 2) {
          let subArray = points.slice(i, i + 4);
          result.push(subArray);
        }
        // Xử lý phần tử cuối cùng
        let lastSubArray = points
          .slice(points.length - 2)
          .concat(points.slice(0, 2));
        result.push(lastSubArray);
      }
      return result;
    };

    //used for the original points
    useEffect(() => {
      setFlattenedPoints(
        points
          .concat(isPolyComplete ? [] : position)
          .reduce((a, b) => a.concat(b), [])
      );
      if (IsConvexPolygon(points) && isConvex(points) && isPolyComplete) {
        setIsPolygon(true);
      } else {
        setIsPolygon(false);
      }
      sendDataToParent();
      setFlatLinePts(predictLine.reduce((a, b) => a.concat(b), []));
      setLinePoint(handleLinePoint(flattenedPoints));
    }, [points, isPolyComplete, position, predictLine]);

    //set new coordinates when user drags the polygon
    const handleGroupDragEnd = (e) => {
      if (e.target.name() === "polygon") {
        let result = [];
        let copyPoints = [...points];
        copyPoints.map((point) =>
          result.push([point[0] + e.target.x(), point[1] + e.target.y()])
        );
        e.target.position({ x: 0, y: 0 });
        setPoints(result);
      }
    };

    // Sử dụng useImperativeHandle để expose hàm cho component cha
    useImperativeHandle(ref, () => ({
      resetPoints: () => {
        resetPts();
      },
    }));

    const resetPts = () => {
      setPoints([]);
      setPolyComplete(false);
      setPredictLine([]);
      setAddPts(false);
      dispatch(handleSelectLine([]));
    };
    return (
      <div ref={refCanvas}>
        <Stage
          width={sizeWidth}
          height={sizeHeight}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
        >
          <Layer>
            <DrawPolygon
              points={points}
              flattenedPoints={flattenedPoints}
              handlePointDragMove={handlePointDragMove}
              handleGroupDragEnd={handleGroupDragEnd}
              handleMouseOverStartPoint={handleMouseOverStartPoint}
              handleMouseOutStartPoint={handleMouseOutStartPoint}
              handleMouseOnMdpt={handleMouseOnMdpt}
              handleMouseOffMdpt={handleMouseOffMdpt}
              isFinished={isPolyComplete}
              handlePointDragEnd={handlePointDragEnd}
              mdPts={mdpts}
              bgDraw={"blue"}
              linePoint={linePoint}
              predictLine={flatLinePts}
              sendPosition={(e) => {
                setPoints(e);
              }}
              IsConvexPolygon={IsConvexPolygon}
            />
          </Layer>
        </Stage>
      </div>
    );
  }
);
export default DrawnCanvas;

import TYPE_ACTION from "@constants/action";
import GroupService from "@redux/services/edit-group-service";
import {
  handleGetAllCamera,
  handleGetListGroup,
} from "@redux/slice/edit-group-slice";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";

function* doGetListGroupSaga(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.getListGroup, body);
    const { data } = response;
    callBack(data);
    if (!body.get) {
      yield put(handleGetListGroup(data));
    }
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doGetAllCameraSaga(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.getAllCamera, body);
    const { data } = response;
    callBack(data);
    yield put(handleGetAllCamera(data));
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doCreateGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.createCameraGroup, body);
    const { data } = response;
    callBack(data);
    message.success("Thêm mới thành công!");
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doUpdateGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.updateCameraGroup, body);
    const { data } = response;
    callBack(data);
    message.success("Cập nhật thành công!");
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doDeleteGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.deleteCameraGroup, body.ids);
    const { data } = response;
    callBack(data);
    message.success("Xóa thành công!");
  } catch (error) {
    console.log("Error update: ", error);
  }
}
function* doDeleteCameraOnGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.deleteCameraOnGroup, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    console.log("Error update: ", error);
  }
}
function* doAddCameraOnGroup(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(GroupService.addCameraOnGroup, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    console.log("Error update: ", error);
  }
}
// -- Watcher
function* watchGetListGroupSaga() {
  yield takeEvery(TYPE_ACTION.GROUP.GET_LIST_GROUP, doGetListGroupSaga);
}
function* watchGetAllCameraSaga() {
  yield takeEvery(TYPE_ACTION.GROUP.GET_ALL_CAMERA, doGetAllCameraSaga);
}
function* watchCreateGroupCamera() {
  yield takeEvery(TYPE_ACTION.GROUP.CREATE_GROUP, doCreateGroup);
}
function* watchDeleteGroupCamera() {
  yield takeEvery(TYPE_ACTION.GROUP.DELETE_CAMERA_GROUP, doDeleteGroup);
}
function* watchUpdateGroupCamera() {
  yield takeEvery(TYPE_ACTION.GROUP.UPDATE_CAMERA_GROUP, doUpdateGroup);
}
function* watchDeleteCameraOnGroup() {
  yield takeEvery(
    TYPE_ACTION.GROUP.DELETE_CAMERA_ON_GROUP,
    doDeleteCameraOnGroup
  );
}
function* watchAddCameraOnGroup() {
  yield takeEvery(TYPE_ACTION.GROUP.ADD_CAMERA_ON_GROUP, doAddCameraOnGroup);
}
export {
  watchGetListGroupSaga,
  watchGetAllCameraSaga,
  watchCreateGroupCamera,
  watchDeleteGroupCamera,
  watchUpdateGroupCamera,
  watchDeleteCameraOnGroup,
  watchAddCameraOnGroup,
};

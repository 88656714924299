import React, { useRef, useEffect, useState } from "react";
import mpegts from "mpegts.js";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { handleGetSizeVideo } from "@redux/slice/edit-group-slice";
import { handleSizeVideo } from "@redux/slice/ai-slice";

const VideoStream = ({ flvUrl, controls = false, initialSize }) => {
  const videoElement = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  let player = useRef(null);

  const createPlayer = (url) => {
    if (mpegts.getFeatureList().mseLivePlayback) {
      player.current = mpegts.createPlayer({
        type: "flv",
        url: url,
        isLive: true,
      });

      player.current.attachMediaElement(videoElement.current);
      player.current.load();

      player.current.on(mpegts.Events.ERROR, (errorType, errorDetails) => {
        console.error("Player Error:", errorType, errorDetails);
      });

      videoElement.current.addEventListener("canplay", () => {
        player.current?.play();
        setLoading(false);
        if (videoElement.current) {
          dispatch(
            handleSizeVideo({
              width: videoElement.current.offsetWidth,
              height: videoElement.current.offsetHeight,
            })
          );
        }
      });
    }
  };
  const handleResize = () => {
    if (videoElement.current) {
      dispatch(
        handleGetSizeVideo({
          width: videoElement.current.offsetWidth,
          height: videoElement.current.offsetHeight,
        })
      );
      dispatch(
        handleSizeVideo({
          width: videoElement.current.offsetWidth,
          height: videoElement.current.offsetHeight,
        })
      );
      initialSize({
        width: videoElement.current.offsetWidth,
        height: videoElement.current.offsetHeight,
        isLoading: loading,
      });
    }
  };
  window.addEventListener("resize", handleResize);

  useEffect(() => {
    handleResize();
  }, [loading]);
  useEffect(() => {
    createPlayer(flvUrl);

    return () => {
      if (player.current) {
        player.current.unload();
        player.current.detachMediaElement();
        player.current.destroy();
        player.current = null;
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [flvUrl]);

  return (
    <div>
      <div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spin />
          </div>
        )}
        <video
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "750px",
            maxHeight: "500px",
            border: "3px solid #2B2A3A",
          }}
          ref={videoElement}
          autoPlay
          controls={controls}
        ></video>
      </div>
    </div>
  );
};

export default VideoStream;

import TYPE_ACTION from "@constants/action";
import AiService from "@redux/services/ai-servive";
import {
  handleGetListAiFlow,
  handleGetListAiFlowByCamera,
  handleListPolygon,
} from "@redux/slice/ai-slice";
import { message } from "antd";
import { call, put, takeEvery } from "redux-saga/effects";

function* doGetListAiFlow(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.getAiFlows, body);
    const { data } = response;
    callBack(data);
    yield put(handleGetListAiFlow(data));
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doGetByCameraAndType(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.getAiFlowsByCamera, body);
    const { data } = response;
    yield put(handleGetListAiFlowByCamera(data));
    callBack(data);
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doGetListPolygon(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.getAllPolygon, body);
    const { data } = response;
    callBack(data);
    yield put(handleListPolygon(data));
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doAddPolygonAi(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.addPolygonToAi, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doAddAiToCamera(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.addAiToCamera, body);
    const { data } = response;
    callBack(data);
    message.success("Thêm mới thành công!");
  } catch (error) {
    console.log("Error fetching: ", error);
  }
}
function* doCreateAiFlow(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.createAiFlows, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doCreatePolygon(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.createPolygon, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doUpdatePolygon(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.updatePolygon, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
function* doDeleteAiFlow(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.deleteAiFlows, body);
    const { data } = response;
    callBack(data);
    message.success("Xóa thành công!");
  } catch (error) {
    console.log("Error update: ", error);
  }
}
function* doDeletePolygon(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.deletePolygon, body);
    const { data } = response;
    callBack(data);
    message.success("Xóa thành công!");
  } catch (error) {
    console.log("Error update: ", error);
  }
}
function* doApplyAiflow(action) {
  try {
    const { body, callBack } = action.payload;
    const response = yield call(AiService.applyAiFlows, body);
    const { data } = response;
    callBack(data);
  } catch (error) {
    message.error("Có lỗi xảy ra!");
    console.log("Error update: ", error);
  }
}
// -- Watcher
function* watchGetListAiFlow() {
  yield takeEvery(TYPE_ACTION.AI.GET_AI_FLOW, doGetListAiFlow);
}
function* watchAddPolygonAi() {
  yield takeEvery(TYPE_ACTION.AI.ADD_POLYGON_TO_AI_FLOW, doAddPolygonAi);
}
function* watchGetAllPolygon() {
  yield takeEvery(TYPE_ACTION.AI.GET_POLYGON, doGetListPolygon);
}
function* watchCreateAiFlow() {
  yield takeEvery(TYPE_ACTION.AI.CREATE_AI_FLOW, doCreateAiFlow);
}
function* watchCreatePolygon() {
  yield takeEvery(TYPE_ACTION.AI.CREATE_POLYGON, doCreatePolygon);
}
function* watchDeleteAiFlow() {
  yield takeEvery(TYPE_ACTION.AI.DELETE_AI_FLOW, doDeleteAiFlow);
}
function* watchDeletePolygon() {
  yield takeEvery(TYPE_ACTION.AI.DELETE_POLYGON, doDeletePolygon);
}
function* watchAddAiToCamera() {
  yield takeEvery(TYPE_ACTION.AI.ADD_AI_FLOW_TO_CAMERA, doAddAiToCamera);
}
function* watchGetByCameraAndType() {
  yield takeEvery(TYPE_ACTION.AI.GET_BY_CAMERA_AND_TYPE, doGetByCameraAndType);
}
function* watchUpdatePolygon() {
  yield takeEvery(TYPE_ACTION.AI.UPDATE_POLYGON, doUpdatePolygon);
}
function* watchApplyAiFlow() {
  yield takeEvery(TYPE_ACTION.AI.APPLY_AI_FLOW, doApplyAiflow);
}
export {
  watchGetListAiFlow,
  watchGetAllPolygon,
  watchCreateAiFlow,
  watchCreatePolygon,
  watchDeleteAiFlow,
  watchDeletePolygon,
  watchAddPolygonAi,
  watchAddAiToCamera,
  watchGetByCameraAndType,
  watchUpdatePolygon,
  watchApplyAiFlow,
};

import { call, put, takeEvery } from "redux-saga/effects";
import {
  getDataAddress,
  getCode,
  getDataProvince,
  getDataDistrict,
  getTotalCamAll,
  getDataRecorder, changeKeyHandle,
  getDataCameraGroup,
  closeGroup,
  getDataWard,
  getDataLocationCamMap,
  getDataCameraSerach
} from "../slice/map-slice";
import MapService from "@redux/services/map-services";
import TYPE_ACTION from "@constants/action";
//-----Worker
function* doGetLocationCamera(action) {
  try {
    const {body,callback} = action.payload;
    const response = yield call(MapService.getDataLocationCamera, body);
    const { data } = response;
    callback(data)
    yield put(getDataLocationCamMap(data));
    
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetLocationCameraScanning(action) {
  try {
    const {body,callback} = action.payload;
    const response = yield call(MapService.getDataLocationCamera, body);
    const { data } = response;
    callback(data)    
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doScanCameraSingleIP(action) {
  try {
    const {body,callback} = action.payload;
    const response = yield call(MapService.scanCameraSingle, body);
    callback(response.data)
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doSearchCamera(action) {
  try {
    const body = action.payload;
    const response = yield call(MapService.getSearchCamera, body);
    console.log("response",response);
    const { data } = response;
    yield put(getDataCameraSerach(data.content));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetDataDrawCircle(action) {
  try {
    const {arr,callBack} = action.payload
    const response = yield call(MapService.getDataDrawCircle,arr);
    const { data } = response;
    callBack(data)
    yield put(getDataLocationCamMap(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetDataDrawRectange(action) {
  const {callback,body} = action.payload
  try {
    const response = yield call(MapService.getDataLocationCameraRec,body);
    const { data } = response;
    callback(data)
    yield put(getDataLocationCamMap(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetTotalCamera(action) {
  const {callBack} = action.payload
  try {
    const response = yield call(MapService.getTotalCamera,action.payload);
    callBack(response.data)
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetProcessing(action) {
  const {callback} = action.payload
  try {
    const response = yield call(MapService.getProcessing,action.payload);
    callback(response.data)
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doSetStateScan(action) {
  const {body,callback} = action.payload;
  try {
    const response = yield call(MapService.setStateScanning,body);
    callback(response)
  } catch (error) {
    console.log("Error fetching", error);
  }
}








function* doGetDataRecorder(action) {
  try {
    const body = action.payload;
    const response = yield call(MapService.getDataRecorder, body);
    const { data } = response;
    yield put(getDataRecorder(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetDataAddress(action) {
  try {
    const response = yield call(MapService.getDataAddress);
    const { data } = response;
    yield put(getDataAddress(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetDataProvince(action) {
  const { body } = action.payload;
  try {
    const response = yield call(MapService.getDataProvince, body);
    const { data } = response;
    yield put(getDataProvince(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetDataWard(action) {
  const { body,callback } = action.payload;
  try {
    const response = yield call(MapService.getDataWard, body);
    const { data } = response;
    callback(data)
    yield put(getDataWard(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetDataDistrict(action) {
  const { body ,callback} = action.payload;
  try {
    const response = yield call(MapService.getDataDistrict, body);
    const { data } = response;
    callback(data)
    yield put(getDataDistrict(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetTotalCam(action) {
  try {
    const response = yield call(MapService.getTotalCamAll);
    const { data } = response;
    yield put(getTotalCamAll(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}
function* doGetTotalCameraAllMap(action) {
  const {callback} = action.payload
  try {
    const response = yield call(MapService.getTotalCamAllMap);
    const { data } = response;
    callback(data)
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetDataDrawPolygon(action) {
  try {
    const {arr,callBack} = action.payload
    const response = yield call(MapService.getDataDrawPolygon,arr);
    callBack(response)
    // const { data } = response;
    // yield put(getTotalCamAll(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}

function* doGetCameraDataWithID(action) {
  const {url,callBack,errorBack} = action.payload;
  try {
    const response = yield call(MapService.getPreviewCameraWithID,url);
    callBack(response.data)
  } catch (error) {
    errorBack(error)
    console.log("Error fetching", error);
  }
}

function* doCloseCamera(action) {
  try {
    console.log("stop stream")
    const response = yield call(MapService.closeCamera,action.payload);
    console.log(response)
  } catch (error) {
    console.log("Error fetching", error);
  }
}


function* doGetCode(action) {
  yield put(getCode(action.payload));
}

function* doGetChangeKey(action) {
  yield put(changeKeyHandle(action.payload));
}

function* doCloseGroup(action) {
  yield put(closeGroup(action.payload));
}

function* doGetDataCameraGroup(action){
  try {
    const {body,callback} = action.payload
    const response = yield call(MapService.getDataCameraGroup,body);
    const {data} = response
    callback(data)
    yield put(getDataCameraGroup(data));
  } catch (error) {
    console.log("Error fetching", error);
  }
}



//----Watcher
function* watchGetLocationCamera() {
  yield takeEvery(
    TYPE_ACTION.MAP.GET_LOCATION_CAMERA,
    doGetLocationCamera
  );
}
function* watchGetLocationCameraScanning() {
  yield takeEvery(
    TYPE_ACTION.MAP.GET_LOCATION_CAMERA_SCANNING,
    doGetLocationCameraScanning
  );
}


function* watchGetLocationCameraCircle() {
  yield takeEvery(
    TYPE_ACTION.MAP.GET_LOCATION_CAMERA_CIRCLE,
    doGetDataDrawCircle
  );
}
function* watchGetLocationCameraRectangle() {
  yield takeEvery(
    TYPE_ACTION.MAP.GET_LOCATION_CAMERA_REC,
    doGetDataDrawRectange
  );
}
function* watchScanCameraSingleIP() {
  yield takeEvery(
    TYPE_ACTION.MAP.SCAN_CAMERA_SINGLE_IP,
    doScanCameraSingleIP
  );
}
//
function* watchGetDataRecorder() {
  yield takeEvery(TYPE_ACTION.MAP.GET_RECORDER, doGetDataRecorder);
}
function* watchGetDataAddress() {
  yield takeEvery(TYPE_ACTION.MAP.GET_ADDRESS, doGetDataAddress);
}
function* watchGetSearchCamera() {
  yield takeEvery(TYPE_ACTION.MAP.GET_CAMERA_SERACH, doSearchCamera);
}
function* watchGetSearchCameraAllMap() {
  yield takeEvery(TYPE_ACTION.MAP.GET_COUNT_CAMERA_MAP, doGetTotalCameraAllMap);
}






////////////////////////////////////////////////////////////////
function* watchGetDataProvince() {
  yield takeEvery(TYPE_ACTION.MAP.GET_PROVINCE, doGetDataProvince);
}
function* watchGetDataWard() {
  yield takeEvery(TYPE_ACTION.MAP.GET_WARD, doGetDataWard);
}
function* watchGetDataDistrict() {
  yield takeEvery(TYPE_ACTION.MAP.GET_DISTRICT, doGetDataDistrict);
}
function* watchGetTotalCam() {
  yield takeEvery(TYPE_ACTION.MAP.GET_TOTAL, doGetTotalCam);
}
function* watchDrawCircle() {
  yield takeEvery(TYPE_ACTION.MAP.GET_ADDRESS_DRAW_CIRCLE, doGetDataDrawCircle);
}
function* watchDrawPolygon() {
  yield takeEvery(TYPE_ACTION.MAP.GET_ADDRESS_DRAW_POLYGON, doGetDataDrawPolygon);
}
function* watchGetCode() {
  yield takeEvery(TYPE_ACTION.MAP.GET_CODE, doGetCode);
}
function* watchGetPreviewCamera() {
  yield takeEvery(TYPE_ACTION.MAP.GET_PREVIEW, doGetCameraDataWithID);
}
function* watchStopStream() {
  yield takeEvery(TYPE_ACTION.MAP.CLOSE_PRVIEW, doCloseCamera);
}
function* watchChangeKey() {
  yield takeEvery(TYPE_ACTION.MAP.CHANGE_KEY, doGetChangeKey);
}
function* watchGetCameraDataGroup() {
  yield takeEvery(TYPE_ACTION.MAP.GET_DATA_CAMERA_GROUP, doGetDataCameraGroup);
}

function* watchCloseGroup() {
  yield takeEvery(TYPE_ACTION.MAP.CLOSE_GROUP, doCloseGroup);
}
function* watchGetTotalCamera() {
  yield takeEvery(TYPE_ACTION.MAP.GET_TOTAL_CAMERA, doGetTotalCamera);
}
function* watchGetProcessing() {
  yield takeEvery(TYPE_ACTION.MAP.GET_LIST_PROCESSING, doGetProcessing);
}

function* watchSetStateScan() {
  yield takeEvery(TYPE_ACTION.MAP.SET_STATE_SCANNING, doSetStateScan);
}




export {
  watchGetLocationCamera,
  watchGetDataAddress,
  watchGetCode,
  watchGetDataProvince,
  watchGetDataDistrict,
  watchGetTotalCam,
  watchGetDataRecorder,
  watchDrawCircle,
  watchDrawPolygon,
  watchGetPreviewCamera,
  watchStopStream,
  watchChangeKey,
  watchGetCameraDataGroup,
  watchCloseGroup,
  watchGetDataWard,
  watchScanCameraSingleIP,
  watchGetSearchCamera,
  watchGetLocationCameraCircle,
  watchGetLocationCameraRectangle,
  watchGetTotalCamera,
  watchGetProcessing,
  watchGetLocationCameraScanning,
  watchGetSearchCameraAllMap,
  watchSetStateScan
};

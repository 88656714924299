import colors from "@constants/colors";
import {
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  message,
  Radio,
  Select,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonCommon from "@common/button/custom-button";
import icon_dg from "@assets/Dropdown.svg";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import DrawnCanvas from "@components/live/polygon/drawn-canvas";
import VideoStream from "@components/live/video-stream";
import PolygonSVG from "./SVG";
import delete_icon from "@assets/DeletePolygon.svg";
import edit_icon from "@assets/EditPolygon.svg";
import TYPE_ACTION from "@constants/action";
import {
  handleResetData,
  handleSendEditPolygon,
  selectIdAiFlows,
  selectLinePolygon,
  selectPolygonDTOs,
  selectPolygonDTOsActive,
} from "@redux/slice/ai-slice";
import CustomModal from "@common/modal/modal";
const SettingAi = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const linePolygon = useSelector(selectLinePolygon);
  const [widthPolygon, setWidthPolygon] = useState(0);
  const [heightPolygon, setHeightPolygon] = useState(0);
  const [isDrawn, setIsDrawn] = useState(false);
  const [isDoor, setIsDoor] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [pointEdit, setPointEdit] = useState([]);
  const [value, setValue] = useState("");
  const location = useLocation();
  const id = location.pathname.split("/")[4];
  const childRef = useRef();
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = useSelector(selectPolygonDTOs);
  const [idAiFlows, setIdAiFlows] = useState(useSelector(selectIdAiFlows));
  const [typePolygon, setTypePolygon] = useState([]);
  const listPolygonActive = useSelector(selectPolygonDTOsActive);
  const [checkedList, setCheckedList] = useState([]);
  const [typeAi, setTypeAi] = useState("");
  const [listStyleType, setListStyleType] = useState([]);
  const [points, setPoints] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPolygon, setIsPolygon] = useState(false);
  const [apply, setApply] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const { type } = location.state || null;

  const optionsAiflow = [
    { label: "Xâm nhập", value: "INTRUSION" },
    { label: "Lưu lượng", value: "FLOW" },
    { label: "Kiểm soát", value: "ACCESS_CONTROL" },
    { label: "Vũ khí", value: "WEAPON_DETECT" },
    { label: "Nhận diện", value: "FEATURE" },
  ];

  const formRef = useRef({
    nameZone: null,
    nameAi: null,
    type: null,
    typeAiFlow: null,
    features: null,
    typeDoor: null,
    idPolygon: null,
    typeAi: null,
  });

  useEffect(() => {
    setCheckedList(listPolygonActive);
  }, [listPolygonActive]);

  useEffect(() => {
    const underscoreIndex = type.indexOf("_");
    const typeName = type.substring(0, underscoreIndex);
    const typeAi = type.substring(underscoreIndex + 1);

    form.setFieldsValue({
      typeName: typeName,
      typeAi: typeAi,
      typePolygon: "Polygon",
    });
    formRef.current.type = typeName;
    formRef.current.typeAi = typeAi;
    setTypeAi(typeAi);
  }, []);

  useEffect(() => {
    handleGetData();
  }, [isLoadingVideo]);

  const resetForm = () => {
    form.setFieldsValue({
      nameZone: "",
      typeAiFlow: "",
      typePolygon: "Polygon",
    });
    dispatch(handleResetData());
    handleReset();
  };

  const [urls, setUrls] = useState();
  const startStream = (url) => {
    setUrls();
    const callBack = (data) => {
      if (data) {
        setUrls(data);
      }
    };
    const errorBack = (data) => {};
    dispatch({
      type: TYPE_ACTION.LIVE.GET_PREVIEW_WITH_ID,
      payload: { url, callBack, errorBack },
    });
  };
  useEffect(() => {
    startStream(id);
  }, [id]);

  function calculateCoordinates(item) {
    const x = parseInt((item[0] / widthPolygon) * 1920);
    const y = parseInt((item[1] / heightPolygon) * 1080);
    return [x, y];
  }
  const AI_FLOWS = [
    {
      name: "Vùng đếm vào",
      value: "ZONE_ACCESS_CONTROL_IN",
    },
    {
      name: "Vùng đếm ra",
      value: "ZONE_ACCESS_CONTROL_OUT",
    },
  ];

  const AI_FLOWS_2 = [
    {
      name: "Vẽ vùng nhận diện",
      value: "ZONE_DETECT",
    },
  ];
  const handleGetData = () => {
    let body = {
      cameraId: id,
      type: [formRef.current.type + "_" + formRef.current.typeAi],
    };
    dispatch({
      type: TYPE_ACTION.AI.GET_BY_CAMERA_AND_TYPE,
      payload: {
        body: body,
        callBack: (data) => {
          setApply(data.apply);
          setIdAiFlows(data.id);
        },
      },
    });
  };

  const onChange = (value) => {
    handleReset();
    setTypeAi(value);
    formRef.current.typeAi = value;
    let body = {
      cameraId: id,
      type: [formRef.current.type + "_" + value],
    };
    dispatch({
      type: TYPE_ACTION.AI.GET_BY_CAMERA_AND_TYPE,
      payload: { body: body, callBack: () => {} },
    });
  };
  const onChangeRadio = (e) => {
    formRef.current.typeAiFlow = e.target.value;
    setValue(e.target.value);
  };
  useEffect(() => {
    setIsDoor(false);

    const setAIStyles = () => {
      if (typeAi === "ACCESS_CONTROL") {
        setListStyleType(AI_FLOWS);
        setIsDoor(true);
      } else if (typeAi === "FLOW") {
        setListStyleType(AI_FLOWS);
      } else if (["INTRUSION", "WEAPON_DETECT", "FEATURE"].includes(typeAi)) {
        setListStyleType(AI_FLOWS_2);
      }
    };

    setAIStyles();
  }, [typeAi]);

  const handleBack = () => {
    dispatch(handleResetData());
    navigate(-1);
  };
  const getPoint = (data, isPolygon) => {
    setPoints(data.map(calculateCoordinates));
    setIsPolygon(isPolygon);
  };
  const createZoneData = (data) => ({
    type: data.typeAiFlow,
    polygon: JSON.stringify(points),
    line: JSON.stringify(linePolygon?.map(calculateCoordinates)),
    name: data.nameZone,
    active: true,
    apply: true,
  });
  const createNewZone = async (data) => {
    const isAccessControl =
      data.typeName + "_" + data.typeAi === "HUMAN_ACCESS_CONTROL" ||
      data.typeName + "_" + data.typeAi === "VEHICLE_ACCESS_CONTROL";

    if (isAccessControl && linePolygon.length === 0) {
      message.warning("Vui lòng chọn 1 trong các cạnh để chọn hướng đi");
      return;
    }

    const dataZone = createZoneData(data);

    const callBack = async (idZone) => {
      await dispatch({
        type: TYPE_ACTION.AI.ADD_POLYGON_TO_AI_FLOW,
        payload: {
          body: {
            id: idAiFlows,
            ids: [idZone.id],
          },
          callBack: () => {},
        },
      });

      await dispatch({
        type: TYPE_ACTION.AI.ADD_AI_FLOW_TO_CAMERA,
        payload: {
          body: {
            id: id,
            ids: [idAiFlows],
          },
          callBack: async () => {
            setIsEdit(false);
            setIsDrawn(false);
            await resetForm();
            await handleGetData();
          },
        },
      });
    };

    await dispatch({
      type: TYPE_ACTION.AI.CREATE_POLYGON,
      payload: { body: dataZone, callBack: callBack },
    });
  };
  const handleUpdateZone = async (data) => {
    try {
      let dataUpdate = {
        id: formRef.current.idPolygon,
        type: data.typeAiFlow,
        polygon: JSON.stringify(points),
        line: JSON.stringify(linePolygon?.map(calculateCoordinates)),
        name: data.nameZone,
        active: true,
        apply: true,
      };
      await dispatch({
        type: TYPE_ACTION.AI.UPDATE_POLYGON,
        payload: {
          body: dataUpdate,
          callBack: async () => {
            setIsEdit(false);
            setIsDrawn(false);
            await resetForm();
            await handleGetData();
          },
        },
      });
      message.success("Cập nhật thành công!");
    } catch (error) {}
  };
  const onFinish = async (value) => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      if (isEdit) {
        await handleUpdateZone(value);
      } else {
        await createNewZone(value);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const initialSize = (data) => {
    setWidthPolygon(data.width);
    setHeightPolygon(data.height);
    setIsLoadingVideo(data.isLoading);
  };

  // hàm reset point
  const handleReset = () => {
    if (childRef.current) {
      childRef.current.resetPoints();
    }
  };
  //edit polygon
  const handleEditPolygon = (item) => {
    // dispatch(handleSelectLine([]));
    setPointEdit(item.points);
    dispatch(handleSendEditPolygon(item.points));
    setIsDrawn(true);
    setIsEdit(true);
    formRef.current.idPolygon = item.id;
    form.setFieldsValue({
      nameZone: item.data.name,
      typeAiFlow: item.data.type,
      typePolygon: "Polygon",
    });
  };

  const handleDeletePolygon = async (data) => {
    dispatch(handleResetData());
    await dispatch({
      type: TYPE_ACTION.AI.DELETE_POLYGON,
      payload: {
        body: data.id,
        callBack: async () => {
          await handleGetData();
        },
      },
    });
  };

  const handleApply = () => {
    const body = {
      id: id,
      apply: true,
      type: formRef.current.type + "_" + formRef.current.typeAi,
    };
    dispatch({
      type: TYPE_ACTION.AI.APPLY_AI_FLOW,
      payload: { body, callBack: () => {} },
    });
  };

  const handleUpdateAiFlows = () => {
    try {
      typePolygon.map((item) => {
        let dataUpdate = {
          id: item.id,
          type: item.typeZone,
          polygon: item.data.polygon,
          line: item.data.line,
          name: item.name,
          active: true,
        };
        dispatch({
          type: TYPE_ACTION.AI.UPDATE_POLYGON,
          payload: { body: dataUpdate, callBack: () => {} },
        });
      });
      plainOptions.map((item) => {
        if (!typePolygon.map((item) => item.id).includes(item.id)) {
          let dataUpdate = {
            id: item.id,
            type: item.typeZone,
            polygon: item.data.polygon,
            line: item.data.line,
            name: item.name,
            active: false,
          };
          dispatch({
            type: TYPE_ACTION.AI.UPDATE_POLYGON,
            payload: { body: dataUpdate, callBack: () => {} },
          });
        }
      });
      setIsEdit(false);
      setIsDrawn(false);
      message.success("Cập nhật thành công!");
      navigate(-1);
    } catch (error) {}
  };

  const handleDrawn = async () => {
    try {
      await form.validateFields();
      if (isDrawn) {
        resetForm();
      }
      setIsDrawn((prev) => !prev);
      setIsEdit(false);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleButtonClick = () => {
    if (apply) {
      handleUpdateAiFlows();
    } else {
      setOpenApply(true);
    }
  };

  // xử lý checkAll
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions.map((item) => item.id) : []);
  };
  const handleCheckboxChange = (list) => {
    setCheckedList(list);
  };
  // ghép API
  useEffect(() => {
    let result = [];
    plainOptions.map((item) => {
      if (checkedList.includes(item.id)) {
        result.push(item);
      }
    });
    setTypePolygon(result);
  }, [checkedList, plainOptions]);
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 4,
          colorBgBase: "#181824",
          controlHeight: 40,
          controlOutlineWidth: 0,
          colorText: colors.textBase,
          colorTextPlaceholder: "#706F80",
          colorBorder: colors.borderColor,
          controlItemBgActive: "#5B5B9F",
        },
        components: {
          Checkbox: {
            colorPrimary: "#5B5B9F",
            colorPrimaryHover: "#5B5B9F",
            colorTextDisabled: colors.textBase,
            colorBgContainerDisabled: "#555555",
          },
          Radio: {
            colorTextDisabled: colors.textBase,
            colorBgContainerDisabled: "#555555",
            colorPrimary: "#5B5B9F",
            colorPrimaryHover: "#5B5B9F",
          },
        },
      }}
    >
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className=" w-full h-full">
          <div className="h-full w-full space-y-[12px] p-2">
            <div className="flex justify-between bg-[#181824] p-[20px]">
              <div className="flex">
                <img
                  color="#9D9D9D"
                  className="cursor-pointer"
                  src={icon_dg}
                  alt="dropdown"
                  onClick={() => {
                    handleBack();
                  }}
                />
                <span
                  className="font-bold bg-[#181824] text-[#5B5B9F] cursor-pointer"
                  onClick={handleBack}
                >
                  Quay lại
                </span>
              </div>
            </div>
            <div className="bg-[#181824] px-[20px] pt-[20px]">
              <div className="grid grid-cols-2 gap-[12px] justify-center items-center">
                <div>
                  <Typography.Title level={5}>
                    <div className="flex items-center">
                      <span style={{ color: "#706F80" }}>Loại AI</span>
                      <span className="pl-2" style={{ color: "red" }}>
                        *
                      </span>
                    </div>
                  </Typography.Title>
                  <Form.Item
                    name="typeName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Select
                      disabled={true}
                      className="w-full"
                      onChange={(e) => {
                        formRef.current.type = e;
                        handleReset();
                      }}
                      options={[
                        { value: "HUMAN", label: "Người" },
                        { value: "VEHICLE", label: "Phương tiện" },
                      ]}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Typography.Title level={5}>
                    <div className="flex items-center">
                      <span style={{ color: "#706F80" }}>Tính năng AI </span>
                      <span className="pl-2" style={{ color: "red" }}>
                        *
                      </span>
                    </div>
                  </Typography.Title>
                  <Form.Item
                    name="typeAi"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Select
                      disabled={true}
                      className="w-full"
                      onChange={onChange}
                      defaultValue={optionsAiflow[0]}
                      options={optionsAiflow}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="p-[20px] bg-[#181824]">
              <div class="grid grid-cols-4">
                <span
                  className="pl-[24px]"
                  style={{
                    color: colors.textBase,
                    fontWeight: 700,
                    fontSize: 16,
                  }}
                >
                  Vẽ vùng hoạt động
                </span>
                {isDrawn &&
                  (!isPolygon ? (
                    <p class="col-span-2 pl-[20px]" style={{ color: "red" }}>
                      Chú ý: Chọn 4 điểm để tạo thành 1 đa giác, vui lòng tránh
                      vẽ các tứ giác có hình dạng lồi
                    </p>
                  ) : (
                    ""
                  ))}
              </div>
              <div className="grid grid-cols-6 gap-3 pt-[20px]">
                <div style={{ border: "1px solid #555555", borderRadius: 4 }}>
                  <div className="p-[16px] space-y-[16px]">
                    <ConfigProvider
                      theme={{
                        token: {
                          borderRadius: 4,
                          colorBgBase: "#181824",
                          controlHeight: 40,
                          controlOutlineWidth: 0,
                          colorText: colors.textBase,
                          colorTextPlaceholder: "#706F80",
                          colorBorder: colors.borderColor,
                          controlItemBgActive: "#5B5B9F",
                        },
                      }}
                    >
                      <span
                        style={{
                          color: colors.textBase,
                          fontWeight: 700,
                          fontSize: 14,
                        }}
                      >
                        Loại vùng
                      </span>
                      <>
                        <Form.Item
                          name="typeAiFlow"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn loại vùng",
                            },
                          ]}
                        >
                          <Radio.Group
                            className="space-y-2"
                            onChange={onChangeRadio}
                            disabled={isEdit}
                            value={value}
                          >
                            {listStyleType.map((item, i) => {
                              return (
                                <Radio value={item.value} key={i}>
                                  {item.name}
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </>
                      <>
                        <Form.Item
                          name="typePolygon"
                          rules={[
                            {
                              required: true,
                              message: "Please input your username!",
                            },
                          ]}
                        >
                          <Select
                            className="w-full"
                            onChange={(e) => {
                              formRef.current.typePolygon = e.target.value;
                            }}
                            defaultValue={"Polygon"}
                            options={[{ value: "Polygon", label: "Tứ giác" }]}
                          />
                        </Form.Item>
                      </>
                      <>
                        <Form.Item
                          name="nameZone"
                          rules={[
                            {
                              required: true,
                              message: "Tên vùng đang trống",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Nhập tên vùng"
                            onChange={(e) => {
                              formRef.current.nameZone = e.target.value;
                            }}
                          />
                        </Form.Item>
                      </>
                      <div className="grid xl:grid-cols-3 grid-rows-3 gap-[16px] pt-[20px]">
                        <CustomButtonCommon
                          text={!isDrawn ? "Vẽ" : "Hủy"}
                          backgroundColor={
                            !isDrawn ? colors.redGlobal : "#2B2A3A"
                          }
                          border="none"
                          width="60px"
                          onClick={() => {
                            handleDrawn();
                          }}
                        />
                        <CustomButtonCommon
                          text="Xóa"
                          disabled={!isDrawn}
                          border="none"
                          width="60px"
                          onClick={handleReset}
                        />
                        <CustomButtonCommon
                          text="Lưu"
                          disabled={!isDrawn || !isPolygon}
                          width="60px"
                          border="none"
                          htmlType="submit"
                        />
                      </div>
                    </ConfigProvider>
                  </div>
                </div>
                <div
                  className="col-span-4 flex justify-center items-center h-[500px] relative"
                  style={{ border: "1px solid #555555", borderRadius: 4 }}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <div className="absolute">
                    <VideoStream initialSize={initialSize} flvUrl={urls} />
                    <div className="absolute top-0 left-0">
                      {!isLoadingVideo && (
                        <>
                          <DrawnCanvas
                            viewportWidth={widthPolygon}
                            viewportHeight={heightPolygon}
                            onData={getPoint}
                            pointEdit={pointEdit}
                            ref={childRef}
                          />
                          <div className="absolute top-0 left-0">
                            {!isDrawn && (
                              <PolygonSVG
                                polygons={typePolygon}
                                width={widthPolygon}
                                height={heightPolygon}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="p-[16px]"
                  style={{ border: "1px solid #555555", borderRadius: 4 }}
                >
                  <div className="pb-[16px]">
                    <span
                      style={{
                        color: colors.textBase,
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      Danh sách vùng được lưu
                    </span>
                  </div>
                  <>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                      disabled={isEdit}
                    >
                      Tất cả
                    </Checkbox>
                    <CheckboxGroup
                      value={checkedList}
                      onChange={handleCheckboxChange}
                      disabled={isEdit}
                    >
                      {plainOptions.map((item, index) => (
                        <div
                          key={item.id}
                          className="flex items-center justify-between"
                        >
                          <Checkbox
                            value={item.id}
                            checked={checkedList.includes(item.id)}
                          >
                            <p className="truncate max-w-[80px]">{item.name}</p>
                          </Checkbox>
                          <div className="flex gap-[12px]">
                            <img
                              alt="edit"
                              src={edit_icon}
                              className="cursor-pointer min-h-[22px]"
                              onClick={() => {
                                !isEdit && handleEditPolygon(item);
                              }}
                            />
                            <img
                              alt="delete"
                              src={delete_icon}
                              className="cursor-pointer min-h-[22px]"
                              onClick={() => {
                                if (!isEdit && !isDrawn) {
                                  handleDeletePolygon(item);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </CheckboxGroup>
                  </>
                </div>
              </div>
              <Form.Item>
                <div className="flex justify-end gap-[16px] pt-[20px]">
                  <CustomButtonCommon
                    text="Hủy"
                    width="101px"
                    backgroundColor="#2B2A3A"
                    border="none"
                    onClick={() => {
                      handleBack();
                    }}
                  />
                  <CustomButtonCommon
                    text="Lưu"
                    width="101px"
                    backgroundColor={colors.redGlobal}
                    onClick={handleButtonClick}
                  />
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      {!apply && (
        <CustomModal
          centered={true}
          open={openApply}
          width={"460px"}
          handleCancel={() => {
            setOpenApply(false);
          }}
          content={
            <div className="h-[175px] w-full bg-[#2B2A3A]">
              <div className="h-[100px] w-full flex items-center justify-center">
                <p className="font-semibold text-[16px]">
                  Bạn có muốn bật bài toán AI
                </p>
              </div>
              <div className="h-[75px] w-full space-x-[16px] flex items-center justify-center px-[36px]">
                <div className="w-full">
                  <CustomButtonCommon
                    width="100%"
                    height="39px"
                    onClick={() => {
                      handleApply();
                      handleUpdateAiFlows();
                    }}
                    backgroundColor={colors.redGlobal}
                    text={"Có"}
                  />
                </div>
                <div className="w-full">
                  <CustomButtonCommon
                    width="100%"
                    height="39px"
                    onClick={() => {
                      handleUpdateAiFlows();
                    }}
                    text={"Không"}
                  />
                </div>
              </div>
            </div>
          }
          isHeader={false}
        />
      )}
    </ConfigProvider>
  );
};
export default SettingAi;

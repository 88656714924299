import { SERVICE_PATHS } from "@constants/url";
import { get, remove, post, patch } from "../../api/api-base";
const AiService = {
  getAiFlows(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.AI.GET_AI_FLOW);
    }

    return get(`${SERVICE_PATHS.AI.GET_AI_FLOW}${filterString}`);
  },

  getAiFlowsByCamera(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.AI.GET_BY_CAMERA_AND_TYPE);
    }

    return get(`${SERVICE_PATHS.AI.GET_BY_CAMERA_AND_TYPE}${filterString}`);
  },

  getAllPolygon(body) {
    let filterString = "?";
    Object.keys(body).map((index) => {
      if (filterString !== "?" && body[index]) {
        filterString += `&${index}=${body[index]}`;
      } else if (body[index]) {
        filterString += `${index}=${body[index]}`;
      }
    });
    if (filterString === "?") {
      return get(SERVICE_PATHS.AI.GET_POLYGON);
    }

    return get(`${SERVICE_PATHS.AI.GET_POLYGON}${filterString}`);
  },
  createAiFlows: (body) => post(SERVICE_PATHS.AI.CREATE_AI_FLOW, body),
  deletePolygon: (body) =>
    remove(`${SERVICE_PATHS.AI.DELETE_POLYGON}?ids=${body}`),
  createPolygon: (body) => post(SERVICE_PATHS.AI.CREATE_POLYGON, body),
  addPolygonToAi: (body) => post(SERVICE_PATHS.AI.ADD_POLYGON_TO_AI_FLOW, body),
  addAiToCamera: (body) => post(SERVICE_PATHS.AI.ADD_AI_FLOW_TO_CAMERA, body),
  updatePolygon: (body) => patch(SERVICE_PATHS.AI.UPDATE_POLYGON, body),
  applyAiFlows: (body) => post(SERVICE_PATHS.AI.APPLY_AI_FLOW, body),
  deleteAiFlows: (body) =>
    remove(`${SERVICE_PATHS.AI.DELETE_AI_FLOW}?ids=${body}`),
};

export default AiService;

/* eslint-disable no-self-compare */
import { createSlice } from "@reduxjs/toolkit";

export const aiSlice = createSlice({
  name: "aiflows",
  initialState: {
    listAiFlow: [],
    listPolygon: [],
    editPolygon: [],
    listAiFlowByCamera: [],
    idAiFlows: null,
    polygonDTOs: [],
    polygonDTOsActive: [],
    widthPolygon: 0,
    heightPolygon: 0,
    activeKey: "1",
    linePolygon: [],
  },
  reducers: {
    handleGetListAiFlow(state, action) {
      state.listAiFlow = action.payload;
    },
    handleSizeVideo(state, action) {
      state.widthPolygon = action.payload.width;
      state.heightPolygon = action.payload.height;
    },
    handleGetListAiFlowByCamera(state, action) {
      state.listAiFlowByCamera = action.payload;
      state.idAiFlows = action.payload.id;
      let getRandomRainbowColor = (randomIndex) => {
        const rainbowColors = [
          "#FF0000",
          "#7e820e",
          "#008000",
          "#0000FF",
          "#4B0082",
          "#800080",
        ];
        if (randomIndex >= rainbowColors.length) {
          return rainbowColors[randomIndex - rainbowColors.length];
        } else {
          return rainbowColors[randomIndex];
        }
      };
      const allPolygons = action.payload.polygonDTOs.map((item, index) => {
        const arrayData = item?.polygon && JSON.parse(item.polygon);
        const line = item?.line && JSON.parse(item.line);
        return {
          points: arrayData?.map(([x, y]) => [
            (x / 1920) * state.widthPolygon,
            (y / 1080) * state.heightPolygon,
          ]),
          id: item.id,
          name: item?.name,
          stroke: getRandomRainbowColor(index),
          fontColor: getRandomRainbowColor(index),
          line:
            line && line.length > 0
              ? line.map(([x, y]) => [
                  (x / 1920) * state.widthPolygon,
                  (y / 1080) * state.heightPolygon,
                ])
              : undefined,
          typeZone: item.type,
          data: item,
          active: item.active,
        };
      });
      const activePolygons = allPolygons.filter((item) => item.active);
      state.polygonDTOs = allPolygons;
      state.polygonDTOsActive = activePolygons.map((item) => item.id);
    },
    handleListPolygon(state, action) {
      state.listPolygon = action.payload;
    },
    handleSelectLine(state, action) {
      const result = [];
      if (action.payload?.length > 0) {
        for (let i = 0; i < action.payload?.length; i += 2) {
          result.push(action.payload?.slice(i, i + 2));
        }
      }
      state.linePolygon = result;
    },
    handleSendEditPolygon(state, action) {
      state.editPolygon = action.payload;
    },
    handleResetData(state) {
      state.linePolygon = [];
      state.listAiFlowByCamera = [];
      state.editPolygon = [];
      // state.polygonDTOs = [];
      // state.polygonDTOsActive = [];
    },
    handelChangeKey(state, action) {
      state.activeKey = action.payload;
    },
  },
});

export const {
  handleGetListAiFlow,
  handleListPolygon,
  handleSelectLine,
  handleSendEditPolygon,
  handleGetListAiFlowByCamera,
  handleSizeVideo,
  handleResetData,
  handelChangeKey,
} = aiSlice.actions;

export const selectListAiFlow = (state) => state.aiflows.listAiFlow;
export const selectListPolygon = (state) => state.aiflows.listPolygon;
export const selectLinePolygon = (state) => state.aiflows.linePolygon;
export const selectEditPolygon = (state) => state.aiflows.editPolygon;
export const selectPolygonDTOs = (state) => state.aiflows.polygonDTOs;
export const selectIdAiFlows = (state) => state.aiflows.idAiFlows;
export const selectWidthVideo = (state) => state.aiflows.widthPolygon;
export const selectHeightVideo = (state) => state.aiflows.heightPolygon;
export const selectActiveKey = (state) => state.aiflows.activeKey;
export const selectPolygonDTOsActive = (state) =>
  state.aiflows.polygonDTOsActive;

export default aiSlice.reducer;
